import _ from 'lodash';
import moment from 'moment';
import fieldSort from './fieldSort';

export function isObj(obj) {
  return (typeof obj === 'object' || typeof obj === 'function') && obj !== null;
}

export function isNull(obj) {
  return _.isNil(obj) || _.isNaN(obj) || obj === '' || /^\s$/.test(obj);
}

export function isNilString(value) {
  return /^(?:undefined|null|)$/.test(value);
}

export function isObjectEmpty(object) {
  return typeof object === 'object' && _.isEmpty(object);
}

/**
 * 判断深层嵌套对象是否为空
 * @param {要判断的对象} obj
 * @param {*判断函数} predicate
 * @example isDeepEmpty({ a: { b: { c: [{ d: '' }] } } }) => true
 */
export function isDeepEmpty(obj, predicate = isNull) {
  return _.every(obj, (value, key) => (typeof value === 'object' ? isDeepEmpty(value) : predicate(value, key)));
}

export function isPromise(value) {
  return value && typeof value.then === 'function';
}

export function isEqualWithNumber(value, other) {
  return _.toNumber(value) === _.toNumber(other);
}

export function isEqualWithString(value, other) {
  return _.toString(value) === _.toString(other);
}

export const isPhone = (value) => /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value);

export function safeGet(target, path, defaults) {
  const value = _.get(target, path);
  return isNull(value) ? defaults : value;
}

export function safeParse(value, defaults) {
  const result = _.attempt((source) => JSON.parse(source), value);
  return _.isError(result) || isNull(result) ? defaults : result;
}

export function safeMomentize(value, defaults) {
  if (isNull(value)) {
    return defaults;
  }
  const momentize = moment(value);
  return momentize.isValid() ? momentize : defaults;
}

export function safeMomentFormat(value, pattern, defaults = '') {
  const momentize = safeMomentize(value);
  return momentize ? momentize.format(pattern) : defaults;
}

// 当前的时间戳是否是在当天
export function isDuringDate(serverTime) {
  const beginDate = moment().startOf('day').valueOf();
  const endDate = moment().endOf('day').valueOf();
  if (serverTime >= beginDate && serverTime <= endDate) {
    return true;
  }
  return false;
}

export function atBy(collection, iteratee, predicate = _.isNil) {
  return _.reduce(
    collection,
    (buffer, item, key) => {
      const value = iteratee(item, key);
      if (!predicate(value, key)) {
        buffer.push(value);
      }
      return buffer;
    },
    [],
  );
}

export function alterGet(
  source,
  alters,
  options = {
    predicate: (value) => !_.isNil(value),
    processResult: (value) => _.toString(value),
  },
) {
  return _.chain(alters)
    .filter((alter) => options.predicate(_.get(source, alter)))
    .nth(0)
    .thru((alter) => _.get(source, alter))
    .thru((result) => (typeof options.processResult === 'function' ? options.processResult(result) : result))
    .defaultTo(options.defaults)
    .value();
}

export function convertToString(value) {
  return _.isNil(value) ? value : _.toString(value);
}

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export function findTargetByDeepTraverse(source, target, result = []) {
  const localSource = JSON.parse(JSON.stringify(source));
  localSource.forEach((item) => {
    if (item.name.indexOf(target) !== -1) {
      result.push(item);
    } else if (item.children && item.children.length) {
      findTargetByDeepTraverse(item.children, target, result);
    }
  });
  return result;
}

// export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
export const deepCopy = (obj) =>
  (function deepcopy(oldObj) {
    // 定义一个新的空对象
    let newObject = {};
    // 判断原对象是否存在
    if (oldObj) {
      // 判断原对象的类型
      if (oldObj.constructor === Object) {
        newObject = new oldObj.constructor();
      } else {
        newObject = new oldObj.constructor(oldObj.valueOf());
      }
      // 遍历克隆原对象的属性
      Object.keys(oldObj).forEach((key) => {
        if (newObject[key] !== oldObj[key]) {
          if (typeof oldObj[key] === 'object') {
            // 对象内部的子对象
            newObject[key] = deepcopy(oldObj[key]);
          } else if (typeof oldObj[key] === 'function') {
            // newObject[key] = new Function(`return ${oldObj[key].toString()}`)();
            // newObject[key] = oldObj[key].bind({});
            newObject[key] = oldObj[key];
          } else {
            newObject[key] = oldObj[key];
          }
        }
      });
      // 克隆原对象的常用方法
      // newObject.toString = oldObj.toString;
      // newObject.valueOf = oldObj.valueOf;
      return newObject;
    }

    return oldObj;
  })(obj);

/*
 * 获取地址栏参数
 */
export function getQuery(href, key) {
  return _.chain(href)
    .replace(/^[^?]+\?/, '')
    .split('&')
    .reduce((buffer, pairString) => {
      const [pairKey, pairValue] = _.split(pairString, '=');
      buffer[pairKey] = decodeURIComponent(pairValue);
      return buffer;
    }, {})
    .thru((buffer) => {
      if (!isNilString(key)) {
        return buffer[key];
      }
      return buffer;
    })
    .value();
}

export function getQueryFromRouter(router) {
  return safeGet(router, ['history', 'current', 'query'], {});
}

export function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function catchWith(promise) {
  return promise.then((data) => ({ data, error: null })).catch((error) => ({ data: null, error }));
}

export function generateQueryMap(map, separator = '|') {
  return _.reduce(
    map,
    (acc, value, keysString) => {
      const keys = _.split(keysString, separator);
      _.forEach(keys, (key) => {
        acc[key] = value;
      });
      return acc;
    },
    {},
  );
}

export function stringifyObject(object) {
  return _.chain(object)
    .reduce((buffer, value, key) => [...buffer, `${key}=${value}`], [])
    .join('&')
    .value();
}

export function stringifyArray(array) {
  return _.chain(array)
    .reduce((buffer, item) => {
      const [key, value] = item;
      return [...buffer, `${key}=${value}`];
    }, [])
    .join('&')
    .value();
}

/**
 * 转换一个数组类型的路径为字符串类型的路径
 * @param path ['info', 0, 'name']
 */
export function joinPath(path) {
  return _.reduce(
    path,
    (buffer, item) => {
      if (typeof item === 'string') {
        buffer = `${buffer}.${item}`;
      } else if (typeof item === 'number') {
        buffer = `${buffer}[${item}]`;
      }
      return buffer;
    },
    '',
  ).replace(/^[.]|[.]$/g, '');
}

// 处理表单数据
export function processFormData(data, options) {
  const { handlers, base = {}, path = [], deeper = false } = options;
  function handleSingle(value, key, options) {
    const { pathString } = options;
    const [, handler] =
      _.find(handlers, ([matcher]) => {
        if (typeof matcher === 'function') {
          return matcher(value, key, options);
        }
        if (_.isRegExp(matcher)) {
          return matcher.test(pathString);
        }
        return matcher === pathString;
      }) || [];

    if (typeof handler === 'function') {
      return handler(value, key, options);
    }
    return value;
  }
  return _.reduce(
    data,
    (buffer, value, key) => {
      const concatedPath = _.concat(path, key);
      const joinedPath = joinPath(concatedPath);
      const newOptions = {
        ...options,
        path: concatedPath,
        pathString: joinedPath,
      };
      const isArray = _.isArray(value);
      const isPlainObject = _.isPlainObject(value);

      let isDeep = deeper;

      if (typeof deeper === 'function') {
        isDeep = deeper(value, key, newOptions);
      } else if (_.isRegExp(deeper)) {
        isDeep = deeper.test(joinedPath);
      }

      if (isDeep && (isArray || isPlainObject)) {
        newOptions.base = isArray ? [] : {};
        buffer[key] = processFormData(value, newOptions);
      } else {
        buffer[key] = handleSingle(value, key, newOptions);
      }
      return buffer;
    },
    base,
  );
}

// 对localStorage的值阅后即焚
export function burnLocalStorageAfterReading(key) {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value;
}

/**
 * 根据固定字段顺序将返回的数据进行排序
 * @param {Object} formParams 原始表单字段数据
 * @param {Object} sortModelName 排序模块对象名称
 */
export const sortFormField = (formParams, sortModelName) => {
  const sortObj = fieldSort[sortModelName];
  const sortFieldArr = Object.keys(sortObj);
  const newFormParams = {};
  sortFieldArr.forEach((item) => {
    if (formParams[item]) {
      newFormParams[item] = formParams[item];
    }
  });
  return newFormParams;
};

export const newRecursion = (list) =>
  list.map((item) => ({
    value: String(item.provinceId),
    label: item.provinceName,
    children:
      item.cityList && Array.isArray(item.cityList)
        ? item.cityList.map((cityItem) => ({
            value: String(cityItem.cityId),
            label: cityItem.cityName,
            // children: [],
          }))
        : [],
  }));

export const medicineOrderItemMemberListDeal = (data, key) => {
  let value = null;
  if (Array.isArray(data)) {
    if (data.length > 0) {
      value = data[0][key] || null;
    }
  }
  // console.log('value-----', value);
  return value;
};

// 将字符串\n转换成br标签
export const returnBr = (str) => {
  return (str && str.replace(/\n/g, '<br/>')) || '';
};

export const formArrayValidate = (forms) => {
  const promiseArr = [];

  for (let i in forms) {
    const promise = new Promise(function (resolve, reject) {
      // console.log('formNames[i]: ', forms[i]);
      forms[i]?.validate?.((valid) => {
        if (valid) resolve(true);
        else resolve(false);
      });
    });

    // console.log('promise=> ', promise);
    promiseArr.push(promise);
  }

  return promiseArr;
};
