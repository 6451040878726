export const selectOptions = {
    // 基本信息
    gender: [
        { label: '男', value: 1, code: 1 },
        { label: '女', value: 2, code: 2 },
        { label: '未知', value: 3, code: 3 },
        { label: '其他', value: 9, code: 9 },
    ],
    nation: [
        { label: '汉族', value: 1, code: 1 },
        { label: '蒙古族', value: 2, code: 2 },
        { label: '回族', value: 3, code: 3 },
        { label: '藏族', value: 4, code: 4 },
        { label: '维吾尔族', value: 5, code: 5 },
        { label: '苗族', value: 6, code: 6 },
        { label: '彝族', value: 7, code: 7 },
        { label: '壮族', value: 8, code: 8 },
        { label: '布依族', value: 9, code: 9 },
        { label: '朝鲜族', value: 10, code: 10 },
        { label: '满族', value: 11, code: 11 },
        { label: '侗族', value: 10, code: 10 },
        { label: '瑶族', value: 13, code: 13 },
        { label: '白族', value: 14, code: 14 },
        { label: '土家族', value: 15, code: 15 },
        { label: '哈尼族', value: 16, code: 16 },
        { label: '哈萨克族', value: 17, code: 17 },
        { label: '傣族', value: 18, code: 18 },
        { label: '黎族', value: 19, code: 19 },
        { label: '傈傈族', value: 20, code: 20 },
        { label: '佤族', value: 21, code: 21 },
        { label: '畲族', value: 22, code: 22 },
        { label: '高山族', value: 23, code: 23 },
        { label: '拉祜族', value: 24, code: 24 },
        { label: '水族', value: 25, code: 25 },
        { label: '东乡族', value: 26, code: 26 },
        { label: '纳西族', value: 27, code: 27 },
        { label: '景颇族', value: 28, code: 28 },
        { label: '柯尔克孜族', value: 29, code: 29 },
        { label: '土族', value: 30, code: 30 },
        { label: '达斡尔族', value: 31, code: 31 },
        { label: '仫佬族', value: 32, code: 32 },
        { label: '羌族', value: 33, code: 33 },
        { label: '布朗族', value: 34, code: 34 },
        { label: '撒拉族', value: 35, code: 35 },
        { label: '毛南族', value: 36, code: 36 },
        { label: '仡佬族', value: 37, code: 37 },
        { label: '锡伯族', value: 38, code: 38 },
        { label: '阿昌族', value: 39, code: 39 },
        { label: '普米族', value: 40, code: 40 },
        { label: '塔吉克族', value: 41, code: 41 },
        { label: '怒族', value: 42, code: 42 },
        { label: '乌孜别克族', value: 43, code: 43 },
        { label: '俄罗斯族', value: 44, code: 44 },
        { label: '鄂温克族', value: 45, code: 45 },
        { label: '德昂族', value: 46, code: 46 },
        { label: '保安族', value: 47, code: 47 },
        { label: '裕固族', value: 48, code: 48 },
        { label: '京族', value: 49, code: 49 },
        { label: '塔塔尔族', value: 50, code: 50 },
        { label: '独龙族', value: 51, code: 51 },
        { label: '鄂伦春族', value: 52, code: 52 },
        { label: '赫哲族', value: 53, code: 53 },
        { label: '门巴族', value: 54, code: 54 },
        { label: '珞巴族', value: 55, code: 55 },
        { label: '基诺族', value: 56, code: 56 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    insurance: [
        { label: '无', value: 997, code: 997 },
        { label: '城镇居民基本医疗保险', value: 1, code: 1 },
        { label: '公费医疗', value: 2, code: 2 },
        { label: '社会基本医疗保险', value: 3, code: 3 },
        { label: '新型农村合作医疗', value: 4, code: 4 },
        { label: '商业医疗保险', value: 5, code: 5 },
        { label: '大病统筹', value: 6, code: 6 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    educational: [
        { label: '无', value: 1, code: 1 },
        { label: '小学', value: 2, code: 2 },
        { label: '初中', value: 3, code: 3 },
        { label: '高中/中专/技工学校', value: 4, code: 4 },
        { label: '大专', value: 5, code: 5 },
        { label: '本科', value: 6, code: 6 },
        { label: '硕士研究生', value: 7, code: 7 },
        { label: '博士研究生', value: 8, code: 8 },
        { label: '传统医学师承和确有专长', value: 9, code: 9 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    job: [
        { label: '工人', value: 1, code: 1 },
        { label: '职员', value: 14, code: 14 },
        { label: '农民', value: 2, code: 2 },
        { label: '军人', value: 3, code: 3 },
        { label: '教师', value: 4, code: 4 },
        { label: '科技人员', value: 5, code: 5 },
        { label: '行政人员', value: 6, code: 6 },
        { label: '医务人员', value: 7, code: 7 },
        { label: '个体、商、企、政服务人员', value: 8, code: 8 },
        { label: '家庭妇女', value: 9, code: 9 },
        { label: '待业', value: 11, code: 11 },
        { label: '学生', value: 12, code: 12 },
        { label: '退休', value: 13, code: 13 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],

    // 就诊信息

    // 就诊类型
    visitType: [
        { label: '门诊', value: 1, code: 1 },
        { label: '住院', value: 2, code: 2 },
        { label: '线上诊疗', value: 3, code: 3 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 症状_临床症状
    sym: [
        // { label: '无', value: 997, code: 997 },
        { label: '胃部不适', value: 1, code: 1 },
        { label: '体重下降', value: 2, code: 2 },
        { label: '腰痛', value: 3, code: 3 },
        { label: '腹胀', value: 4, code: 4 },
        { label: '恶心', value: 6, code: 6 },
        { label: '呕吐', value: 7, code: 7 },
        { label: '便秘', value: 8, code: 8 },
        { label: '腹泻', value: 9, code: 9 },
        { label: '便血', value: 11, code: 11 },
        { label: '黑便', value: 12, code: 12 },
        { label: '腹水', value: 13, code: 13 },
        { label: '反酸', value: 14, code: 14 },
        { label: '嗳气', value: 15, code: 15 },
        { label: '烧心感', value: 16, code: 16 },
        { label: '呕血', value: 17, code: 17 },
        { label: '纳差', value: 18, code: 18 },
        { label: '乏力', value: 19, code: 19 },
        { label: '食欲减退', value: 20, code: 20 },
        { label: '吞咽困难', value: 21, code: 21 },
        { label: '消化不良', value: 22, code: 22 },
        { label: '体重减轻', value: 23, code: 23 },
        { label: '发热', value: 24, code: 24 },
        { label: '下腹不适', value: 25, code: 25 },
        { label: '咳嗽', value: 26, code: 26 },
        { label: '咳痰', value: 27, code: 27 },
        { label: '喘憋相', value: 28, code: 28 },
        { label: '痰血或咯血', value: 29, code: 29 },
        { label: '气短或喘鸣', value: 30, code: 30 },
        { label: '胸痛', value: 31, code: 31 },
        { label: '声音嘶哑', value: 32, code: 32 },
        { label: '饮水返呛', value: 33, code: 33 },
        { label: '胸水', value: 34, code: 34 },
        { label: '上腔静脉堵塞综合征', value: 35, code: 35 },
        { label: 'HORNER综合征', value: 36, code: 36 },
        { label: '神经症状', value: 37, code: 37 },
        { label: '骨痛', value: 38, code: 38 },
        { label: '淋巴结肿大', value: 39, code: 39 },
        { label: '深静脉血栓DVT', value: 40, code: 40 },
        { label: '癌痛综合征', value: 41, code: 41 },
        { label: '胸闷', value: 42, code: 42 },
        { label: '憋气', value: 43, code: 43 },
        { label: '多尿', value: 44, code: 44 },
        { label: '尿痛', value: 45, code: 45 },
        { label: '眼花', value: 46, code: 46 },
        { label: '耳鸣', value: 47, code: 47 },
        { label: '视力模糊', value: 48, code: 48 },
        { label: '手脚麻木', value: 49, code: 49 },
        { label: '乳房胀痛', value: 50, code: 50 },
        { label: '多饮', value: 51, code: 51 },
        { label: '关节痛', value: 52, code: 52 },
        { label: '心绞痛', value: 53, code: 53 },
        { label: '失眠', value: 54, code: 54 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 症状_临床症状变化
    symChange: [
        { label: '好转', value: 1, code: 1 },
        { label: '恶化', value: 2, code: 2 },
        { label: '稳定', value: 3, code: 3 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 残疾情况
    disable: [
        { label: '无残疾', value: 1, code: 1 },
        { label: '视力残疾', value: 2, code: 2 },
        { label: '听力残疾', value: 3, code: 3 },
        { label: '言语残疾', value: 4, code: 4 },
        { label: '肢体残疾', value: 5, code: 5 },
        { label: '智力残疾', value: 6, code: 6 },
        { label: '精神残疾', value: 7, code: 7 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 失眠_日间功能障碍
    nightFunction: [
        { label: '入睡困难', value: 1, code: 1 },
        { label: '睡眠维持障碍', value: 2, code: 2 },
        { label: '早醒', value: 3, code: 3 },
        { label: '睡眠质量下降', value: 4, code: 4 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 失眠_夜间功能障碍
    dayFunction: [
        { label: '疲劳', value: 1, code: 1 },
        { label: '情绪低落', value: 2, code: 2 },
        { label: '激惹', value: 3, code: 3 },
        { label: '躯体不适', value: 4, code: 4 },
        { label: '认知障碍', value: 5, code: 5 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],

    // 既往史信息

    // 吸烟史_吸烟状况
    smoke: [
        { label: '已戒烟', value: 1, code: 1 },
        { label: '现在吸烟', value: 2, code: 2 },
        { label: '从未吸烟', value: 3, code: 3 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 危险因素接触史_危险因素接触物（组合无限加）
    dangerous: [
        // { label: '无', value: 997, code: 997 },
        { label: '药物（塞替派/美法仑/马兜铃酸/环磷酰胺等）', value: 1, code: 1 },
        { label: '化学物质（强无机酸雾/苯/染料代谢产生的联苯胺等）', value: 2, code: 2 },
        {
            label: '病毒（乙型肝炎病毒/丙型肝炎病毒/幽门螺旋杆菌/人免疫缺陷病毒I型/人乳头瘤病毒/卡博士肉瘤疱疹病毒等）',
            value: 3,
            code: 3,
        },
        { label: '寄生虫感染（麝后睾吸虫等）', value: 4, code: 4 },
        { label: '职业暴露（木尘/焊接烟尘/煤烟/皮革粉末等）', value: 5, code: 5 },
        { label: '香烟或酒精（吸烟/二手烟烟雾/乙醇等）', value: 6, code: 6 },
        { label: '污染（空气污染等）', value: 7, code: 7 },
        { label: '物理（X射线和伽马射线辐射/太阳辐射/放射性碘等）', value: 8, code: 8 },
        { label: '食物（中式咸鱼/槟榔果等）', value: 9, code: 9 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 危险因素接触史_防护措施
    dangerousPro: [
        { label: '有', value: 1, code: 1 },
        { label: '无', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 饮酒史_饮酒状况
    drinkStatus: [
        { label: '从不饮酒', value: 1, code: 1 },
        { label: '偶尔饮酒', value: 2, code: 2 },
        { label: '每周大于三次', value: 3, code: 3 },
        { label: '每天饮酒', value: 4, code: 4 },
        { label: '已戒酒', value: 5, code: 5 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 饮酒史_饮酒种类
    drinkType: [
        { label: '白酒', value: 1, code: 1 },
        { label: '啤酒', value: 2, code: 2 },
        { label: '红酒', value: 3, code: 3 },
        { label: '黄酒', value: 4, code: 4 },
        { label: '米酒', value: 5, code: 5 },
        { label: '混合类型', value: 6, code: 6 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 饮酒史_
    drinkCompany: [
        { label: '天', value: 1, code: 1 },
        { label: '周', value: 2, code: 2 },
        { label: '月', value: 3, code: 3 },
        { label: '年', value: 4, code: 4 },
    ],
    // 生活方式_锻炼方式
    exerciseWays: [
        { label: '散步', value: 1, code: 1 },
        { label: '跑步', value: 2, code: 2 },
        { label: '游泳', value: 3, code: 3 },
        { label: '骑自行车', value: 4, code: 4 },
        { label: '舞蹈', value: 5, code: 5 },
        { label: '跳绳', value: 6, code: 6 },
        { label: '瑜伽', value: 7, code: 7 },
        { label: '快走', value: 8, code: 8 },
        { label: '爬山', value: 9, code: 9 },
        { label: '跳操', value: 10, code: 10 },
        { label: '俯卧撑', value: 11, code: 11 },
        { label: '潜水', value: 12, code: 12 },
        { label: '器械练习', value: 13, code: 13 },
        { label: '滑雪', value: 14, code: 14 },
        { label: '无', value: 997, code: 997 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 生活方式_锻炼强度
    exerciseStrenth: [
        { label: '极大运动强度：非常吃力，不能坚持到运动结束', value: 1, code: 1 },
        { label: '大运动强度：相当吃力，但是能坚持到运动结束', value: 2, code: 2 },
        { label: '中等强度：有适度出汗，肌肉有略微酸胀的感觉', value: 3, code: 3 },
        { label: '低强度运动：运动后无汗，脉搏无明显变化，人有较轻松的感觉', value: 4, code: 4 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 生活方式_锻炼频率
    lifestyleExerciseFrequency: [
        { label: '每天', value: 1, code: 1 },
        { label: '每周3次或以上', value: 2, code: 2 },
        { label: '每周3次以下', value: 3, code: 3 },
        { label: '不锻炼', value: 4, code: 4 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 生活方式_睡眠状况
    lifestyleSleepPatterns: [
        { label: '入睡困难', value: 1, code: 1 },
        { label: '早醒', value: 2, code: 2 },
        { label: '多梦', value: 3, code: 3 },
        { label: '失眠', value: 4, code: 4 },
        { label: '无异常', value: 997, code: 997 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 生活方式_饮食习惯
    lifestyleEatingHabits: [
        { label: '荤素均衡', value: 1, code: 1 },
        { label: '荤食为主', value: 2, code: 2 },
        { label: '素食为主', value: 3, code: 3 },
        { label: '嗜盐', value: 4, code: 4 },
        { label: '嗜油', value: 5, code: 5 },
        { label: '嗜糖', value: 6, code: 6 },
        { label: '外卖为主', value: 7, code: 7 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_婚姻状况
    marriage: [
        { label: '未婚', value: 1, code: 1 },
        { label: '已婚', value: 2, code: 2 },
        { label: '丧偶', value: 3, code: 3 },
        { label: '离异', value: 4, code: 4 },
        { label: '再婚', value: 5, code: 5 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    pregnancy: [
        { label: '是', value: 1, code: 1 },
        { label: '否', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_生育情况
    birth: [
        { label: '无', value: 1, code: 1 },
        { label: '有', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_生产方式
    birthWay: [
        { label: '剖宫产', value: 1, code: 1 },
        { label: '自然分娩', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_妊娠期间异常情
    abnormal: [
        { label: '早产', value: 1, code: 1 },
        { label: '流产', value: 2, code: 2 },
        { label: '高血压', value: 3, code: 3 },
        { label: '甲功异常', value: 4, code: 4 },
        { label: '糖尿病', value: 5, code: 5 },
        { label: '心脏病', value: 6, code: 6 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_月经情况
    menstruation: [
        { label: '无月经', value: 1, code: 1 },
        { label: '未绝经', value: 2, code: 2 },
        { label: '已绝经', value: 3, code: 3 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_周期是否规则
    menstruationStatus: [
        { label: '规则', value: 1, code: 1 },
        { label: '不规则', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_有无痛经
    menstruationAche: [
        { label: '有', value: 1, code: 1 },
        { label: '无', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 婚育月经史_月经量
    menstruationVolume: [
        { label: '中', value: 1, code: 1 },
        { label: '多', value: 2, code: 2 },
        { label: '少', value: 3, code: 3 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 家族史_与本人关系
    familyRela: [
        { label: '父亲', value: 2, code: 2 },
        { label: '母亲', value: 3, code: 3 },
        { label: '配偶', value: 1, code: 1 },
        { label: '儿子', value: 6, code: 6 },
        { label: '女儿', value: 7, code: 7 },
        { label: '祖父', value: 14, code: 14 },
        { label: '祖母', value: 15, code: 15 },
        { label: '外祖父', value: 18, code: 18 },
        { label: '外祖母', value: 19, code: 19 },
        { label: '兄弟', value: 4, code: 4 },
        { label: '姐妹', value: 5, code: 5 },
        { label: '伯/叔', value: 8, code: 8 },
        { label: '姑', value: 9, code: 9 },
        { label: '侄子', value: 10, code: 10 },
        { label: '侄女', value: 11, code: 11 },
        { label: '外甥', value: 12, code: 12 },
        { label: '外甥女', value: 13, code: 13 },
        { label: '舅', value: 16, code: 16 },
        { label: '姨', value: 17, code: 17 },
        { label: '堂兄弟', value: 20, code: 20 },
        { label: '堂姐妹', value: 21, code: 21 },
        { label: '姑表兄弟', value: 22, code: 22 },
        { label: '姑表姐妹', value: 23, code: 23 },
        { label: '舅表兄弟', value: 24, code: 24 },
        { label: '舅表姐妹', value: 25, code: 25 },
        { label: '姨表兄弟', value: 26, code: 26 },
        { label: '姨表姐妹', value: 27, code: 27 },
        { label: '同父异母兄弟', value: 404, code: 404 },
        { label: '同父异母姐妹', value: 504, code: 504 },
        { label: '同母异父兄弟', value: 402, code: 402 },
        { label: '同母异父姐妹', value: 502, code: 502 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 过敏史_有无过敏史
    allergyStatus: [
        { label: '有', value: 1, code: 1 },
        { label: '无', value: 2, code: 2 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],
    // // 过敏史_药品通用名

    // allergyName: [
    //     { value: 997, code: 997 },
    //     { code: null },
    //     { value: 1, code: 1 },
    //     { value: 2, code: 2 },
    //     { value: 3, code: 3 },
    //     { value: 998, code: 998 },
    //     { value: 999, code: 999 },
    // ],

    // 过敏史_其他物质
    allergyOther: [
        { label: '鸡蛋', value: 1, code: 1 },
        { label: '面粉', value: 2, code: 2 },
        { label: '坚果', value: 3, code: 3 },
        { label: '海鲜', value: 4, code: 4 },
        { label: '豆制品', value: 5, code: 5 },
        { label: '牛奶', value: 6, code: 6 },
        { label: '花粉/草', value: 7, code: 7 },
        { label: '动物毛发', value: 8, code: 8 },
        { label: '酒精', value: 9, code: 9 },
        { label: '粉尘', value: 10, code: 10 },
        { label: '螨虫', value: 11, code: 11 },
        { label: '铁', value: 12, code: 12 },
        // { label: '食物', value: 4, code: 4 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],

    // 过敏史_过敏药品类别
    allergyDrugType: [
        { label: '青霉素', value: 1, code: 1 },
        { label: '地卡因', value: 2, code: 2 },
        { label: '链霉素', value: 3, code: 3 },
        { label: '头孢类抗生素', value: 4, code: 4 },
        { label: '安定', value: 5, code: 5 },
        { label: '止痛片', value: 6, code: 6 },
        { label: '阿司匹林', value: 7, code: 7 },
        { label: '普鲁卡因', value: 8, code: 8 },
        { label: '磺胺类药物', value: 9, code: 9 },
        { label: '泛影葡胺', value: 10, code: 10 },
        { label: '维生素B1', value: 11, code: 11 },
        { label: '破伤风抗毒素（TAT）', value: 12, code: 12 },
        { label: '其他', value: 999, code: 999 },
    ],
    // 预防接种史_非免疫规划预防接种疫苗名称
    inoculationName: [
        { label: '无', value: 997, code: 997 },
        { label: '流感', value: 1, code: 1 },
        { label: 'hpv二价', value: 2, code: 2 },
        { label: 'hpv四价', value: 3, code: 3 },
        { label: 'hpv九价', value: 4, code: 4 },
        { label: '乙肝', value: 5, code: 5 },
        { label: '狂犬病', value: 6, code: 6 },
        { label: '水痘疫苗', value: 7, code: 7 },
        { label: '未知', value: 998, code: 998 },
        { label: '其他', value: 999, code: 999 },
    ],

    // 检查信息

    // 实验室检查
    labExamine: {
        // 检查项目
        examineProjects: [
            { label: '血常规', value: 1, code: 1 },
            { label: '尿常规', value: 2, code: 2 },
            { label: '肝功能', value: 3, code: 3 },
            { label: '血气检查', value: 4, code: 4 },
            { label: '肝炎及DNA定量', value: 5, code: 5 },
            { label: '肿瘤标记物', value: 6, code: 6 },
            { label: '凝血功能检查', value: 7, code: 7 },
            { label: '电解质', value: 8, code: 8 },
            { label: '血沉', value: 9, code: 9 },
            { label: '脑脊液检查', value: 10, code: 10 },
            { label: 'EB病毒检查', value: 11, code: 11 },
            { label: '巨细胞病毒检查', value: 12, code: 12 },
            { label: '单纯疱疹病毒检查', value: 13, code: 13 },
            { label: 'TTV抗体检查', value: 14, code: 14 },
            { label: 'HIV病毒检查', value: 15, code: 15 },
            { label: 'T淋巴细胞抗体检查', value: 16, code: 16 },
            { label: '血免疫固定电泳', value: 17, code: 17 },
            { label: '心肌酶谱', value: 18, code: 18 },
            { label: '库姆和冷凝实验检查', value: 19, code: 19 },
            { label: '抗核和ENA抗体检查', value: 20, code: 20 },
            { label: '免疫因子检查', value: 21, code: 21 },
            { label: '肾功能', value: 22, code: 22 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 检查结果
        examineResult: [
            { label: '正常', value: 1, code: 1 },
            { label: '异常', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // blood血型
        blood: [
            { label: 'A 型', value: 1, code: 1 },
            { label: 'B 型', value: 2, code: 2 },
            { label: 'O 型', value: 3, code: 3 },
            { label: 'AB 型', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 幽门螺旋杆菌
        pylori: [
            { label: '阳性', value: 1, code: 1 },
            { label: '阴性', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 尿蛋白
        urine: [
            { label: '-', value: 1, code: 1 },
            { label: '±', value: 2, code: 2 },
            { label: '+', value: 3, code: 3 },
            { label: '++', value: 4, code: 4 },
            { label: '+++', value: 5, code: 5 },
            { label: '++++', value: 6, code: 6 },
            { label: '未检测', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // RH型
        rh: [
            { label: 'Rh阴性', value: 1, code: 1 },
            { label: 'Rh阳性', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 影像学检查
    image: {
        // 检查项目
        examineProjects: [
            { label: 'X线', value: 1, code: 1 },
            { label: '超声', value: 2, code: 2 },
            { label: 'CT', value: 3, code: 3 },
            { label: 'MRI', value: 4, code: 4 },
            { label: 'ECT', value: 5, code: 5 },
            { label: 'PETCT', value: 6, code: 6 },
            { label: '造影', value: 7, code: 7 },
            { label: '钼靶', value: 8, code: 8 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // // 检查部位
        // examinePart: [
        //     { code: null },
        //     { value: 1, code: 1 },
        //     { value: 2, code: 2 },
        //     { value: 3, code: 3 },
        //     { value: 4, code: 4 },
        //     { value: 5, code: 5 },
        //     { value: 6, code: 6 },
        //     { value: 998, code: 998 },
        //     { value: 999, code: 999 },
        // ],

        // 影像学发现
        imageFind: [
            { label: '胆囊息肉', value: 1, code: 1 },
            { label: '甲状腺结节', value: 2, code: 2 },
            { label: '肝囊肿', value: 3, code: 3 },
            { label: '肾囊肿', value: 4, code: 4 },
            { label: '胆囊息肉蒂', value: 5, code: 5 },
            { label: '肺结节', value: 6, code: 6 },
            { label: '泌尿系结石', value: 7, code: 7 },
            { label: '乳腺结节', value: 8, code: 8 },
            { label: '子宫肌瘤', value: 9, code: 9 },
            { label: '子宫内膜息肉', value: 10, code: 10 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // // 组织部位
        // tissuePart: [
        //     { code: null },
        //     { value: 1, code: 1 },
        //     { value: 2, code: 2 },
        //     { value: 3, code: 3 },
        //     { value: 4, code: 4 },
        //     { value: 5, code: 5 },
        //     { value: 6, code: 6 },
        //     { value: 998, code: 998 },
        //     { value: 999, code: 999 },
        // ],

        // 数目
        numbers: [
            { label: '单发', value: 1, code: 1 },
            { label: '多发', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 乳腺组织构成
        breast: [
            { label: '脂肪型', value: 1, code: 1 },
            { label: '纤维腺体型', value: 2, code: 2 },
            { label: '不均匀回声型', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 组织性质
        breastNature: [
            { label: '实性', value: 1, code: 1 },
            { label: '囊性', value: 2, code: 2 },
            { label: '囊实性', value: 3, code: 3 },
            { label: '部分实性', value: 4, code: 4 },
            { label: '磨玻璃', value: 5, code: 5 },
            { label: '玻璃样', value: 6, code: 6 },
            { label: '肉瘤样', value: 7, code: 7 },
            { label: '红色变性', value: 8, code: 8 },
            { label: '钙化', value: 9, code: 9 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 组织形态
        breastForm: [
            { label: '规则', value: 1, code: 1 },
            { label: '不规则', value: 2, code: 2 },
            { label: '圆形', value: 3, code: 3 },
            { label: '椭圆形', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 组织方向
        breastDirect: [
            { label: '与皮肤平行', value: 1, code: 1 },
            { label: '与皮肤不平行', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 内部回声
        innerEcho: [
            { label: '无', value: 1, code: 1 },
            { label: '极低回声', value: 2, code: 2 },
            { label: '低回声', value: 3, code: 3 },
            { label: '等回声', value: 4, code: 4 },
            { label: '高回声', value: 5, code: 5 },
            { label: '混合回声', value: 6, code: 6 },
            { label: '不均回声', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 外部回声
        outerEcho: [
            { label: '无改变', value: 1, code: 1 },
            { label: '回声增强', value: 2, code: 2 },
            { label: '声影', value: 3, code: 3 },
            { label: '混合型改变', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 边缘情况
        edge: [
            { label: '规则', value: 1, code: 1 },
            { label: '不规则', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 钙化情况
        cao: [
            { label: '微钙化', value: 1, code: 1 },
            { label: '大钙化', value: 2, code: 2 },
            { label: '粗大钙化', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 周边血流情况
        blood: [
            { label: '有', value: 1, code: 1 },
            { label: '无', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // TI-RADS分级
        tirads: [
            { label: '0级', value: 1, code: 1 },
            { label: '1级', value: 2, code: 2 },
            { label: '2级', value: 3, code: 3 },
            { label: '3级', value: 4, code: 4 },
            { label: '4级', value: 5, code: 5 },
            { label: '5级', value: 6, code: 6 },
            { label: '6级', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 淋巴结整体情况  淋巴
        lymph: [
            { label: '未见异常', value: 1, code: 1 },
            { label: '可能转移', value: 2, code: 2 },
            { label: '转移可能性大', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // BI-RADS分级
        birads: [
            { label: '0级', value: 1, code: 1 },
            { label: '1级', value: 2, code: 2 },
            { label: '2级', value: 3, code: 3 },
            { label: '3级', value: 4, code: 4 },
            { label: '4级', value: 5, code: 5 },
            { label: '5级', value: 6, code: 6 },
            { label: '6级', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 子宫大小
        uterusSize: [
            { label: '正常', value: 1, code: 1 },
            { label: '增大', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },

    // 内镜检查
    endoscopy: {
        // 检查项目
        examineProjects: [
            { label: '鼻咽', value: 1, code: 1 },
            { label: '肠镜', value: 2, code: 2 },
            { label: '胃镜', value: 3, code: 3 },
            { label: '气管-支气管镜', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 辅助检查
    supply: {
        // 检查项目
        examineProjects: [
            { label: '肺功能', value: 1, code: 1 },
            { label: '心电图', value: 2, code: 2 },
            { label: '心功能', value: 3, code: 3 },
            { label: '血气分析', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 基因检查
    gen: {
        // 基因检查
        gene: [
            { label: '有', value: 1, code: 1 },
            { label: '无', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 基因检测方法
        geneWay: [
            { label: 'Sanger测序法', value: 1, code: 1 },
            { label: '焦磷酸测序法(Pyrosequencing)', value: 2, code: 2 },
            { label: '新一代测序(NGS)', value: 3, code: 3 },
            { label: '扩增阻滞突变系统(ARMS-PCR)', value: 4, code: 4 },
            { label: '高分辨率熔解曲线(HRM)法', value: 5, code: 5 },
            { label: '数字PCR(DigitalPCR)', value: 6, code: 6 },
            { label: '荧光原位杂交(FISH)', value: 7, code: 7 },
            { label: '免疫组化(IHC)', value: 8, code: 8 },
            { label: '荧光定量逆转录PCR(Q-RT-PCR)', value: 9, code: 9 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 基因检测_基因检测名称（组合无限加
        geneName: [
            { label: '+12', value: 98, code: 98 },
            { label: '11q', value: 155, code: 155 },
            { label: '13q', value: 157, code: 157 },
            { label: '13q14', value: 96, code: 96 },
            { label: '17p', value: 156, code: 156 },
            { label: '1p32', value: 152, code: 152 },
            { label: '1q21', value: 151, code: 151 },
            { label: '6q23', value: 97, code: 97 },
            { label: 'ABL1', value: 190, code: 190 },
            { label: 'AKT1', value: 191, code: 191 },
            { label: 'AKT2', value: 192, code: 192 },
            { label: 'AKT3', value: 193, code: 193 },
            { label: 'ALK', value: 1, code: 1 },
            { label: 'AMER1', value: 165, code: 165 },
            { label: 'AML1/ETO', value: 113, code: 113 },
            { label: 'AML1/MDS1', value: 114, code: 114 },
            { label: 'APC', value: 2, code: 2 },
            { label: 'APCDD1', value: 415, code: 415 },
            { label: 'AR', value: 194, code: 194 },
            { label: 'ARAF', value: 195, code: 195 },
            { label: 'ARFRP1', value: 416, code: 416 },
            { label: 'ARID1A', value: 3, code: 3 },
            { label: 'ARID1B', value: 196, code: 196 },
            { label: 'ARID2', value: 197, code: 197 },
            { label: 'ASXL1', value: 70, code: 70 },
            { label: 'ATM', value: 4, code: 4 },
            { label: 'ATR', value: 198, code: 198 },
            { label: 'ATRX', value: 199, code: 199 },
            { label: 'AURKA', value: 200, code: 200 },
            { label: 'AURKB', value: 201, code: 201 },
            { label: 'AXIN1', value: 202, code: 202 },
            { label: 'AXL', value: 203, code: 203 },
            { label: 'B53/17', value: 164, code: 164 },
            { label: 'BACH1', value: 417, code: 417 },
            { label: 'BAP1', value: 204, code: 204 },
            { label: 'BARD1', value: 205, code: 205 },
            { label: 'BBC3', value: 418, code: 418 },
            { label: 'BCL-2', value: 5, code: 5 },
            { label: 'BCL-6', value: 6, code: 6 },
            { label: 'BCL2L1', value: 206, code: 206 },
            { label: 'BCOR', value: 71, code: 71 },
            { label: 'BCR/ABL', value: 163, code: 163 },
            { label: 'BCR/ABL1', value: 125, code: 125 },
            { label: 'BIRC2', value: 7, code: 7 },
            { label: 'BIRC3', value: 8, code: 8 },
            { label: 'BLM', value: 207, code: 207 },
            { label: 'BRAF', value: 9, code: 9 },
            { label: 'BRCA1', value: 67, code: 67 },
            { label: 'BRCA2', value: 68, code: 68 },
            { label: 'BRD4', value: 208, code: 208 },
            { label: 'BRIP1', value: 209, code: 209 },
            { label: 'BTK', value: 10, code: 10 },
            { label: 'C-KIT', value: 18, code: 18 },
            { label: 'C-MYC', value: 21, code: 21 },
            { label: 'CARD11', value: 11, code: 11 },
            { label: 'CBFB', value: 210, code: 210 },
            { label: 'CBFβ/MYH11', value: 112, code: 112 },
            { label: 'CBL', value: 73, code: 73 },
            { label: 'CCND', value: 12, code: 12 },
            { label: 'CCND1', value: 13, code: 13 },
            { label: 'CCND2', value: 14, code: 14 },
            { label: 'CCND3', value: 15, code: 15 },
            { label: 'CCNE1', value: 240, code: 240 },
            { label: 'CD274', value: 241, code: 241 },
            { label: 'CD79A', value: 16, code: 16 },
            { label: 'CD79B', value: 242, code: 242 },
            { label: 'CDC73', value: 243, code: 243 },
            { label: 'CDH1', value: 244, code: 244 },
            { label: 'CDK12', value: 245, code: 245 },
            { label: 'CDK4', value: 246, code: 246 },
            { label: 'CDK6', value: 247, code: 247 },
            { label: 'CDK8', value: 248, code: 248 },
            { label: 'CDKN1A', value: 249, code: 249 },
            { label: 'CDKN1B', value: 250, code: 250 },
            { label: 'CDKN1C', value: 251, code: 251 },
            { label: 'CDKN2A', value: 17, code: 17 },
            { label: 'CDKN2B', value: 252, code: 252 },
            { label: 'CDKN2C', value: 253, code: 253 },
            { label: 'CEBPA', value: 74, code: 74 },
            { label: 'CHD1', value: 254, code: 254 },
            { label: 'CHEK1', value: 255, code: 255 },
            { label: 'CHEK2', value: 256, code: 256 },
            { label: 'CIC', value: 257, code: 257 },
            { label: 'CKS1B', value: 19, code: 19 },
            { label: 'CMET', value: 20, code: 20 },
            { label: 'CREBBP', value: 22, code: 22 },
            { label: 'CRKL', value: 258, code: 258 },
            { label: 'CRLF2', value: 259, code: 259 },
            { label: 'CSF1R', value: 260, code: 260 },
            { label: 'CSF3R', value: 75, code: 75 },
            { label: 'CTCF', value: 261, code: 261 },
            { label: 'CTNNB1', value: 262, code: 262 },
            { label: 'CUL3', value: 263, code: 263 },
            { label: 'CUX1', value: 76, code: 76 },
            { label: 'CXCR4', value: 23, code: 23 },
            { label: 'D13S25', value: 24, code: 24 },
            { label: 'DAXX', value: 264, code: 264 },
            { label: 'DDR2', value: 265, code: 265 },
            { label: 'DICER1', value: 266, code: 266 },
            { label: 'DNMT3A', value: 25, code: 25 },
            { label: 'DNMT3B', value: 77, code: 77 },
            { label: 'DOT1L', value: 267, code: 267 },
            { label: 'DTX1', value: 26, code: 26 },
            { label: 'E2A-PBX1', value: 160, code: 160 },
            { label: 'E2A/HLF', value: 127, code: 127 },
            { label: 'EGFR', value: 27, code: 27 },
            { label: 'EGR1', value: 28, code: 28 },
            { label: 'EGR2', value: 29, code: 29 },
            { label: 'EMSY', value: 268, code: 268 },
            { label: 'EP300', value: 30, code: 30 },
            { label: 'EPHA3', value: 299, code: 299 },
            { label: 'EPHA5', value: 300, code: 300 },
            { label: 'EPHA7', value: 301, code: 301 },
            { label: 'EPHB1', value: 302, code: 302 },
            { label: 'ERBB2', value: 61, code: 61 },
            { label: 'ERBB3', value: 62, code: 62 },
            { label: 'ERBB4', value: 303, code: 303 },
            { label: 'ERCC1', value: 304, code: 304 },
            { label: 'ERG', value: 305, code: 305 },
            { label: 'ERRFI1', value: 306, code: 306 },
            { label: 'ESR1', value: 307, code: 307 },
            { label: 'ETV6', value: 31, code: 31 },
            { label: 'ETV6-PDGFRα', value: 130, code: 130 },
            { label: 'ETV6/PDGFRβ', value: 132, code: 132 },
            { label: 'EVII', value: 133, code: 133 },
            { label: 'EZH2', value: 32, code: 32 },
            { label: 'FAM175A', value: 308, code: 308 },
            { label: 'FAM46C', value: 166, code: 166 },
            { label: 'FANCA', value: 309, code: 309 },
            { label: 'FANCC', value: 310, code: 310 },
            { label: 'FANCD2', value: 311, code: 311 },
            { label: 'FANCE', value: 312, code: 312 },
            { label: 'FANCF', value: 167, code: 167 },
            { label: 'FANCG', value: 313, code: 313 },
            { label: 'FANCI', value: 314, code: 314 },
            { label: 'FANCL', value: 315, code: 315 },
            { label: 'FAT1', value: 33, code: 33 },
            { label: 'FAT3', value: 316, code: 316 },
            { label: 'FAT4', value: 34, code: 34 },
            { label: 'FBXW7', value: 35, code: 35 },
            { label: 'FGF19', value: 317, code: 317 },
            { label: 'FGF3', value: 318, code: 318 },
            { label: 'FGF4', value: 319, code: 319 },
            { label: 'FGFR1', value: 320, code: 320 },
            { label: 'FGFR2', value: 321, code: 321 },
            { label: 'FGFR3', value: 150, code: 150 },
            { label: 'FGFR4', value: 322, code: 322 },
            { label: 'FH', value: 323, code: 323 },
            { label: 'FIPIL1/PDGFRα', value: 131, code: 131 },
            { label: 'FIPIL1/RARα', value: 121, code: 121 },
            { label: 'FLCN', value: 324, code: 324 },
            { label: 'FLT1', value: 325, code: 325 },
            { label: 'FLT3', value: 78, code: 78 },
            { label: 'FLT4', value: 326, code: 326 },
            { label: 'FOXL2', value: 168, code: 168 },
            { label: 'FOXO1', value: 355, code: 355 },
            { label: 'FOXP1', value: 356, code: 356 },
            { label: 'FUBP1', value: 357, code: 357 },
            { label: 'GALNT12', value: 358, code: 358 },
            { label: 'GATA1', value: 79, code: 79 },
            { label: 'GATA2', value: 80, code: 80 },
            { label: 'GATA3', value: 359, code: 359 },
            { label: 'GLI1', value: 360, code: 360 },
            { label: 'GNA11', value: 361, code: 361 },
            { label: 'GNAQ', value: 362, code: 362 },
            { label: 'GNAS', value: 363, code: 363 },
            { label: 'GPS2', value: 36, code: 36 },
            { label: 'GREM1', value: 169, code: 169 },
            { label: 'GRIN2A', value: 364, code: 364 },
            { label: 'GRM3', value: 281, code: 281 },
            { label: 'GSK3B', value: 365, code: 365 },
            { label: 'GSTM1', value: 366, code: 366 },
            { label: 'GSTT1', value: 367, code: 367 },
            { label: 'H3F3A', value: 368, code: 368 },
            { label: 'H3F3C', value: 170, code: 170 },
            { label: 'HGF', value: 369, code: 369 },
            { label: 'HIST1H1C', value: 171, code: 171 },
            { label: 'HIST1H2AG', value: 37, code: 37 },
            { label: 'HIST1H2BD', value: 172, code: 172 },
            { label: 'HIST1H2BK', value: 38, code: 38 },
            { label: 'HIST1H3A', value: 173, code: 173 },
            { label: 'HIST1H3B', value: 174, code: 174 },
            { label: 'HIST1H3C', value: 175, code: 175 },
            { label: 'HIST1H3D', value: 176, code: 176 },
            { label: 'HIST1H3E', value: 177, code: 177 },
            { label: 'HIST1H3G', value: 178, code: 178 },
            { label: 'HIST1H3H', value: 179, code: 179 },
            { label: 'HIST1H3I', value: 180, code: 180 },
            { label: 'HIST1H3J', value: 181, code: 181 },
            { label: 'HIST2H3D', value: 182, code: 182 },
            { label: 'HIST3H3', value: 183, code: 183 },
            { label: 'HNF1A', value: 370, code: 370 },
            { label: 'HNF1B', value: 371, code: 371 },
            { label: 'HOX11', value: 134, code: 134 },
            { label: 'HOX11L2', value: 162, code: 162 },
            { label: 'HRAS', value: 387, code: 387 },
            { label: 'Her', value: 95, code: 95 },
            { label: 'ID3', value: 39, code: 39 },
            { label: 'IDH1', value: 81, code: 81 },
            { label: 'IDH2', value: 40, code: 40 },
            { label: 'IGF1R', value: 388, code: 388 },
            { label: 'IGF2', value: 389, code: 389 },
            { label: 'IGHV', value: 41, code: 41 },
            { label: 'IKBKB', value: 42, code: 42 },
            { label: 'IKBKE', value: 390, code: 390 },
            { label: 'IKZF1', value: 82, code: 82 },
            { label: 'IL3-IGH', value: 161, code: 161 },
            { label: 'IL7R', value: 391, code: 391 },
            { label: 'INHBA', value: 392, code: 392 },
            { label: 'INPP4B', value: 393, code: 393 },
            { label: 'IRF4', value: 394, code: 394 },
            { label: 'IRS1', value: 184, code: 184 },
            { label: 'IRS2', value: 395, code: 395 },
            { label: 'IgH', value: 141, code: 141 },
            { label: 'IgH(R1G1Y1)', value: 99, code: 99 },
            { label: 'IgH(R2G2)', value: 100, code: 100 },
            { label: 'IgH/CCND', value: 144, code: 144 },
            { label: 'IgH/CCND1', value: 145, code: 145 },
            { label: 'IgH/MAF', value: 142, code: 142 },
            { label: 'IgH/MAFB', value: 143, code: 143 },
            { label: 'IgK', value: 146, code: 146 },
            { label: 'JAK1', value: 396, code: 396 },
            { label: 'JAK2', value: 43, code: 43 },
            { label: 'JAK3', value: 397, code: 397 },
            { label: 'JUN', value: 185, code: 185 },
            { label: 'KDM5A', value: 398, code: 398 },
            { label: 'KDM5C', value: 399, code: 399 },
            { label: 'KDM6A', value: 400, code: 400 },
            { label: 'KDR', value: 66, code: 66 },
            { label: 'KEAP1', value: 401, code: 401 },
            { label: 'KIT', value: 402, code: 402 },
            { label: 'KLF2', value: 44, code: 44 },
            { label: 'KLHL', value: 45, code: 45 },
            { label: 'KLHL6', value: 46, code: 46 },
            { label: 'KMT2A', value: 403, code: 403 },
            { label: 'KMT2C', value: 404, code: 404 },
            { label: 'KMT2D', value: 83, code: 83 },
            { label: 'KRAS', value: 47, code: 47 },
            { label: 'LATS1', value: 405, code: 405 },
            { label: 'LMO1', value: 406, code: 406 },
            { label: 'LRP1B', value: 407, code: 407 },
            { label: 'MAP2K1', value: 408, code: 408 },
            { label: 'MAP2K2', value: 409, code: 409 },
            { label: 'MAP2K4', value: 410, code: 410 },
            { label: 'MAP3K1', value: 411, code: 411 },
            { label: 'MCL1', value: 412, code: 412 },
            { label: 'MDM2', value: 413, code: 413 },
            { label: 'MDM4', value: 414, code: 414 },
            { label: 'MED12', value: 211, code: 211 },
            { label: 'MEF2B', value: 212, code: 212 },
            { label: 'MEN1', value: 213, code: 213 },
            { label: 'MET', value: 214, code: 214 },
            { label: 'MITF', value: 215, code: 215 },
            { label: 'MLH1', value: 48, code: 48 },
            { label: 'MLH3', value: 216, code: 216 },
            { label: 'MLL/AF10', value: 104, code: 104 },
            { label: 'MLL/AF17', value: 105, code: 105 },
            { label: 'MLL/AF1P', value: 106, code: 106 },
            { label: 'MLL/AF1Q', value: 107, code: 107 },
            { label: 'MLL/AF4', value: 101, code: 101 },
            { label: 'MLL/AF6', value: 102, code: 102 },
            { label: 'MLL/AF9', value: 103, code: 103 },
            { label: 'MLL/AFX', value: 108, code: 108 },
            { label: 'MLL/ELL', value: 109, code: 109 },
            { label: 'MLL/ENL', value: 110, code: 110 },
            { label: 'MPL', value: 217, code: 217 },
            { label: 'MRE11A', value: 218, code: 218 },
            { label: 'MSH2', value: 49, code: 49 },
            { label: 'MSH3', value: 219, code: 219 },
            { label: 'MSH6', value: 50, code: 50 },
            { label: 'MTOR', value: 65, code: 65 },
            { label: 'MUTYH', value: 220, code: 220 },
            { label: 'MYC', value: 64, code: 64 },
            { label: 'MYCL', value: 221, code: 221 },
            { label: 'MYCN', value: 222, code: 222 },
            { label: 'MYD88', value: 223, code: 223 },
            { label: 'MYD88L265P', value: 51, code: 51 },
            { label: 'NBN', value: 224, code: 224 },
            { label: 'NF1', value: 225, code: 225 },
            { label: 'NF2', value: 226, code: 226 },
            { label: 'NFE2L2', value: 227, code: 227 },
            { label: 'NFKBIA', value: 228, code: 228 },
            { label: 'NKX2-1', value: 229, code: 229 },
            { label: 'NOTCH1', value: 230, code: 230 },
            { label: 'NOTCH2', value: 231, code: 231 },
            { label: 'NOTCH3', value: 232, code: 232 },
            { label: 'NOTCH１', value: 52, code: 52 },
            { label: 'NPM/ALK', value: 122, code: 122 },
            { label: 'NPM/MLF1/TEL/ABL1', value: 126, code: 126 },
            { label: 'NPM/RARα', value: 117, code: 117 },
            { label: 'NPM1', value: 84, code: 84 },
            { label: 'NRAS', value: 53, code: 53 },
            { label: 'NSD1', value: 233, code: 233 },
            { label: 'NTHL1', value: 234, code: 234 },
            { label: 'NTRK1', value: 235, code: 235 },
            { label: 'NTRK2', value: 236, code: 236 },
            { label: 'NTRK3', value: 237, code: 237 },
            { label: 'NUP93', value: 238, code: 238 },
            { label: 'NuMA1/RARα', value: 119, code: 119 },
            { label: 'NuP98/HOxA11', value: 136, code: 136 },
            { label: 'NuP98/HOxA13', value: 137, code: 137 },
            { label: 'NuP98/HOxA9', value: 135, code: 135 },
            { label: 'NuP98/HOxC11', value: 138, code: 138 },
            { label: 'NuP98/HOxD13', value: 139, code: 139 },
            { label: 'NuP98/PMX1', value: 140, code: 140 },
            { label: 'PALB2', value: 239, code: 239 },
            { label: 'PARK2', value: 269, code: 269 },
            { label: 'PAX5', value: 270, code: 270 },
            { label: 'PBRM1', value: 271, code: 271 },
            { label: 'PD-L1', value: 158, code: 158 },
            { label: 'PDGFRA', value: 54, code: 54 },
            { label: 'PDGFRB', value: 272, code: 272 },
            { label: 'PHF6', value: 85, code: 85 },
            { label: 'PIK3CA', value: 159, code: 159 },
            { label: 'PIK3CB', value: 273, code: 273 },
            { label: 'PIK3CG', value: 274, code: 274 },
            { label: 'PIK3R1', value: 275, code: 275 },
            { label: 'PIK3R2', value: 276, code: 276 },
            { label: 'PLCG2', value: 277, code: 277 },
            { label: 'PML/RARα', value: 116, code: 116 },
            { label: 'PMS1', value: 278, code: 278 },
            { label: 'PMS2', value: 55, code: 55 },
            { label: 'POLD1', value: 279, code: 279 },
            { label: 'POLE', value: 280, code: 280 },
            { label: 'POM121L12', value: 186, code: 186 },
            { label: 'PPP2R1A', value: 282, code: 282 },
            { label: 'PPP2R2A', value: 283, code: 283 },
            { label: 'PRDM1', value: 284, code: 284 },
            { label: 'PRKAR1A', value: 285, code: 285 },
            { label: 'PRKARIA/RARα', value: 120, code: 120 },
            { label: 'PRKDC', value: 286, code: 286 },
            { label: 'PTCH1', value: 69, code: 69 },
            { label: 'PTEN', value: 287, code: 287 },
            { label: 'PTPN11', value: 86, code: 86 },
            { label: 'PTPRD', value: 288, code: 288 },
            { label: 'RAC1', value: 289, code: 289 },
            { label: 'RAD50', value: 290, code: 290 },
            { label: 'RAD51', value: 291, code: 291 },
            { label: 'RAD51B', value: 292, code: 292 },
            { label: 'RAD51C', value: 293, code: 293 },
            { label: 'RAD51D', value: 294, code: 294 },
            { label: 'RAD52', value: 295, code: 295 },
            { label: 'RAD54L', value: 296, code: 296 },
            { label: 'RAF1', value: 297, code: 297 },
            { label: 'RARA', value: 298, code: 298 },
            { label: 'RB1', value: 149, code: 149 },
            { label: 'RBM10', value: 327, code: 327 },
            { label: 'RET', value: 63, code: 63 },
            { label: 'RICTOR', value: 328, code: 328 },
            { label: 'RNF43', value: 329, code: 329 },
            { label: 'ROS1', value: 56, code: 56 },
            { label: 'RPTOR', value: 330, code: 330 },
            { label: 'RUNX1', value: 87, code: 87 },
            { label: 'SDH', value: 57, code: 57 },
            { label: 'SDHA', value: 331, code: 331 },
            { label: 'SDHB', value: 332, code: 332 },
            { label: 'SDHC', value: 333, code: 333 },
            { label: 'SDHD', value: 334, code: 334 },
            { label: 'SET/CAN', value: 115, code: 115 },
            { label: 'SETBP1', value: 88, code: 88 },
            { label: 'SETD2', value: 335, code: 335 },
            { label: 'SF3B1', value: 58, code: 58 },
            { label: 'SIL/TAL1', value: 129, code: 129 },
            { label: 'SLX4', value: 336, code: 336 },
            { label: 'SMAD2', value: 337, code: 337 },
            { label: 'SMAD3', value: 338, code: 338 },
            { label: 'SMAD4', value: 339, code: 339 },
            { label: 'SMARCA4', value: 340, code: 340 },
            { label: 'SMARCB1', value: 341, code: 341 },
            { label: 'SMO', value: 342, code: 342 },
            { label: 'SOCS1', value: 187, code: 187 },
            { label: 'SOX2', value: 188, code: 188 },
            { label: 'SOX9', value: 343, code: 343 },
            { label: 'SPEN', value: 344, code: 344 },
            { label: 'SPOP', value: 345, code: 345 },
            { label: 'SPTA1', value: 346, code: 346 },
            { label: 'SRC', value: 347, code: 347 },
            { label: 'SRSF2', value: 89, code: 89 },
            { label: 'STAG2', value: 348, code: 348 },
            { label: 'STAT3', value: 349, code: 349 },
            { label: 'STAT5B', value: 350, code: 350 },
            { label: 'STAT5b/RARα', value: 118, code: 118 },
            { label: 'STK11', value: 351, code: 351 },
            { label: 'SUFU', value: 352, code: 352 },
            { label: 'SYK', value: 353, code: 353 },
            { label: 'TBX3', value: 354, code: 354 },
            { label: 'TCF3/PBX1', value: 124, code: 124 },
            { label: 'TCRβ', value: 148, code: 148 },
            { label: 'TCRγ', value: 147, code: 147 },
            { label: 'TEL/AML1', value: 123, code: 123 },
            { label: 'TERC', value: 189, code: 189 },
            { label: 'TERT', value: 372, code: 372 },
            { label: 'TET2', value: 90, code: 90 },
            { label: 'TGFBR2', value: 373, code: 373 },
            { label: 'TLS/ERG', value: 128, code: 128 },
            { label: 'TNFAIP3', value: 374, code: 374 },
            { label: 'TNFRSF14', value: 375, code: 375 },
            { label: 'TNFSF11', value: 376, code: 376 },
            { label: 'TOP1', value: 377, code: 377 },
            { label: 'TP53', value: 59, code: 59 },
            { label: 'TP63', value: 378, code: 378 },
            { label: 'TSC1', value: 379, code: 379 },
            { label: 'TSC2', value: 380, code: 380 },
            { label: 'TSHR', value: 381, code: 381 },
            { label: 'U2AF1', value: 91, code: 91 },
            { label: 'U2AF2', value: 92, code: 92 },
            { label: 'UGTIA1', value: 60, code: 60 },
            { label: 'VEGFA', value: 382, code: 382 },
            { label: 'VHL', value: 383, code: 383 },
            { label: 'WRN', value: 384, code: 384 },
            { label: 'WT1', value: 93, code: 93 },
            { label: 'XPO1', value: 385, code: 385 },
            { label: 'XRCC2', value: 386, code: 386 },
            { label: 'ZRSR2', value: 94, code: 94 },
            { label: 'cd38', value: 154, code: 154 },
            { label: 'dupMLL', value: 111, code: 111 },
            { label: 'zap70', value: 153, code: 153 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 基因检测_基因检测结果（组合无限加
        geneResult: [
            { label: '阴性', value: 1, code: 1 },
            { label: '缺失突变', value: 2, code: 2 },
            { label: '点突变', value: 3, code: 3 },
            { label: '插入突变', value: 4, code: 4 },
            { label: '阳性', value: 5, code: 5 },
            { label: '扩增', value: 6, code: 6 },
            { label: '可疑', value: 7, code: 7 },
            { label: '突变', value: 8, code: 8 },
            { label: '野生', value: 9, code: 9 },
            { label: '未检测', value: 997, code: 997 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 病理检查
    pathology: {
        // 病理检查
        pahExamine: [
            { label: '有', value: 1, code: 1 },
            { label: '无', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 免疫表型_免疫表型名称
        immuneName: [
            { label: '34βE12', value: 194, code: 194 },
            { label: 'AACT', value: 195, code: 195 },
            { label: 'AAT', value: 196, code: 196 },
            { label: 'ACTH', value: 197, code: 197 },
            { label: 'AE1/AE3 ', value: 199, code: 199 },
            { label: 'AEI', value: 188, code: 188 },
            { label: 'AFP', value: 200, code: 200 },
            { label: 'AMACR', value: 185, code: 185 },
            { label: 'APC', value: 202, code: 202 },
            { label: 'AR', value: 184, code: 184 },
            { label: 'ARV567', value: 187, code: 187 },
            { label: 'ARV7', value: 186, code: 186 },
            { label: 'Actin', value: 198, code: 198 },
            { label: 'Alk', value: 77, code: 77 },
            { label: 'BCL-2', value: 56, code: 56 },
            { label: 'BCL-6', value: 55, code: 55 },
            { label: 'BRAF', value: 153, code: 153 },
            { label: 'BRCA1', value: 208, code: 208 },
            { label: 'BRCA2', value: 209, code: 209 },
            { label: 'Bax', value: 203, code: 203 },
            { label: 'Bcl-10', value: 204, code: 204 },
            { label: 'BrdU', value: 210, code: 210 },
            { label: 'C-MYC', value: 52, code: 52 },
            { label: 'C1q', value: 211, code: 211 },
            { label: 'C3c', value: 212, code: 212 },
            { label: 'C4c', value: 213, code: 213 },
            { label: 'CA125', value: 214, code: 214 },
            { label: 'CA15-3', value: 215, code: 215 },
            { label: 'CA19-9 ', value: 216, code: 216 },
            { label: 'CA50', value: 217, code: 217 },
            { label: 'CAM5', value: 124, code: 124 },
            { label: 'CD10', value: 6, code: 6 },
            { label: 'CD103', value: 27, code: 27 },
            { label: 'CD117', value: 28, code: 28 },
            { label: 'CD11c', value: 307, code: 307 },
            { label: 'CD123', value: 29, code: 29 },
            { label: 'CD127', value: 30, code: 30 },
            { label: 'CD13', value: 7, code: 7 },
            { label: 'CD137', value: 335, code: 335 },
            { label: 'CD137L', value: 336, code: 336 },
            { label: 'CD138', value: 31, code: 31 },
            { label: 'CD14', value: 8, code: 8 },
            { label: 'CD15', value: 58, code: 58 },
            { label: 'CD16', value: 9, code: 9 },
            { label: 'CD184', value: 337, code: 337 },
            { label: 'CD19', value: 10, code: 10 },
            { label: 'CD1α', value: 85, code: 85 },
            { label: 'CD2', value: 1, code: 1 },
            { label: 'CD20', value: 11, code: 11 },
            { label: 'CD200', value: 32, code: 32 },
            { label: 'CD21', value: 62, code: 62 },
            { label: 'CD22', value: 12, code: 12 },
            { label: 'CD23', value: 13, code: 13 },
            { label: 'CD24', value: 319, code: 319 },
            { label: 'CD25', value: 14, code: 14 },
            { label: 'CD269', value: 333, code: 333 },
            { label: 'CD27', value: 15, code: 15 },
            { label: 'CD28', value: 16, code: 16 },
            { label: 'CD3', value: 2, code: 2 },
            { label: 'CD3+CD16+CD56+', value: 42, code: 42 },
            { label: 'CD3+CD4+ ', value: 39, code: 39 },
            { label: 'CD3+CD56+ ', value: 46, code: 46 },
            { label: 'CD3+CD57+ ', value: 47, code: 47 },
            { label: 'CD3+CD8+ ', value: 40, code: 40 },
            { label: 'CD3+HLA-DR+ ', value: 43, code: 43 },
            { label: 'CD3+HLA-DR- ', value: 44, code: 44 },
            { label: 'CD3-CD16+CD56+ ', value: 41, code: 41 },
            { label: 'CD3-HLA-DR+ ', value: 45, code: 45 },
            { label: 'CD30', value: 65, code: 65 },
            { label: 'CD31', value: 135, code: 135 },
            { label: 'CD319', value: 334, code: 334 },
            { label: 'CD33', value: 17, code: 17 },
            { label: 'CD34', value: 67, code: 67 },
            { label: 'CD36', value: 18, code: 18 },
            { label: 'CD38', value: 19, code: 19 },
            { label: 'CD3ε', value: 87, code: 87 },
            { label: 'CD4', value: 3, code: 3 },
            { label: 'CD4+CD25+CD127Low', value: 50, code: 50 },
            { label: 'CD42', value: 148, code: 148 },
            { label: 'CD43', value: 20, code: 20 },
            { label: 'CD45', value: 21, code: 21 },
            { label: 'CD45RB', value: 74, code: 74 },
            { label: 'CD45RO', value: 72, code: 72 },
            { label: 'CD5', value: 53, code: 53 },
            { label: 'CD52', value: 22, code: 22 },
            { label: 'CD56', value: 23, code: 23 },
            { label: 'CD57', value: 90, code: 90 },
            { label: 'CD61', value: 149, code: 149 },
            { label: 'CD68', value: 78, code: 78 },
            { label: 'CD7', value: 4, code: 4 },
            { label: 'CD79a', value: 24, code: 24 },
            { label: 'CD79b', value: 25, code: 25 },
            { label: 'CD8', value: 5, code: 5 },
            { label: 'CD8+CD28+ ', value: 48, code: 48 },
            { label: 'CD8+CD28- ', value: 49, code: 49 },
            { label: 'CD81', value: 26, code: 26 },
            { label: 'CD99', value: 81, code: 81 },
            { label: 'CDX2', value: 154, code: 154 },
            { label: 'CEA', value: 181, code: 181 },
            { label: 'CK', value: 61, code: 61 },
            { label: 'CK19', value: 192, code: 192 },
            { label: 'CK34', value: 101, code: 101 },
            { label: 'CK5', value: 179, code: 179 },
            { label: 'CK5/6', value: 131, code: 131 },
            { label: 'CK7', value: 102, code: 102 },
            { label: 'CK8/18', value: 182, code: 182 },
            { label: 'CMV', value: 227, code: 227 },
            { label: 'CNPase', value: 230, code: 230 },
            { label: 'CR', value: 301, code: 301 },
            { label: 'CTN', value: 234, code: 234 },
            { label: 'CXCL-13 ', value: 236, code: 236 },
            { label: 'CXCL13', value: 68, code: 68 },
            { label: 'CYCLIN-D1', value: 51, code: 51 },
            { label: 'Calcitonin', value: 218, code: 218 },
            { label: 'Calponin', value: 219, code: 219 },
            { label: 'Calretinin', value: 220, code: 220 },
            { label: 'Caspase1', value: 221, code: 221 },
            { label: 'Caspase9', value: 222, code: 222 },
            { label: 'Catenin-α ', value: 223, code: 223 },
            { label: 'Catenin-β ', value: 224, code: 224 },
            { label: 'Catenin-γ ', value: 225, code: 225 },
            { label: 'CathepsinD', value: 226, code: 226 },
            { label: 'CgA', value: 100, code: 100 },
            { label: 'Ckpan', value: 123, code: 123 },
            { label: 'CollagenII', value: 231, code: 231 },
            { label: 'CollagenIV', value: 232, code: 232 },
            { label: 'Cullin', value: 235, code: 235 },
            { label: 'CyclinA', value: 237, code: 237 },
            { label: 'CyclinB1', value: 238, code: 238 },
            { label: 'CyclinD1', value: 239, code: 239 },
            { label: 'CyclinE', value: 240, code: 240 },
            { label: 'CyclinG', value: 241, code: 241 },
            { label: 'D2-40 ', value: 134, code: 134 },
            { label: 'DFAP', value: 321, code: 321 },
            { label: 'DOG-1', value: 243, code: 243 },
            { label: 'Dystrophin', value: 244, code: 244 },
            { label: 'E-cadherin ', value: 125, code: 125 },
            { label: 'EBER', value: 245, code: 245 },
            { label: 'EGFR', value: 103, code: 103 },
            { label: 'EMA', value: 75, code: 75 },
            { label: 'ER', value: 162, code: 162 },
            { label: 'ERCC1', value: 140, code: 140 },
            { label: 'ERG', value: 248, code: 248 },
            { label: 'ESA', value: 249, code: 249 },
            { label: 'EbV', value: 66, code: 66 },
            { label: 'Endostatin', value: 247, code: 247 },
            { label: 'FLI-1 ', value: 253, code: 253 },
            { label: 'FMC7', value: 37, code: 37 },
            { label: 'FN', value: 254, code: 254 },
            { label: 'FSH', value: 255, code: 255 },
            { label: 'Fas-L', value: 251, code: 251 },
            { label: 'Fascin', value: 250, code: 250 },
            { label: 'Fcr', value: 163, code: 163 },
            { label: 'Ferritin', value: 252, code: 252 },
            { label: 'GATA-3', value: 310, code: 310 },
            { label: 'GCDFP-15', value: 258, code: 258 },
            { label: 'GFAP', value: 104, code: 104 },
            { label: 'GPA', value: 151, code: 151 },
            { label: 'Galectin-3', value: 256, code: 256 },
            { label: 'Gastrin', value: 257, code: 257 },
            { label: 'Glucagon', value: 259, code: 259 },
            { label: 'Glut1', value: 260, code: 260 },
            { label: 'Glypican-3 ', value: 261, code: 261 },
            { label: 'Glypican3', value: 164, code: 164 },
            { label: 'Granulocyte', value: 262, code: 262 },
            { label: 'GranzymeB', value: 263, code: 263 },
            { label: 'GranzymeB(颗粒酶B）', value: 88, code: 88 },
            { label: 'H-Caldesmon', value: 266, code: 266 },
            { label: 'HBcAg', value: 264, code: 264 },
            { label: 'HBsAg', value: 265, code: 265 },
            { label: 'HCG', value: 267, code: 267 },
            { label: 'HHV8', value: 73, code: 73 },
            { label: 'HLA-DR', value: 271, code: 271 },
            { label: 'HLA-DR+', value: 38, code: 38 },
            { label: 'HMB45', value: 105, code: 105 },
            { label: 'HMGB1', value: 304, code: 304 },
            { label: 'HMW', value: 272, code: 272 },
            { label: 'HPV16', value: 273, code: 273 },
            { label: 'HRG', value: 274, code: 274 },
            { label: 'Hemoglobin', value: 268, code: 268 },
            { label: 'Hepatocyte', value: 269, code: 269 },
            { label: 'IgG', value: 106, code: 106 },
            { label: 'IgG4', value: 107, code: 107 },
            { label: 'Inhibin-α ', value: 275, code: 275 },
            { label: 'Insulin', value: 276, code: 276 },
            { label: 'Integrin', value: 277, code: 277 },
            { label: 'KIR', value: 93, code: 93 },
            { label: 'KP1', value: 313, code: 313 },
            { label: 'KRAS', value: 165, code: 165 },
            { label: 'Kappa', value: 80, code: 80 },
            { label: 'Keratin', value: 137, code: 137 },
            { label: 'Ki-67 ', value: 84, code: 84 },
            { label: 'Ki67', value: 133, code: 133 },
            { label: 'L26', value: 70, code: 70 },
            { label: 'LCA', value: 57, code: 57 },
            { label: 'LH', value: 280, code: 280 },
            { label: 'LMO2', value: 281, code: 281 },
            { label: 'LMP1', value: 312, code: 312 },
            { label: 'LMW', value: 282, code: 282 },
            { label: 'LRP', value: 283, code: 283 },
            { label: 'Lamda', value: 82, code: 82 },
            { label: 'Laminin', value: 278, code: 278 },
            { label: 'Leu7', value: 279, code: 279 },
            { label: 'MAP', value: 139, code: 139 },
            { label: 'MAP-2', value: 286, code: 286 },
            { label: 'MBP', value: 287, code: 287 },
            { label: 'MC', value: 288, code: 288 },
            { label: 'MDM2', value: 289, code: 289 },
            { label: 'MGMT', value: 292, code: 292 },
            { label: 'MHC', value: 293, code: 293 },
            { label: 'MLH1', value: 144, code: 144 },
            { label: 'MLH2', value: 166, code: 166 },
            { label: 'MLH6', value: 167, code: 167 },
            { label: 'MMP-3', value: 295, code: 295 },
            { label: 'MMP-7 ', value: 296, code: 296 },
            { label: 'MMR', value: 330, code: 330 },
            { label: 'MPO', value: 64, code: 64 },
            { label: 'MSH2', value: 145, code: 145 },
            { label: 'MSH6', value: 146, code: 146 },
            { label: 'MSI', value: 168, code: 168 },
            { label: 'MUM-1 ', value: 54, code: 54 },
            { label: 'Mammaglobin', value: 285, code: 285 },
            { label: 'Mart-1', value: 328, code: 328 },
            { label: 'MelanA', value: 290, code: 290 },
            { label: 'Melanoma', value: 291, code: 291 },
            { label: 'Meleno-pan', value: 327, code: 327 },
            { label: 'MiTF', value: 294, code: 294 },
            { label: 'MyoD1', value: 109, code: 109 },
            { label: 'NF', value: 112, code: 112 },
            { label: 'NKAIN2', value: 306, code: 306 },
            { label: 'NKX3', value: 309, code: 309 },
            { label: 'NRAS', value: 169, code: 169 },
            { label: 'NSE', value: 113, code: 113 },
            { label: 'NapsinA', value: 297, code: 297 },
            { label: 'Nesin', value: 193, code: 193 },
            { label: 'Nestin', value: 110, code: 110 },
            { label: 'NeuN', value: 111, code: 111 },
            { label: 'P-63', value: 300, code: 300 },
            { label: 'P120', value: 126, code: 126 },
            { label: 'P40', value: 180, code: 180 },
            { label: 'P504s', value: 189, code: 189 },
            { label: 'P53', value: 71, code: 71 },
            { label: 'P63/HCK', value: 183, code: 183 },
            { label: 'PAX8', value: 191, code: 191 },
            { label: 'PCK', value: 322, code: 322 },
            { label: 'PD-1', value: 69, code: 69 },
            { label: 'PDGFRA', value: 326, code: 326 },
            { label: 'PDL-1 ', value: 303, code: 303 },
            { label: 'PI3CA', value: 141, code: 141 },
            { label: 'PI3K', value: 128, code: 128 },
            { label: 'PMS2', value: 147, code: 147 },
            { label: 'PR', value: 320, code: 320 },
            { label: 'PS2', value: 129, code: 129 },
            { label: 'PSA', value: 308, code: 308 },
            { label: 'PSAP', value: 190, code: 190 },
            { label: 'PTEN', value: 170, code: 170 },
            { label: 'Pax-5', value: 79, code: 79 },
            { label: 'Perforin （穿孔素)', value: 98, code: 98 },
            { label: 'RAGE', value: 305, code: 305 },
            { label: 'Ras', value: 171, code: 171 },
            { label: 'S-100 ', value: 86, code: 86 },
            { label: 'SALL4', value: 311, code: 311 },
            { label: 'SMA', value: 117, code: 117 },
            { label: 'SOX-11', value: 89, code: 89 },
            { label: 'SY', value: 118, code: 118 },
            { label: 'Syn', value: 119, code: 119 },
            { label: 'T- bet ', value: 329, code: 329 },
            { label: 'TCR', value: 332, code: 332 },
            { label: 'TCR δ', value: 97, code: 97 },
            { label: 'TCRβ', value: 95, code: 95 },
            { label: 'TCRγ', value: 96, code: 96 },
            { label: 'TDT', value: 59, code: 59 },
            { label: 'TG', value: 323, code: 323 },
            { label: 'TIA-1', value: 60, code: 60 },
            { label: 'TILs', value: 173, code: 173 },
            { label: 'TP', value: 143, code: 143 },
            { label: 'TS', value: 142, code: 142 },
            { label: 'TTF-1 ', value: 132, code: 132 },
            { label: 'Tau', value: 316, code: 316 },
            { label: 'Telomerase', value: 172, code: 172 },
            { label: 'Topollα', value: 130, code: 130 },
            { label: 'UGT1A1', value: 174, code: 174 },
            { label: 'VEG', value: 315, code: 315 },
            { label: 'VEGFR-1b', value: 175, code: 175 },
            { label: 'VEGFR-2 ', value: 176, code: 176 },
            { label: 'VIP', value: 138, code: 138 },
            { label: 'Villin', value: 177, code: 177 },
            { label: 'Vim', value: 120, code: 120 },
            { label: 'Vimentin', value: 121, code: 121 },
            { label: 'WT-1', value: 302, code: 302 },
            { label: 'ZAP70', value: 92, code: 92 },
            { label: 'alk-1', value: 299, code: 299 },
            { label: 'aneuploidy', value: 152, code: 152 },
            { label: 'b-FGF ', value: 207, code: 207 },
            { label: 'bc12', value: 325, code: 325 },
            { label: 'c-Myb', value: 228, code: 228 },
            { label: 'cCD22', value: 317, code: 317 },
            { label: 'cKappa', value: 33, code: 33 },
            { label: 'cLambda', value: 34, code: 34 },
            { label: 'cerbB-2/Her-2', value: 127, code: 127 },
            { label: 'cin', value: 155, code: 155 },
            { label: 'ck20', value: 156, code: 156 },
            { label: 'ck8', value: 157, code: 157 },
            { label: 'cox', value: 158, code: 158 },
            { label: 'cox2', value: 159, code: 159 },
            { label: 'ctcs', value: 160, code: 160 },
            { label: 'desmin', value: 242, code: 242 },
            { label: 'dpd', value: 161, code: 161 },
            { label: 'hGH', value: 270, code: 270 },
            { label: 'heppar-1', value: 324, code: 324 },
            { label: 'kis', value: 83, code: 83 },
            { label: 'lysozyme', value: 284, code: 284 },
            { label: 'lysozyme（溶菌酶）', value: 99, code: 99 },
            { label: 'mES-13', value: 314, code: 314 },
            { label: 'mES13I', value: 136, code: 136 },
            { label: 'myogenin', value: 298, code: 298 },
            { label: 'neuropilin-1', value: 331, code: 331 },
            { label: 'p16', value: 114, code: 114 },
            { label: 'p63', value: 115, code: 115 },
            { label: 'sCD22', value: 318, code: 318 },
            { label: 'sIgD', value: 35, code: 35 },
            { label: 'sIgM', value: 36, code: 36 },
            { label: 'xrcc1', value: 178, code: 178 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 免疫表型_免疫表型结果
        immuneResult: [
            { label: '阳性', value: 10, code: 10 },
            { label: '阴性', value: 1, code: 1 },
            { label: '±', value: 2, code: 2 },
            { label: '+', value: 3, code: 3 },
            { label: '++', value: 4, code: 4 },
            { label: '+++', value: 5, code: 5 },
            { label: '++++', value: 14, code: 14 },
            { label: '少+ ', value: 6, code: 6 },
            { label: ' 灶+', value: 7, code: 7 },
            { label: '弱+', value: 8, code: 8 },
            { label: '部分+', value: 9, code: 9 },
            { label: '强+', value: 11, code: 11 },
            { label: '多+ ', value: 12, code: 12 },
            { label: '散+', value: 13, code: 13 },
            { label: '未检测', value: 997, code: 997 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
            { label: '显示残留FDC网', value: 15, code: 15 },
        ],
        // 组织学类型
        partType: [
            // { label:'ll',code: null },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 能否提供组织病理切片
        partPice: [
            { label: '能', value: 1, code: 1 },
            { label: '否', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },

    // 诊断信息
    diagnosticInformation: {
        // 临床诊断
        // diag: [
        //     { value: 997, code: 997 },
        //     { code: null },
        //     { value: 998, code: 998 },
        //     { value: 999, code: 999 },
        // ],

        // 肿瘤部位
        tumorPart: [
            { label: '升结肠', value: 1, code: 1 },
            { label: '结肠肝曲', value: 2, code: 2 },
            { label: '横结肠', value: 3, code: 3 },
            { label: '结肠脾曲', value: 4, code: 4 },
            { label: '降结肠', value: 5, code: 5 },
            { label: '乙状结肠', value: 6, code: 6 },
            { label: '直肠', value: 7, code: 7 },
            { label: '降乙交界', value: 8, code: 8 },
            { label: '直乙交界', value: 9, code: 9 },
            { label: '全结肠', value: 10, code: 10 },
            { label: '全结直肠', value: 11, code: 11 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 危险分层
        dangerPart: [
            { label: '低危', value: 1, code: 1 },
            { label: '中危', value: 2, code: 2 },
            { label: '高危', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 分子分型
        molecular: [
            { label: 'LuminA型', value: 1, code: 1 },
            { label: 'LuminalB型（HER-2阴性)', value: 2, code: 2 },
            { label: 'LuminalB型（HER-2阳性）', value: 3, code: 3 },
            { label: 'HER-2过表达型', value: 4, code: 4 },
            { label: '三阴性', value: 5, code: 5 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 临床分期类别
        clinicalStage: [
            { label: 'TNM分期', value: 1, code: 1 },
            { label: 'Lugano分期', value: 2, code: 2 },
            { label: 'ANN分期', value: 3, code: 3 },
            { label: 'ISS分期', value: 4, code: 4 },
            { label: 'R-ISS分期', value: 5, code: 5 },
            { label: 'DS分期', value: 6, code: 6 },
            { label: '小细胞肺癌分期', value: 7, code: 7 },
            { label: 'Rai分期', value: 8, code: 8 },
            { label: 'Binet分期', value: 9, code: 9 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // TNM分期
        tnm: [
            { label: 'Stage 0 ', value: 1, code: 1 },
            { label: 'Stage 0A ', value: 2, code: 2 },
            { label: 'Stage 0is ', value: 2, code: 2 },
            { label: 'Stage I ', value: 3, code: 3 },
            { label: 'Stage IA ', value: 4, code: 4 },
            { label: 'Stage IA1 ', value: 6, code: 6 },
            { label: 'Stage IA2 ', value: 7, code: 7 },
            { label: 'Stage IB ', value: 8, code: 8 },
            { label: 'Stage IB1 ', value: 9, code: 9 },
            { label: 'Stage IB2 ', value: 10, code: 10 },
            { label: 'Stage IC ', value: 11, code: 11 },
            { label: 'Stage IS ', value: 12, code: 12 },
            { label: 'Stage II ', value: 13, code: 13 },
            { label: 'Stage IIA ', value: 14, code: 14 },
            { label: 'Stage IIA1 ', value: 15, code: 15 },
            { label: 'Stage IIA2 ', value: 16, code: 16 },
            { label: 'Stage IIB ', value: 17, code: 17 },
            { label: 'Stage IIC ', value: 18, code: 18 },
            { label: 'Stage III ', value: 19, code: 19 },
            { label: 'Stage IIIA ', value: 20, code: 20 },
            { label: 'Stage IIIB ', value: 21, code: 21 },
            { label: 'Stage IIIC ', value: 22, code: 22 },
            { label: 'Stage IIIC1 ', value: 23, code: 23 },
            { label: 'Stage IIIC2 ', value: 24, code: 24 },
            { label: 'Stage IV ', value: 25, code: 25 },
            { label: 'Stage IVA ', value: 26, code: 26 },
            { label: 'Stage IVA1 ', value: 27, code: 27 },
            { label: 'Stage IVA2 ', value: 28, code: 28 },
            { label: 'Stage IVB ', value: 29, code: 29 },
            { label: 'Stage IVC ', value: 30, code: 30 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // Lugano分期
        lugano: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅰ1期', value: 2, code: 2 },
            { label: 'Ⅰ2期', value: 3, code: 3 },
            { label: 'Ⅱ期', value: 4, code: 4 },
            { label: 'Ⅱ1期', value: 5, code: 5 },
            { label: 'Ⅱ2期', value: 6, code: 6 },
            { label: 'ⅡE期', value: 7, code: 7 },
            { label: 'Ⅲ期', value: 8, code: 8 },
            { label: 'Ⅳ期', value: 9, code: 9 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // ANN分期
        ann: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅱ期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: 'Ⅳ期', value: 4, code: 4 },
            { label: 'ⅠE期', value: 5, code: 5 },
            { label: 'ⅡE期', value: 6, code: 6 },
            { label: 'ⅢS期', value: 7, code: 7 },
            { label: 'ⅢE期', value: 8, code: 8 },
            { label: 'Ⅱbulky', value: 9, code: 9 },
            { label: 'ⅢSE', value: 10, code: 10 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // R-ISS分期
        riss: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅱ期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // DS分期
        ds: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅱ期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // ISS分期
        iss: [
            { label: 'I期', value: 1, code: 1 },
            { label: 'II期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // Rai分期
        rai: [
            { label: '0期', value: 1, code: 1 },
            { label: 'Ⅰ期', value: 2, code: 2 },
            { label: 'Ⅱ期', value: 3, code: 3 },
            { label: 'Ⅲ期', value: 4, code: 4 },
            { label: 'Ⅳ期', value: 5, code: 5 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // Binet分期
        binet: [
            { label: 'A期', value: 1, code: 1 },
            { label: 'B期', value: 2, code: 2 },
            { label: 'C期', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 小细胞肺癌分期
        carcinoma: [
            { label: '局限期', value: 1, code: 1 },
            { label: '广泛期', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 肾病分期 肾病
        nephropathy: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅱ期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: 'Ⅳ期', value: 4, code: 4 },
            { label: 'V期', value: 5, code: 5 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 视网膜病变分期
        retinopathy: [
            { label: 'Ⅰ期', value: 1, code: 1 },
            { label: 'Ⅱ期', value: 2, code: 2 },
            { label: 'Ⅲ期', value: 3, code: 3 },
            { label: 'Ⅳ期', value: 4, code: 4 },
            { label: 'V期', value: 5, code: 5 },
            { label: 'VI期', value: 6, code: 6 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 脂肪肝程度
        fatty: [
            { label: 'F0（轻度）', value: 1, code: 1 },
            { label: 'F1（中度）', value: 2, code: 2 },
            { label: 'F2（中重度）', value: 3, code: 3 },
            { label: 'F3（重度）', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 脂肪肝B超诊断分度
        fattyB: [
            { label: '轻度', value: 1, code: 1 },
            { label: '中度', value: 2, code: 2 },
            { label: '重度', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 过敏性鼻炎分度
        allergic: [
            { label: '轻度间歇性', value: 1, code: 1 },
            { label: '中-种度间歇性', value: 2, code: 2 },
            { label: '轻度持续性', value: 3, code: 3 },
            { label: '中-重持续性', value: 4, code: 4 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 糖尿病病足wagner分级
        diabetes: [
            { label: '1级', value: 1, code: 1 },
            { label: '2级', value: 2, code: 2 },
            { label: '3级', value: 3, code: 3 },
            { label: '4级', value: 4, code: 4 },
            { label: '5级', value: 5, code: 5 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],

        // 糖尿病病足类型
        diabetesType: [
            { label: '缺血性', value: 1, code: 1 },
            { label: '神经性', value: 2, code: 2 },
            { label: '神经-缺血性', value: 3, code: 3 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },

    // 治疗信息
    treatInfos: {
        // 手术信息
        operation: {
            // 有无手术
            isOpertation: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 用药信息
        medication: {
            // 治疗阶段
            medicalStage: [
                { label: '一线化疗', value: 1, code: 1 },
                { label: '二线化疗', value: 2, code: 2 },
                { label: '三线化疗', value: 3, code: 3 },
                { label: '四线及以后化疗', value: 4, code: 4 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 治疗类别
            medicalType: [
                { label: '化疗', value: 1, code: 1 },
                { label: '靶向治疗', value: 2, code: 2 },
                { label: '内分泌治疗', value: 3, code: 3 },
                { label: '支持治疗', value: 4, code: 4 },
                { label: '新辅助化疗', value: 5, code: 5 },
                { label: '辅助化疗', value: 6, code: 6 },
                { label: '维持治疗', value: 7, code: 7 },
                { label: '手术治疗', value: 8, code: 8 },
                { label: '放射治疗', value: 9, code: 9 },
                { label: '质子治疗', value: 10, code: 10 },
                { label: 'car-t治疗', value: 11, code: 11 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 用药方式
            medicalWay: [
                { label: '静脉滴注', value: 1, code: 1 },
                { label: '静脉推注', value: 2, code: 2 },
                { label: '口服', value: 3, code: 3 },
                { label: '皮下', value: 4, code: 4 },
                { label: '肌肉', value: 5, code: 5 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 剂量单位
            dose: [
                { label: 'mg', value: 1, code: 1 },
                { label: 'μg', value: 2, code: 2 },
                { label: 'g', value: 3, code: 3 },
                { label: 'IU', value: 4, code: 4 },
                { label: 'ml', value: 5, code: 5 },
                { label: 'U', value: 6, code: 6 },
                { label: '片', value: 7, code: 7 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 用药频率
            medicalRate: [
                { label: '持续', value: 1, code: 1 },
                { label: '按需', value: 2, code: 2 },
                { label: '每日一次(QD)', value: 3, code: 3 },
                { label: '每日两次(BID)', value: 4, code: 4 },
                { label: '每日三次(TID)', value: 5, code: 5 },
                { label: '每日四次', value: 6, code: 6 },
                { label: '隔日一次', value: 7, code: 7 },
                { label: '每周一次', value: 8, code: 8 },
                { label: '每周两次', value: 9, code: 9 },
                { label: '每周三次', value: 10, code: 10 },
                { label: '每周四次', value: 11, code: 11 },
                { label: '每两周', value: 12, code: 12 },
                { label: '每月', value: 13, code: 13 },
                { label: '每月两次', value: 14, code: 14 },
                { label: '每月三次', value: 15, code: 15 },
                { label: '每两个月', value: 16, code: 16 },
                { label: '每三个月', value: 17, code: 17 },
                { label: '每四个月', value: 18, code: 18 },
                { label: '间歇的', value: 19, code: 19 },
                { label: 'QN', value: 20, code: 20 },
                { label: 'Q4h', value: 21, code: 21 },
                { label: 'Q6h', value: 22, code: 22 },
                { label: 'Q8h', value: 23, code: 23 },
                { label: 'Q12h', value: 24, code: 24 },
                { label: 'Q72h', value: 25, code: 25 },
                { label: '每三周', value: 26, code: 26 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 有无剂量调整
            isDose: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 调整原因
            changeReason: [
                { label: '无', value: 1, code: 1 },
                { label: '患者的选择', value: 2, code: 2 },
                { label: '缺乏疗效', value: 3, code: 3 },
                { label: '副作用', value: 4, code: 4 },
                { label: '濒临死亡', value: 5, code: 5 },
                { label: '身体情况差', value: 6, code: 6 },
                { label: '局部复发', value: 7, code: 7 },
                { label: '远处转移', value: 8, code: 8 },
                { label: '失访', value: 9, code: 9 },
                { label: '妊娠', value: 10, code: 10 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 剂量变化:
            doseChange: [
                { label: '停止用药', value: 1, code: 1 },
                { label: '剂量减少', value: 2, code: 2 },
                { label: '剂量增加', value: 3, code: 3 },
                { label: '剂量不变', value: 4, code: 4 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 用药依从性
            compliance: [
                { label: '按时服用', value: 1, code: 1 },
                { label: '漏服', value: 2, code: 2 },
                { label: '自行停药', value: 3, code: 3 },
                { label: '不服药', value: 4, code: 4 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 终止原因
            stopReaseon: [
                { label: '死亡', value: 1, code: 1 },
                { label: '疾病进展', value: 2, code: 2 },
                { label: '不良反应', value: 3, code: 3 },
                { label: '更换用药', value: 4, code: 4 },
                { label: '转诊停药', value: 5, code: 5 },
                { label: '体力评分差停药', value: 6, code: 6 },
                { label: '失访', value: 7, code: 7 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 不良事件信息
        bads: {
            // 有无不良事件
            isBad: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 不良事件_不良事件严重程度
            badDegree: [
                { label: '轻度', value: 1, code: 1 },
                { label: '中度', value: 2, code: 2 },
                { label: '重度', value: 3, code: 3 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 不良事件_不良事件处理方法
            badSolution: [
                { label: '密切观察', value: 1, code: 1 },
                { label: '常规药事指导处理', value: 2, code: 2 },
                { label: '主诊医师协助处理', value: 3, code: 3 },
                { label: '厂家协助处理', value: 4, code: 4 },
                { label: '就近医疗机构处理', value: 5, code: 5 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 不良事件_不良事件结局
            badResult: [
                { label: '痊愈', value: 1, code: 1 },
                { label: '好转', value: 2, code: 2 },
                { label: '未好转', value: 3, code: 3 },
                { label: '有后遗症', value: 4, code: 4 },
                { label: '死亡', value: 5, code: 5 },
                { label: '加重', value: 6, code: 6 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 疗效信息
        efficacy: {
            // 疗效评估
            efficacyInfo: [
                { label: 'CR', value: 1, code: 1 },
                { label: 'PR', value: 2, code: 2 },
                { label: 'SD', value: 3, code: 3 },
                { label: 'PD', value: 4, code: 4 },
                { label: 'iCR', value: 5, code: 5 },
                { label: 'iPR', value: 6, code: 6 },
                { label: 'iUPD', value: 7, code: 7 },
                { label: 'iCPD', value: 8, code: 8 },
                { label: 'iSD', value: 9, code: 9 },
                { label: '无法评估', value: 10, code: 10 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 肿瘤进展类型
            tumorProgression: [
                { label: '局部复发', value: 1, code: 1 },
                { label: '远处转移', value: 2, code: 2 },
                { label: '区域淋巴结转移', value: 3, code: 3 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 进展部位
            progressionPart: [
                { label: '淋巴结', value: 1, code: 1 },
                { label: '肺', value: 2, code: 2 },
                { label: '纵隔', value: 3, code: 3 },
                { label: '胸膜', value: 4, code: 4 },
                { label: '其他呼吸器官', value: 5, code: 5 },
                { label: '小肠', value: 6, code: 6 },
                { label: '大肠和直肠', value: 7, code: 7 },
                { label: '腹膜后和腹膜', value: 8, code: 8 },
                { label: '肝', value: 9, code: 9 },
                { label: '其他消化器官', value: 10, code: 10 },
                { label: '肾和肾盂', value: 11, code: 11 },
                { label: '膀胱和其他泌尿器官', value: 12, code: 12 },
                { label: '皮肤', value: 13, code: 13 },
                { label: '脑和脑膜', value: 14, code: 14 },
                { label: '其他神经系统', value: 15, code: 15 },
                { label: '骨和骨髓', value: 16, code: 16 },
                { label: '卵巢', value: 17, code: 17 },
                { label: '肾上腺', value: 18, code: 18 },
                { label: '心包', value: 19, code: 19 },
                { label: '脑', value: 20, code: 20 },
                { label: '对侧乳腺', value: 21, code: 21 },
                { label: '软组织', value: 22, code: 22 },
                { label: '胸壁', value: 23, code: 23 },
                { label: '同侧乳腺', value: 24, code: 24 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 治疗效果
            effect: [
                { label: '痊愈', value: 1, code: 1 },
                { label: '好转', value: 2, code: 2 },
                { label: '未好转', value: 3, code: 3 },
                { label: '有后遗症', value: 4, code: 4 },
                { label: '死亡', value: 5, code: 5 },
                { label: '加重', value: 6, code: 6 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 输血信息
        bloodInfo: {
            // 有无输血
            isBlood: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 输血类别
            bloodType: [
                { label: '血小板', value: 1, code: 1 },
                { label: '血浆', value: 2, code: 2 },
                { label: '全血', value: 3, code: 3 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 移植信息
        migration: {
            // 有无移植
            isMig: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
            // 移植类型
            migType: [
                { label: '自体', value: 1, code: 1 },
                { label: '异体', value: 2, code: 2 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
        // 放疗信息
        radio: {
            // 有无放疗
            isRadio: [
                { label: '有', value: 1, code: 1 },
                { label: '无', value: 3, code: 3 },
                { label: '未知', value: 998, code: 998 },
                { label: '其他', value: 999, code: 999 },
            ],
        },
    },

    // 费用信息
    costInfos: {
        // 费用类别
        costType: [
            { label: '手术操作费', value: 1, code: 1 },
            { label: '护理费', value: 2, code: 2 },
            { label: '换药费', value: 3, code: 3 },
            { label: '器材使用费', value: 4, code: 4 },
            { label: '服务费', value: 5, code: 5 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 随访信息
    followInfos: {
        // 是否随访
        isFollow: [
            { label: '是', value: 1, code: 1 },
            { label: '否', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 生存状态
        status: [
            { label: '生存（肿瘤情况不详）', value: 1, code: 1 },
            { label: '死亡', value: 2, code: 2 },
            { label: '进展', value: 3, code: 3 },
            { label: '复发', value: 4, code: 4 },
            { label: '转移', value: 5, code: 5 },
            { label: '生存（无瘤）', value: 6, code: 6 },
            { label: '生存（肿瘤稳定）', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 死亡原因
        deadReason: [
            { label: '与治疗相关', value: 1, code: 1 },
            { label: '与癌症相关-局部复发', value: 2, code: 2 },
            { label: '与癌症相关-远处复发', value: 3, code: 3 },
            { label: '与癌症相关-具体情况未知', value: 4, code: 4 },
            { label: '与治疗可能相关', value: 5, code: 5 },
            { label: '与癌症相关-恶病质', value: 6, code: 6 },
            { label: '与癌症无关', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 健康指导
    healthGuide: {
        // 健康评价
        healthInfo: [
            { label: '无异常', value: 1, code: 1 },
            { label: '有异常', value: 2, code: 2 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
        // 生活方式干预
        lifeWay: [
            { label: '戒烟', value: 1, code: 1 },
            { label: '健康饮酒', value: 2, code: 2 },
            { label: '控制饮食', value: 3, code: 3 },
            { label: '锻炼', value: 4, code: 4 },
            { label: '减体重', value: 5, code: 5 },
            { label: '建议接种疫苗', value: 6, code: 6 },
            { label: '适当运动', value: 7, code: 7 },
            { label: '未知', value: 998, code: 998 },
            { label: '其他', value: 999, code: 999 },
        ],
    },
    // 单纯性肝病部位专用选项
    liverParts: [
        {
            partId: 1,
            partName: '左肝',
            uniqueCode: '1',
        },
        {
            partId: 2,
            partName: '右肝',
            uniqueCode: '2',
        },
        {
            partId: 998,
            partName: '未知',
            uniqueCode: '998',
        },
        {
            partId: 999,
            partName: '其他',
            uniqueCode: '999',
        },
    ],
    // 单纯性肾部位专用选项
    nephropathyParts: [
        {
            partId: 1,
            partName: '左肾',
            uniqueCode: '1',
        },
        {
            partId: 2,
            partName: '右肾',
            uniqueCode: '2',
        },
        {
            partId: 998,
            partName: '未知',
            uniqueCode: '998',
        },
        {
            partId: 999,
            partName: '其他',
            uniqueCode: '999',
        },
    ],
    // 乳房结节部位专用选项
    breastParts: [
        {
            partId: 1,
            partName: '左乳',
            uniqueCode: '1',
        },
        {
            partId: 2,
            partName: '右乳',
            uniqueCode: '2',
        },
        {
            partId: 998,
            partName: '未知',
            uniqueCode: '998',
        },
        {
            partId: 999,
            partName: '其他',
            uniqueCode: '999',
        },
    ],
};

export const baseInfos = {
    recordDate: '记录日期',
    name: '姓名',
    gender: '性别',
    idNo: '身份证号',
    age: '年龄',
    nation: '民族',
    birthDate: '出生日期',
    phone: '本人手机号',
    insurance: '医保情况',
    residence: '户籍地址',
    address: '详细地址',
    street: '详细地址(备注)',
    email: '邮箱地址',
    contacter: '联系人姓名',
    contactPhone: '联系人电话',
    educational: '学历',
    job: '职业',
    workYear: '工作年限',
};
export const medicalInformation = {
    medicalNo: '病历号',
    visitDate: '就诊日期',
    hospitalDate: '入院日期',
    dischargeDate: '出院日期',
    visitType: '就诊类型',
    hospitalName: '就诊医院',
    admissionDate: '入科日期',
    admissionDays: '入科天数',
    departmentName: '就诊科室',
    doctor: '主治医生',
};
export const symptomsAndSigns = {
    recordDate: '记录日期',
    description: '主诉',
    sym: '症状_临床症状',
    symDate: '症状_症状发生时间',
    symDays: '症状_症状持续时间',
    symNumbers: '症状_症状发生次数',
    symChange: '症状_临床症状变化',
    present: '现病史',
    high: '身高',
    weigh: '体重',
    bmi: '体质指数BMI',
    waist: '腰围',
    disable: '残疾情况',
    tem: '体征_体温',
    pulse: '体征_脉搏',
    breathRate: '体征_呼吸频率',
    leftDiastolic: '体征_左侧舒张压',
    leftSystolic: '体征_左侧收缩压',
    rightDiastolic: '体征_右侧舒张压',
    rightSystolic: '体征_右侧收缩压',
    dayFunction: '失眠_日间功能障碍',
    nightFunction: '失眠_夜间功能障碍',
    sleepTime: '失眠_总睡眠时间',
    result: '睡眠量表检测结果',
};
export const pastHistoryInformation = {
    recordDate: '记录日期',
    smoke: '吸烟史_吸烟状况',
    smokeNumber: '吸烟史_日吸烟量（每日平均支数)',
    startSmokeAge: '吸烟史_开始吸烟年龄',
    endSmokeAge: '吸烟史_戒烟年龄',
    dangerous: '危险因素接触史_危险因素接触物(组合无限加)',
    dangerousRate: '危险因素接触史_每月频次(组合无限加)',
    dangerousPro: '危险因素接触史_防护措施(组合无限加)',
    drinkStatus: '饮酒史_饮酒状况',
    drinkStrenth: '饮酒史_次饮酒量',
    startDrinkAge: '饮酒史_开始饮酒年龄',
    endDrinkAge: '饮酒史_戒酒年龄',
    drunkTimes: '饮酒史_近一年内醉酒次数',
    drinkType: '饮酒史_饮酒种类',
    exerciseWays: '生活方式_锻炼方式',
    exerciseStrenth: '生活方式_锻炼强度',
    lifestyleExerciseFrequency: '生活方式_锻炼频率',
    exerciseDays: '生活方式_坚持锻炼时间',
    exerciseTime: '生活方式_每次锻炼时间',
    sleepTime: '生活方式_睡眠时长',
    lifestyleSleepPatterns: '生活方式_睡眠状况',
    lifestyleEatingHabits: '生活方式_饮食习惯',
    marriage: '婚育月经史_婚姻状况',
    birth: '婚育月经史_生育情况',
    pregnantNumber: '婚育月经史_怀孕次数',
    birthNumber: '婚育月经史_生产次数',
    birthWay: '婚育月经史_生产方式',
    abnormal: '婚育月经史_妊娠期间异常情况',
    menstruation: '婚育月经史_月经情况',
    menstruationAge: '婚育月经史_初潮年龄',
    menstruationDate: '婚育月经史_月经周期',
    menstruationDays: '婚育月经史_持续时间',
    menstruationEndDate: '婚育月经史_末次月经时间',
    menstruationStatus: '婚育月经史_周期是否规则',
    menstruationAche: '婚育月经史_有无痛经',
    menstruationVolume: '婚育月经史_月经量',
    volumeMark: '婚育月经史_备注',
    familyRela: '家族史_与本人关系',
    familyHis: '家族史_家族疾病史',
    allergyStatus: '过敏史_有无过敏史',
    allergyName: '过敏史_药品通用名',
    allergyOther: '过敏史_其他物质',
    allergyMark: '过敏史_备注',
    inoculationName: '预防接种史_非免疫规划预防接种疫苗名称',
    inoculationDate: '预防接种史_接种日期',
};
export const examine = {
    examineDate: '检查日期',
    reportDate: '报告日期',
    examineProjects: '检查项目',
    examineResult: '检查结果',
    blood: '周边血流情况',
    bloodGlucose: '空腹血糖',
    eatGlucose: '餐后2小时血糖',
    hba1c: '糖化血红蛋白(Hba1C)',
    creat: '肌酐(Creat)',
    bun: '尿素氮（BUN）',
    urate: '尿酸(Urate)',
    hgb: '血红蛋白(HGB)',
    pylori: '幽门螺旋杆菌',
    ast: '天冬氨酸转氨酶(AST)',
    alt: '丙氨酸转氨酶(ALT)',
    rggt: 'γ-谷氨酰胺转移酶',
    ft3: '游离3-碘甲腺原氨酸（FT3）',
    ft4: '游离甲状腺素（FT4）',
    tsh: '促甲状腺激素（TSH）',
    tpo: '甲状腺过氧化物酶抗体（TPO-Ab）',
    tgab: '甲状腺球蛋白抗体（Tg-Ab）',
    trig: '甘油三酯(Trig)',
    chol: '总胆固醇(Chol)',
    cdl: '低密度脂蛋白胆固醇(Ldl)',
    hdl: '高密度脂蛋白胆固醇(Hdl)',
    rh: 'RH型',
    examinePart: '检查部位',
    imageFind: '影像学发现',
    tissuePart: '组织部位',
    numbers: '数目',
    long: '纵径',
    across: '横径',
    breast: '乳腺组织构成',
    breastNature: '组织性质',
    breastForm: '组织形态',
    breastDirect: '组织方向',
    innerEcho: '内部回声',
    outerEcho: '外部回声',
    edge: '边缘情况',
    cao: '钙化情况',
    tirads: 'TI-RADS分级',
    lymph: '淋巴结整体情况',
    birads: 'BI-RADS分级',
    uterusSize: '子宫大小',
    gene: '基因检查',
    geneName: '基因检测_基因检测名称（组合无限加）',
    geneResult: '基因检测_基因检测结果（组合无限加）',
    pahExamine: '病理检查',
    immuneName: '免疫表型_免疫表型名称',
    immuneResult: '免疫表型_免疫表型结果',
    partType: '组织学类型',
    partPice: '能否提供组织病理切片',
};
export const diagnosticInformation = {
    diagDate: '确诊日期',
    firstDate: '首次确诊日期',
    endDate: '末次确诊日期',
    diagMark: '临床诊断（备注）',
    clinicalDiagnosis: '临床诊断',
    diseaseTime: '患病时长',
    diseaseTimes: '发病次数',
    tumorPart: '肿瘤部位',
    dangerPart: '危险分层',
    molecular: '分子分型',
    clinicalStage: '临床分期类别',
    tnm: 'TNM分期',
    lugano: 'Lugano分期',
    ann: 'ANN分期',
    riss: 'R-ISS分期',
    ds: 'DS分期',
    iss: 'ISS分期',
    rai: 'Rai分期',
    binet: 'Binet分期',
    carcinoma: '小细胞肺癌分期',
    nephropathy: '"肾病分期',
    retinopathy: '视网膜病变分期',
    fatty: '脂肪肝程度',
    allergic: '过敏性鼻炎分度',
    diabetes: '糖尿病病足wagner分级',
    diabetesType: '糖尿病病足类型',
};
export const treatInfos = {
    isOpertation: '有无手术',
    operDate: '手术日期',
    operName: '手术名称',
    bracketNum: '支架个数',
    mark: '备注',
    medicalStage: '治疗阶段',
    medicalType: '治疗类别',
    customerName: '药品厂家',
    drugUnit: '药品规格',
    drugNum: '药品数量',
    commonName: '药物通用名',
    drugName: '药物商品名',
    startDate: '放疗开始日期',
    endDate: '用药结束日期',
    drugDose: '每次给药剂量',
    medicalWay: '用药方式',
    dose: '剂量单位',
    medicalRate: '用药频率',
    isDose: '有无剂量调整',
    changeReason: '调整原因',
    doseChange: '剂量变化',
    compliance: '用药依从性',
    stopReaseon: '终止原因',
    medicalChange: '是否换药',
    changeDrugName: '更换用药的名称',
    medicalGuide: '用药指导',
    isBad: '有无不良事件',
    badName: '不良事件_不良事件名称',
    badDegree: '不良事件_不良事件严重程度',
    badSolution: '不良事件_不良事件处理方法',
    badResult: '不良事件_不良事件结局',
    efficacyDate: '疗效评估日期',
    efficacyInfo: '疗效评估',
    tumorProgression: '肿瘤进展类型',
    progressionDate: '复发或进展日期',
    progressionPart: '进展部位',
    effect: '治疗效果',
    com: '耗材厂家',
    price: '耗材单价',
    name: '耗材名称',
    number: '耗材数量',
    equipName: '使用器材名称',
    isBlood: '有无输血',
    bloodTime: '输血时间',
    bloodStrengh: '输血剂量',
    bloodType: '输血类别',
    bloodMark: '备注',
    isMig: '有无移植',
    harveDate: '采干日期（自体）',
    migType: '移植类型',
    migDate: '移植日期（异体）',
    isRadio: '有无放疗',
    radioTimes: '放疗次数',
    radioNum: '放疗总剂量',
    treatInfo: '治疗意见',
    treatMark: '治疗信息备注',
};
export const costInfos = {
    unormalInfo: '费用发生日期',
    costType: '费用类别',
    socialCost: '社保内自付金额',
    insuranceCost: '医保报销比例',
    selfCost: '自费金额',
    sum: '总费用',
};

export const medicinal = {
    // 是否过敏
    isDrug: [
        { label: '是', value: 1, code: 1 },
        { label: '否', value: 2, code: 2 },
    ],
    drugMedicinal: [
        { name: '青霉素', code: 1 },
        { name: '头孢类抗生素', code: 2 },
        { name: '破伤风抗生素（TAT）', code: 3 },
        { name: '普鲁卡因', code: 4 },
        { name: '地卡因', code: 5 },
        { name: '磺胺类药物', code: 6 },
        { name: '维生素B1', code: 7 },
        { name: '泛影葡胺', code: 8 },
        { name: '阿司匹林', code: 9 },
        { name: '链霉素', code: 10 },
        { name: '安定', code: 11 },
        { name: '其他', code: 12 },
    ],
    isFoodAllergy: [
        {
            name: '是', code: 1, isLink: true,
        },
        {
            name: '否', code: 2, isLink: false,
        },
    ],
    foodAllergy: [
        { name: '鸡蛋', code: 1, checked: false },
        { name: '面粉', code: 2, checked: false },
        { name: '坚果', code: 3, checked: false },
        { name: '海鲜', code: 4, checked: false },
        { name: '豆制品', code: 5, checked: false },
        { name: '牛奶', code: 6, checked: false },
        { name: '其他', code: 7, checked: false },
    ],
    isContactAllergy: [
        {
            name: '是', code: 1, isLink: true, checked: false,
        },
        {
            name: '否', code: 2, isLink: false, checked: false,
        },
    ],
    contactAllergy: [
        { name: '花粉/草', code: 1, checked: false },
        { name: '动物毛发', code: 2, checked: false },
        { name: '酒精', code: 3, checked: false },
        { name: '粉尘', code: 4, checked: false },
        { name: '螨虫', code: 5, checked: false },
        { name: '铁', code: 6, checked: false },
        { name: '其他', code: 7, checked: false },
    ],

};
// 与员工关系
export const relation = {
    1: '企业员工',
    2: '员工父母',
    3: '员工配偶',
    4: '员工子女',
    5: '其他员工家属',
}
