/*
 * @Author: qiu
 * @Date: 2022-11-29 15:57:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-20 17:12:51
 * @Description:
 * @FilePath: /src/utils/jsencrypt.ts
 */
import JSEncrypt from 'jsencrypt';

/**
 * @description: 加密密码
 * @param {string} pubkey 公钥
 * @param {string} pwd 密码
 * @return {string|boolean} 加密后文本 | 失败返回false
 */
const jsEncrypt = (pubkey, pwd) => {
  const encrypt = new JSEncrypt({ default_key_size: '1024' });
  encrypt.setPublicKey(pubkey);
  return encrypt.encrypt(pwd);
};

export default jsEncrypt;
