import Configs from '@/config';

/**
 * 获取当前路径 除了token的全部路径
 * @returns 返回除了token的全部路径
 */
const getCurrentPath = () => {
  const { search } = window.location;
  let arr = [];
  const param = {};
  if (search.startsWith('?')) {
    const tem = search.replace('?', '');
    arr = tem.split('&');
    arr.forEach((e) => {
      const [key, value] = e.split('=');
      param[key] = value;
    });
  }

  let p = [];
  Object.keys(param).forEach((e) => {
    if (e !== 'token') {
      p.push(`${e}=${param[e]}`);
    }
  });

  const { origin, pathname } = window.location;
  let currentPath = `${origin}${pathname}`;
  if (p.length > 0) {
    currentPath += `?${p.join('&')}`;
  }
  return currentPath;
};
/**
 * 获取页面路径参数
 * @param {string} name key
 * @param {boolean} hash 是否hash模式
 * @returns 参数值
 */
export const getQueryString = (name, hash) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  let r;

  if (hash) {
    r = window.location.hash.split('?')[1] && window.location.hash.split('?')[1].match(reg);
  } else {
    r = window.location.search.substr(1).match(reg);
  }
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return '';
};
/**
 * 退出登录
 * test/dev: https://staff.medbanks-test.com/login
 * pro : https://staff.medbanks.cn/login
 */
export const loginPM = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('SP_TOKEN');
  sessionStorage.removeItem('SP_TOKEN');
  localStorage.removeItem(Configs.TOKEN_KEY);
  const prefix = Configs.isProd ? '/checkToken' : '';
  window.location.href = `${prefix}/login`;
};

/** 登录态校验 */
export const checkLoginStatus = () => {
  const urlToken = getQueryString('token');
  if (urlToken) {
    sessionStorage.setItem('SP_TOKEN', urlToken);
    localStorage.setItem(Configs.TOKEN_KEY, urlToken);
  }
  const token = urlToken || sessionStorage.getItem('SP_TOKEN') || localStorage.getItem(Configs.TOKEN_KEY);
  if (!token) {
    loginPM();
  }
};
