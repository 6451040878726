/*
 * @Author: siyue.huang
 * @Email: siyue.huang@medbanks.cn
 * @Date: 2021-01-21 12:08:38
 * @Last Modified by: leslieHoward
 * @Last Modified time: 2021-07-26 14:29:16
 * @Description: 组件级权限控制
 */

<template>
    <div v-fragment>
        <slot v-if="isVisible" />
    </div>
</template>

<script>
import _ from 'lodash';
import { isNull } from '@/utils';
import { translateRoleCategory } from '@/utils/enums';
import store from '@/store';

export default {
    name: 'AuthWrapper',
    props: {
        code: {
            type: String,
            default: '',
        },
        roleCategories: {
            type: String|Array,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        isVisible() {
            const isCodeNull = isNull(this.code);
            const isRoleCategoriesNull = _.isEmpty(this.roleCategories);

            if (isCodeNull && isRoleCategoriesNull) {
                return true;
            }

            if (isCodeNull) {
                // 翻译当前登录人的角色分类
                const roleCategory = translateRoleCategory(localStorage.getItem('identity'));

                return _.includes(this.roleCategories, roleCategory.label);
            }

            if (isRoleCategoriesNull) {
                return _.includes(store.getters.permissionCode, this.code);
            }

            return true;
        },
    },
    methods: {},
};
</script>

