<!--
 * @Author: xuchuanwei
 * @Date: 2022-03-18 17:36:18
 * @LastEditors: xuchuanwei
 * @LastEditTime: 2022-03-29 18:19:00
 * @Description: 
-->
<template>
  <div class="face-verify-wrap" v-show="supervisionSwitch.switchVideoAuthenticationFlag && visible">
    <div class="musk"></div> 
    <div class="verify-content">
      <div class="header">身份认证</div>
      <FaceVerifyCmp @verifySuccess="verifySuccess" />
    </div> 
  </div>
</template>
<script> 
import config from '@/config'
import { mapGetters } from 'vuex'
import FaceVerifyCmp from './faceVerifyCmp'

const verifyTokenKey = 'VERIFY_TOKEN'
const staffTokenKey = 'STAFF_TOKEN'
export default {
  name: 'FaceVerify',
  
  components: { FaceVerifyCmp },

  data() {
    return {
      visible: false, 
    }
  },

  computed: {
    ...mapGetters('supervision', ['supervisionSwitch']),
  },

  methods: { 
    verifySuccess() {
      let token = localStorage.getItem(staffTokenKey)
      localStorage.setItem(verifyTokenKey, token)
      this.visible = false;
      this.$router.push('/workbench') 
    }
  },
  mounted() {  
    // 员工平台重新登录之后，派随坊的token不会变。记录员工平台的token，用于区分是否进行过人脸识别
    // 存储员工平台token的代码, 在/src/router/index.js中
    let verifyToken = localStorage.getItem(verifyTokenKey)
    let token = localStorage.getItem(staffTokenKey)
    if (!verifyToken || token != verifyToken) { 
      this.visible = true;
    }
  },
};
</script> 

<style lang="scss" scoped>
.face-verify-wrap {
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  background: #f2f2f2;
  width: 100vw;
  height: 100vh;

  .musk {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .verify-content {
    position: relative;
    z-index: 2;
    width: 375px;
    height: 667px;
    margin: 0 auto;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 44px;

    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #F9FAF9;
    }
  }
  /deep/ .el-dialog__header {
    text-align: center;
    background: #F9FAF9;
    padding-top: 10px;
  }
  /deep/ .face-customer-class {
    margin-top: 0!important;
    top: 50%;
    transform: translateY(-50%);
  } 
}

</style>