<template>
  <el-select
    :key="labelField"
    v-bind="computedProps"
    :remote-method="debounceSearch"
    :loading="isSearching"
    style="width: 100%;"
    @change="onChange"
    @visible-change="onVisibleChange"
  >
    <el-option
      v-for="item in computedOptions"
      :key="item[optionKey]"
      :label="labelFormat(item)"
      :value="valueField === 'WHOLE' ? item : item[valueField]"
      :class="optionClassName"
    >
      <slot :option="item" />
    </el-option>
  </el-select>
</template>

<script>
import _ from 'lodash';
import { isNull } from '@/utils';

function isObjectArrayType(value) {
  return _.every(value, (item) => _.isPlainObject(item));
}

export default {
  name: 'SearchSelector',
  inheritAttrs: false,
  props: {
    // value: {
    //     type: [Array, Object, String, Number],
    //     default: _.noop(),
    // },
    options: {
      type: Array,
      default: () => [],
    },
    request: {
      type: Function,
      default: _.noop(),
    },
    labelField: {
      type: String,
      default: 'label',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    optionClassName: {
      type: [String, Array],
      default: '',
    },

    labelFormatFn: {
      type: Function,
    },
  },
  data() {
    this.debounceSearch = _.debounce(this.search, 200);

    return {
      defaultProps: {
        placeholder: '请输入关键词',
        multiple: true,
        remote: true,
        filterable: true,
        size: 'mini',
        'reserve-keyword': true,
        'value-key': 'value',
      },
      optionKey: '',
      // 是否搜索中
      isSearching: false,
      // 搜索值
      query: '',
      // 筛选后的选项
      filters: [],
    };
  },
  computed: {
    computedProps() {
      return { ...this.defaultProps, ...this.$attrs };
    },
    computedOptions() {
      const options = isNull(this.query) ? this.options : this.filters;
      if (_.isEmpty(options) && isObjectArrayType(this.$attrs.value)) {
        return this.$attrs.value;
      }
      return options || [];
    },
  },
  mounted() {
    this.optionKey = this.valueField === 'WHOLE' ? this.$attrs['value-key'] : this.valueField;
  },
  methods: {
    labelFormat(value) {
      if (this.labelFormatFn) {
        return this.labelFormatFn(value);
      }
      return value[this.labelField];
    },

    // 字符串匹配
    stringMatch(source, target) {
      return _.includes(_.toLower(source), _.toLower(target));
    },
    // 搜索
    async search(query) {
      this.isSearching = true;
      this.query = query;

      if (query !== '') {
        if (typeof this.request === 'function') {
          this.filters = await this.request(query);
        } else {
          this.filters = _.filter(this.options, (item) => this.stringMatch(_.get(item, [this.labelField], ''), query));
        }
      } else {
        this.filters = [];
      }

      this.isSearching = false;
    },
    onChange(value) {
      try {
        const _key = this.valueField === 'WHOLE' ? this.$attrs['value-key'] : this.valueField;
        const option =
          this.computedOptions?.filter?.((it) => {
            if (Array.isArray(value)) {
              return value?.includes?.(it[_key]);
            } else if (typeof value === 'object') {
              return value?.[_key] === it[_key];
            } else {
              return value === it[_key];
            }
          }) ||
          (Array.isArray(value) && value?.includes?.(it[_key])) ||
          [];

        // console.log('onChange=> ', value, option, _key, this.computedOptions);
        this.$emit('onChange', value, option);
      } catch (error) {
        console.error(error);
      }
    },
    onVisibleChange(visible) {
      // 选项面板不显示的时候重置搜索内容
      if (!visible) {
        this.query = '';
      }
      this.$emit('visible-change', visible);
    },
  },
};
</script>
