/* eslint-disable no-restricted-syntax */
import nanoid from 'nanoid';

export const onInput = (e, b, key) => {
    let val = e.replace(/[^0-9.]/g, '').trim();
    const index = val.indexOf('.');
    if (index !== -1) {
        val = `${val.substr(0, index)}.${val.substr(index).replace(/\./g, '')}`;
    }
    const container = this.$refs[b];
    if (container) {
        container.formParams[key] = val;
    }
};
export const onInput2 = (e, b, key) => {
    let val = e.replace(/[^0-9.]/g, '').trim();
    const index = val.indexOf('.');
    if (index !== -1) {
        val = `${val.substr(0, index)}.${val.substr(index).replace(/\./g, '')}`;
    }
    const container = this.$refs[b];
    if (container) {
        container.formParams[key] = val;
    }
};
/**
 * 处理文本、日期显示
 * @param {string} val 文本、日期
 */
export function formatterTextValue(val) {
    if (!val) return null;
    const reDateTime = /^(?:19|20|21)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
    const isDateTime = reDateTime.test(val);
    if (!isDateTime) {
        // if (val.replace(/^(.*[n])*.*(.|n)$/g, "$2")==='/') {
        //     return val.split('/')[0]
        // }
        return val;
    }
    const date = new Date(val.replace(/-/g, '/'));
    const y = date.getFullYear();
    const m = date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1; // 月份
    const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(); // 日
    return `${y}-${m}-${d}`;
}
/**
 * 平铺对象
 * @param {object} data 需要展开的对象
 * @param {array} list 接受的空数组
 */
export function tileChildren(data, list, keys) {
    if (!data) {
        return false;
    }
    Object.getOwnPropertyNames(data).forEach((key) => {
        const str = keys ? `${keys}.${key}` : key;
        if (data[key].constructor === Object) {
            tileChildren(data[key], list, str);
        } else {
            list.push({
                name: key,
                value: data[key],
                topName: str,
            });
        }
    });
    return list;
}

/**
 * 深层去除对象中所有符合为空条件的对象
 * @param {Object} obj 来源对象
 * @param {Function} fn 函数验证每个字段
 */
export function compactObj(obj, fn) {
    // eslint-disable-next-line guard-for-in
    for (const i in obj) {
        if (typeof obj[i] === 'object') {
            compactObj(obj[i], fn);
        }
        if (fn(obj[i])) {
            delete obj[i];
        }
    }
}

/**
 * @description 删除空对象 删除'', null, undefined
 * @param {*} foo 对象
 * @return {boolean} true/false
 */
export function isEmpty(foo) {
    if (typeof foo === 'object') {
        // eslint-disable-next-line guard-for-in
        for (const i in foo) {
            return false;
        }
        return true;
    }
    return foo === '' || foo === null || foo === undefined;
}


// 健康档案-个人一般史数据处理
export const enCodeDatas = (data) => {
    const {
        marry, allergy, danger, disable, drink, inoculation, life, smoking,
    } = data;
    const {
        menstruation,
        menstruationAge,
        menstruationEndDate,
        minMenstrualCycle,
        maxMenstrualCycle,
        minDuration,
        maxDuration,
        menstruationStatus,
        menstruationAche,
        menstruationVolume,
        pregnancy,
        birth,
        pregnantNumber,
        volumeMark,
        marryInfo,
    } = marry;
    const marryList = [];
    marryInfo && marryInfo.forEach((it) => {
        const { birthDate, birthWay } = it;
        const { code, value, other } = birthWay;
        const item = {
            parturitionDate: birthDate,
            parturitionWayDesc: other,
            parturitionWayLabel: value,
            parturitionWayValue: code,
        };
        marryList.push(item);
    });

    const {
        allergyStatus,
        allergyName,
        allergyOther,
        allergyMark,
    } = allergy;
    const otherMatterOptions = [];
    const drugInfoOptions = [];
    let otherMatterDesc = '';
    let drugInfoDesc = '';
    allergyName && allergyName.forEach((it) => {
        if (it.other) {
            drugInfoDesc = it.other;
        }
        drugInfoOptions.push({
            label: it.value,
            value: it.commonEncode,
        });
    });
    allergyOther && allergyOther.forEach((it) => {
        if (it.other) {
            otherMatterDesc = it.other;
        }
        otherMatterOptions.push({
            label: it.value,
            value: it.code,
        });
    });

    const dangerResult = [];
    danger && danger.forEach((item) => {
        const {
            dangerous,
            dangerousRate,
            dangerousPro,
            dangerMark,
        } = item;
        const dangerItem = {
            remark: dangerMark,
            monthFrequency: dangerousRate, // 每月频次
            safeMeasureLabel: dangerousPro.value, // 防护措施
            dangerTouchLabel: dangerous.value, // 危险因素接触物
            dangerTouchDesc: dangerous.other, // 危险因素接触物其他描述
            dangerTouchValue: dangerous.code, // 危险因素接触物
            safeMeasureDesc: dangerousPro.other, // 防护措施其他描述
            safeMeasureValue: dangerousPro.code, // 防护措施
        };
        dangerResult.push(dangerItem);
    });

    let disableDesc = '';
    const disableOptions = [];
    disable && disable.forEach((it) => {
        if (it.other) {
            disableDesc = it.other;
        }
        disableOptions.push({
            label: it.value,
            value: it.code,
        });
    });

    const {
        exerciseWays,
        exerciseStrenth,
        lifestyleExerciseFrequency,
        exerciseDays,
        exerciseTime,
        sleepTime,
        lifestyleSleepPatterns,
        lifestyleEatingHabits,
    } = life;
    let exerciseWaysDesc = '';
    const exerciseWayOptions = [];
    exerciseWays && exerciseWays.forEach((it) => {
        if (it.other) {
            exerciseWaysDesc = it.other;
        }
        exerciseWayOptions.push({
            label: it.value,
            value: it.code,
        });
    });
    let sleepStateDesc = '';
    const sleepStateOptions = [];
    lifestyleSleepPatterns && lifestyleSleepPatterns.forEach((it) => {
        if (it.other) {
            sleepStateDesc = it.other;
        }
        sleepStateOptions.push({
            label: it.value,
            value: it.code,
        });
    });
    let dietaryHabitDesc = '';
    const dietaryHabitOptions = [];
    lifestyleEatingHabits && lifestyleEatingHabits.forEach((it) => {
        if (it.other) {
            dietaryHabitDesc = it.other;
        }
        dietaryHabitOptions.push({
            label: it.value,
            value: it.code,
        });
    });

    // 饮酒
    const {
        drinkStatus,
        drinkInfo,
        startDrinkAge,
        endDrinkAge,
        drunkTimes,
    } = drink;
    const drinkRet = [];
    drinkInfo && drinkInfo.forEach((it) => {
        const {
            drinkType,
            drinkStrenth,
            drinkRate1,
            drinkCompany,
        } = it;
        drinkRet.push({
            wineTypeDesc: drinkType.other,
            wineTypeLabel: drinkType.value,
            wineTypeValue: drinkType.code,
            perVolume: drinkStrenth,
            frequency: drinkRate1,
            frequencyUnitDesc: drinkCompany.other,
            frequencyUnitLabel: drinkCompany.value,
            frequencyUnitValue: drinkCompany.code,
        });
    });

    // 预防接种史
    const inoculationResult = [];
    inoculation && inoculation.forEach((it) => {
        const {
            inoculationDate,
            inoculationName,
        } = it;
        inoculationResult.push({
            inoculateDate: inoculationDate,
            inoculateVeroInfoDesc: inoculationName.other,
            inoculateVeroInfoLabel: inoculationName.value,
            inoculateVeroInfoValue: inoculationName.code,
        });
    });

    const params = {
        menstruationStateDesc: menstruation ? menstruation.other : '', // 婚育月经史-月经情况其他描述
        menstruationStateLabel: menstruation ? menstruation.value : '', // 婚育月经史-月经情况
        menstruationStateValue: menstruation ? menstruation.code : '', // 婚育月经史-月经情况枚举值
        menstruationAge, // 婚育月经史-初潮年龄（岁）
        menstruationLastDate: menstruationEndDate, // 婚育月经史-末次月经时间
        menstruationMinCycle: minMenstrualCycle, // 婚育月经史-最短月经周期（天）
        menstruationMaxCycle: maxMenstrualCycle, // 婚育月经史-最长月经周期（天）
        minDuration, // 婚育月经史-最短持续时间（天）
        maxDuration, // 婚育月经史-最长持续时间（天）
        menstruationCycleRegularityDesc: menstruationStatus ? menstruationStatus.other : '', // 婚育月经史-周期是否规则其他描述
        menstruationCycleRegularityLabel: menstruationStatus ? menstruationStatus.value : '', // 婚育月经史-周期是否规则
        menstruationCycleRegularityValue: menstruationStatus ? menstruationStatus.code : '', // 婚育月经史-周期是否规则枚举值
        menstruationColicDesc: menstruationAche ? menstruationAche.other : '', // 婚育月经史-有无痛经其他描述
        menstruationColicLabel: menstruationAche ? menstruationAche.value : '', // 婚育月经史-有无痛经
        menstruationColicValue: menstruationAche ? menstruationAche.code : '', // 婚育月经史-有无痛经枚举值
        menstruationVolumeDesc: menstruationVolume ? menstruationVolume.other : '', // 婚育月经史-月经量其他描述
        menstruationVolumeLabel: menstruationVolume ? menstruationVolume.value : '', // 婚育月经史-月经量
        menstruationVolumeValue: menstruationVolume ? menstruationVolume.code : '', // 婚育月经史-月经量枚举值
        pregnancyDesc: pregnancy ? pregnancy.other : '', // 婚育月经史-妊娠期其他描述
        pregnancyLabel: pregnancy ? pregnancy.value : '', // 婚育月经史-妊娠期
        pregnancyValue: pregnancy ? pregnancy.code : '', // 婚育月经史-妊娠期枚举值
        birthStateDesc: birth ? birth.other : '', // 婚育月经史-生育情况其他描述
        birthStateLabel: birth ? birth.value : '', // 婚育月经史-生育情况
        birthStateValue: birth ? birth.code : '', // 婚育月经史-生育情况枚举值
        pregnancyTimes: pregnantNumber, // 婚育月经史-孕次数（次）
        parturitionInfoList: marryList, // 婚育月经史-分娩信息list
        marryMenstruationRemark: volumeMark, // 婚育月经史-婚育月经史备注
        // marriageStateDesc: '', // 婚姻状况其他描述
        // marriageStateLabel: '', // 婚姻状况
        // marriageStateValue: '', // 婚姻状况枚举值
        // allergyDrugType: {}, // 过敏史-过敏药品类别 ------------------------
        allergyRemark: allergyMark, // 过敏史-过敏史备注
        allergyStateDesc: allergyStatus ? allergyStatus.other : '', // 过敏史-有无过敏史其他描述
        allergyStateLabel: allergyStatus ? allergyStatus.value : '', // 过敏史-有无过敏史
        allergyStateValue: allergyStatus ? allergyStatus.code : '', // 过敏史-有无过敏史枚举值
        drugInfo: {
            desc: drugInfoDesc,
            options: drugInfoOptions,
        }, // 过敏史-药品通用名
        otherMatter: {
            desc: otherMatterDesc,
            options: otherMatterOptions,
        }, // 过敏史-其他物质
        dangerTouchList: dangerResult, // 危险因素 ----------------------------------
        disabilityStateInfo: {
            desc: disableDesc,
            options: disableOptions,
        }, // 危险因素接触史-残疾情况
        exerciseWays: {
            desc: exerciseWaysDesc,
            options: exerciseWayOptions,
        }, // 生活方式-锻炼方式 -------------------------------
        exerciseStrengthDesc: exerciseStrenth ? exerciseStrenth.other : '', // 生活方式-锻炼强度其他描述
        exerciseStrengthLabel: exerciseStrenth ? exerciseStrenth.value : '', // 生活方式-锻炼强度
        exerciseStrengthValue: exerciseStrenth ? exerciseStrenth.code : '', // 生活方式-锻炼强度枚举值
        exerciseFrequencyDesc: lifestyleExerciseFrequency ? lifestyleExerciseFrequency.other : '', // 生活方式-锻炼频率其他描述
        exerciseFrequencyLabel: lifestyleExerciseFrequency ? lifestyleExerciseFrequency.value : '', // 生活方式-锻炼频率
        exerciseFrequencyValue: lifestyleExerciseFrequency ? lifestyleExerciseFrequency.code : '', // 生活方式-锻炼频率枚举值
        exerciseDurationYear: exerciseDays, // 生活方式-坚持锻炼时间（年）
        exercisePerDurationMinute: exerciseTime, // 生活方式-每次锻炼时间（分钟）
        sleepDuration: sleepTime, // 生活方式-睡眠时长（小时/天）
        sleepState: {
            desc: sleepStateDesc,
            options: sleepStateOptions,
        }, // 生活方式-睡眠状况
        dietaryHabit: {
            desc: dietaryHabitDesc,
            options: dietaryHabitOptions,
        }, // 生活方式-饮食习惯
        smokeDailyNumber: smoking ? smoking.smokeNumber : '', // 吸烟史-日吸烟量（支）-------------------------
        smokeEndAge: smoking ? smoking.endSmokeAge : '', // 吸烟史-戒烟年龄（岁）
        smokeStartAge: smoking ? smoking.startSmokeAge : '', // 吸烟史-开始吸烟年龄（岁）
        smokeStateDesc: (smoking && smoking.smoke) ? smoking.smoke.other : '', // 吸烟史-吸烟状况其他描述
        smokeStateLabel: (smoking && smoking.smoke) ? smoking.smoke.value : '', // 吸烟史-吸烟状况
        smokeStateValue: (smoking && smoking.smoke) ? smoking.smoke.code : '', // 吸烟史-吸烟状况枚举
        drinkStateDesc: drinkStatus ? drinkStatus.other : '', // 饮酒史-饮酒状况其他描述 ----------------------------
        drinkStateLabel: drinkStatus ? drinkStatus.value : '', // 饮酒史-饮酒状况
        drinkStateValue: drinkStatus ? drinkStatus.code : '', // 饮酒史-饮酒状况枚举
        drinkInfoList: drinkRet, // 饮酒史-饮酒信息
        drinkStartAge: startDrinkAge, // 饮酒史-开始饮酒年龄（岁）
        drinkEndAge: endDrinkAge, // 饮酒史-戒酒年龄（岁）
        drunkTimes, // 饮酒史-近一年内醉酒次数（次）
        inoculateInfoList: inoculationResult, // 预防接种史 ----------------------
    };

    return params;
};

export const deCodeDatas = (data) => {
    const {
        menstruationStateDesc, // 婚育月经史-月经情况其他描述
        menstruationStateLabel, // 婚育月经史-月经情况
        menstruationStateValue, // 婚育月经史-月经情况枚举值
        menstruationAge, // 婚育月经史-初潮年龄（岁）
        menstruationLastDate, // 婚育月经史-末次月经时间
        menstruationMinCycle, // 婚育月经史-最短月经周期（天）
        menstruationMaxCycle, // 婚育月经史-最长月经周期（天）
        minDuration, // 婚育月经史-最短持续时间（天）
        maxDuration, // 婚育月经史-最长持续时间（天）
        menstruationCycleRegularityDesc, // 婚育月经史-周期是否规则其他描述
        menstruationCycleRegularityLabel, // 婚育月经史-周期是否规则
        menstruationCycleRegularityValue, // 婚育月经史-周期是否规则枚举值
        menstruationColicDesc, // 婚育月经史-有无痛经其他描述
        menstruationColicLabel, // 婚育月经史-有无痛经
        menstruationColicValue, // 婚育月经史-有无痛经枚举值
        menstruationVolumeDesc, // 婚育月经史-月经量其他描述
        menstruationVolumeLabel, // 婚育月经史-月经量
        menstruationVolumeValue, // 婚育月经史-月经量枚举值
        pregnancyDesc, // 婚育月经史-妊娠期其他描述
        pregnancyLabel, // 婚育月经史-妊娠期
        pregnancyValue, // 婚育月经史-妊娠期枚举值
        birthStateDesc, // 婚育月经史-生育情况其他描述
        birthStateLabel, // 婚育月经史-生育情况
        birthStateValue, // 婚育月经史-生育情况枚举值
        pregnancyTimes, // 婚育月经史-孕次数（次）
        parturitionInfoList, // 婚育月经史-分娩信息list
        marryMenstruationRemark, // 婚育月经史-婚育月经史备注
    } = data;

    const marryInfo = [];
    parturitionInfoList && parturitionInfoList.forEach((it) => {
        const {
            parturitionDate,
            parturitionWayLabel,
            parturitionWayValue,
            parturitionWayDesc,
        } = it;
        marryInfo.push({
            key: nanoid(),
            birthDate: parturitionDate,
            birthWay: {
                label: parturitionWayLabel,
                value: parturitionWayLabel,
                code: parturitionWayValue,
                text: parturitionWayLabel,
                other: parturitionWayDesc,
            },
        });
    });
    return {
        marry: {
            menstruation: {
                label: menstruationStateLabel,
                value: menstruationStateLabel,
                code: menstruationStateValue,
                text: menstruationStateLabel,
                other: menstruationStateDesc,
            },
            marryInfo,
        },
        danger: {},
        allergy: {},
        drink: {},
        inoculation: {},
        life: {},
        smoking: {},
        disableInfo: {},
    };
};
