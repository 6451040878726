/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-10-28 13:57:23
 * @Last Modified by: chenhq
 * @Last Modified time: 2022-02-14 10:54:34
 * @Description: 递归菜单
 * @Route: Route
 */

<template>
    <div v-if="menuData && menuData.length">
        <template v-for="(item, i) in menuData">
            <el-submenu
                v-if="item.children"
                :key="item.path || i"
                :index="item.path || item.name">
                <template slot="title">
                    <img v-if="item.icon"
                         class="icon_image"
                         :src="require(`../../assets/images/${item.icon}`)">
                    <span>{{ item.name }}</span>
                </template>
                <sidebar-menu
                    :menu-data="item.children"
                    :default-active="defaultActive"
                    class="med-submenu" />
            </el-submenu>
            <el-menu-item
                v-else
                :key="item.path"
                :index="item.path"
                :class="[defaultActive === item.path && 'menu-active-item', 'list-item']">
                <!--path跳转-->
                <img v-if="item.icon"
                     class="icon_image"
                     :src="require(`../../assets/images/${item.icon}`)">
                <span>{{ item.name }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SidebarMenu',
    props: {
        menuData: {
            type: Array,
            default: () => ([]),
        },
        defaultActive: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-submenu__title:hover,
.el-menu-item:hover,
.el-menu-item.is-active {
    // background-color: #002140 !important;
}
/deep/ .el-submenu__title {
    display: flex;
    align-items: center;
}
/deep/ .is-active {
    background-color: #1b65b9 !important;
}
.icon_image {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.list-item {
    display: flex;
    align-items: center;
}
</style>
