<!--
 * @Author: xuchuanwei
 * @Date: 2022-03-18 17:36:18
 * @LastEditors: xuchuanwei
 * @LastEditTime: 2022-03-30 11:04:38
 * @Description: 
-->
<template> 
<div class="face-verify">
  <!-- 认证前身份信息确认 -->
  <div class="identify-info" v-if="verifyStatus == verifyStatusEnums.notStarted">
    <div class="form-item">
      <div class="name">姓名</div>
      <div class="value">{{accountInfo.realName}}</div>
    </div>
    
    <div class="form-item">
      <div class="name">身份证号码</div>
      <div class="value" v-if="idCardNoReadonly">
        {{secretCard(idCardNo)}}
      </div>
      <div class="value" v-else>
        <el-input @blur="validateIdCard" v-model="idCardNo" placeholder="请输入身份证号码"></el-input>
      </div>
    </div> 

    <div class="form-item btn-wrap">
      <el-button 
        class="verify-btn"
        round 
        @click="confirmVerify">
        信息确认无误，进入人脸识别
      </el-button> 
    </div>  
  </div>

  <!-- 认证中 -->
  <div class="video-wrapper" v-else-if="verifyStatus == verifyStatusEnums.verifying">
    <!-- 视频遮罩层 -->
    <div class="wrapper"> 
      <!-- <h2 class="tip verifying">人脸识别中 {{seconds}}秒</h2> -->
      <img class="face-img" :src="require('@/assets/images/face.png')" />
      <h2 class="tip">请对准镜头摇一摇头</h2>
      <p class="text">请在光线充足的情况下进行人脸识别</p>
    </div>
    <!-- video用于显示媒体设备的视频流，自动播放 -->
    <video ref="video" id="video" autoplay></video> 
  </div>  

  <!-- 认证成功 -->
  <div class="verify-success" v-else>
    <i class="el-icon-success"></i>
    <p class="text">已通过</p>
  </div>
</div> 
</template>
<script>
import { safeParse } from '@/utils';  
export default {
  name: 'faceVerifyCmp',
  data() {
    const userInfo = safeParse(localStorage.getItem('userInfo'), {}); 
    const accountInfo = userInfo.accountInfo || {}
    const verifyStatusEnums = {
      notStarted: 1,
      verifying: 2,
      success: 3,
    }
    return {
      streamIns: null, 
      seconds: 0,
      timer: null,
      verifyVisible: false,
      accountInfo,
      idCardNo: accountInfo.cardType == 1 ? accountInfo.cardNo : '',
      verifyStatus: verifyStatusEnums.notStarted,
      verifyStatusEnums,
      messageInstance: null,
    }
  },

  computed: {
    idCardNoReadonly() {
      const {cardType, cardNo} = this.accountInfo 
      let idCardNo = cardType == 1 ? cardNo : '';
      return !!idCardNo;
    }
  }, 
  
  methods: {
    // 身份证脱敏
    secretCard(idCardNo) { 
      let replaceStr = '$1***********$2' 
      // 15为身份证脱敏
      if (idCardNo.length < 18) {
        replaceStr = '$1********$2'
      }
      return idCardNo.replace(/(\w{4})\w*(\w{3})/, replaceStr)
    },
    
    validateIdCard() { 
      if (!this.isIdCard(this.idCardNo)) {
        this.messageInstance && this.messageInstance.close();
        this.messageInstance = this.$message.warning('请输入正确的身份证号!')
        return false;
      }
      return true;
    },

    isIdCard(value) {
      return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value);
    },
    // 关闭摄像
    closeCamera() {
      try {
        this.streamIns.enabled = false;
        this.streamIns.getTracks()[0].stop();
        this.streamIns.getVideoTracks()[0].stop();
      } catch (e) {
        throw new Error(e);
      }
    }, 

    // 访问用户媒体设备的兼容方法
    getUserMediaFn() {
      return navigator.mediaDevices.getUserMedia 
        || navigator.webkitGetUserMedia 
        || navagator.mozGetUserMedia 
        || navigator.getUserMedia
    },

    navigatorGetUserMedia(constrains) {
      if (navigator.mediaDevices.getUserMedia) {
        //最新标准API
        return navigator.mediaDevices.getUserMedia(constrains)
      } else if (navigator.webkitGetUserMedia) {
        //webkit内核浏览器
        return navigator.webkitGetUserMedia(constrains)
      } else if (navigator.mozGetUserMedia) {
        //Firefox浏览器
        return navagator.mozGetUserMedia(constrains)
      } else if (navigator.getUserMedia) {
        //旧版API
        return navigator.getUserMedia(constrains)
      }
    },

    getUserMediaSuccess(stream) {
      this.streamIns = stream;
      const video = this.$refs['video']
      //兼容老版本webkit内核浏览器 
      try {
        const CompatibleURL = window.URL || window.webkitURL; 
        const streamUrl = CompatibleURL.createObjectURL(stream);
        video.src = streamUrl 
      } catch (e) {
        video.srcObject = stream;  
      }
      // 播放视频
      video.play();
      this.faceVerify();
    },

    getUserMediaError(error) { 
      this.$message.error('访问用户媒体设备失败：' + error.message)
    },

    // 人脸识别中
    faceVerify() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.seconds = 3;
      this.timer = setInterval(() => {
        if (this.seconds > 1) {
          this.seconds = this.seconds - 1;
        } else {
          this.faceVerifySuccess();
        }
      }, 1000)
    },

    // 人脸识别成功回调
    faceVerifySuccess() { 
      clearInterval(this.timer);
      this.closeCamera();
      this.verifyStatus = this.verifyStatusEnums.success;
      setTimeout(() => {
        this.$emit('verifySuccess');
      }, 1000)
    },

    // 身份信息确认
    confirmVerify() {
      if (!this.validateIdCard()) return;
      
      const getUserMedia = this.getUserMediaFn()  
      if(!getUserMedia) { 
        this.$message.error('你的浏览器不支持访问用户媒体设备'); 
        return;
      } 
      
      this.verifyStatus = this.verifyStatusEnums.verifying;
      let params = {
        video: {
          width: 1920,
          height: 1080,
          facingMode: 'user', // 前置优先
        },
      }
      this.navigatorGetUserMedia(params).then(this.getUserMediaSuccess).catch(this.getUserMediaError)
    }
  }, 

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
};
</script>
<style lang="scss" scoped>
.face-verify {
  height: 640px; 

  .video-wrapper {
    position: relative;
    height: 100%; 
    padding-top: 60px;
    
    .wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1000;
      left: 0;
      top: 0;

      .verifying {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .face-img {
        width: 280px;
        height: 280px;
        margin: 40px auto 0;
        display: block;
      }

      .tip {
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
      }
      .text {
        text-align: center;
        color: #999;
      }
    }

    #video {
      position: relative;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      // width: 100%;
      // height: 100%x
      object-fit: cover;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 0 auto 0;
      display: block;
    }  
  }

  .identify-info {
    padding: 0 18px;
    height: 100%;

    .form-item {
      margin-bottom: 10px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;

      &.btn-wrap {
        margin-top: 24px;
        border-bottom: none; 
        /deep/ .verify-btn {
          color: #fff;
          width: 100%; 
          background: #F97844;
          height: 44px;
          border-radius: 22px;

          &:active {
            border-color: #F97844;
          }
        }
      }

      .name {
        flex-shrink: 0;
        width: 70px;
        margin-right: 10px;
      }

      .value {
        flex-grow: 1;
        font-size: 16px;
        text-align: right;
        
        /deep/ .el-input__inner {
          border: none;
          padding: 0;
          text-align: right;
          font-size: 16px;
        }
      }
    }
  }

  .verify-success {
    padding-top: 40px;
    text-align: center;
    
    .el-icon-success {
      color: #20C0B4;
      font-size: 84px;
    }

    .text {
      margin-top: 10px;
      color: #20C0B4;
      font-size: 16px;
    }
  }
}

</style>