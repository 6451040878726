import Vue from 'vue';
import { Subject, ReplaySubject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import _ from 'lodash';
export function factory(params) {
    // const lazySubject = new ReplaySubject(params);
    // const subject = new Subject();
    // const merged = merge(subject, lazySubject);
    // return { lazySubject, subject, merged };
    return new ReplaySubject(params);
}
export function resettable(factory) {
    const resetter = new Subject();
    const source = new Subject();
    let destination = factory();
    let subscription = source.subscribe(destination);
    return {
        observable: resetter.asObservable().pipe(
            startWith(null),
            switchMap(() => destination),
        ),
        reset: () => {
            subscription.unsubscribe();
            destination = factory();
            subscription = source.subscribe(destination);
            resetter.next();
        },
        subject: source,
    };
}
class Observer {
    constructor() {
        this.observers = {};
    }
    createObserver = (key) => {
        if (!this.observers[key]) {
            this.observers[key] = resettable(() => factory(1));
        }
        return this.observers[key];
    };
    on = (key, subscriber) => {
        this.createObserver(key);
        return this.observers[key].observable.subscribe(subscriber);
    };
    off = (key) => {
        delete this.observers[key];
    };
    next = (key, subscription) => {
        this.createObserver(key);
        this.observers[key].subject.next(subscription);
    };
    reset = (key) => {
        this.observers[key].reset();
    };
    get = (key) => this.observers[key];
    at = (keys) => _.at(this.observers, keys);
}
const $observer = new Observer();
Vue.mixin({
    data() {
        return {
            SUBSCRIPTIONS: new Subscription(),
        };
    },
    beforeDestroy() {
        this.$unsubscribe();
    },
    methods: {
        $subscribe(key, subscriber) {
            if (key) {
                this.SUBSCRIPTIONS.add(
                    $observer.on(key, (subscription) => {
                        subscriber(subscription);
                        this.$nextTick(() => {
                            $observer.reset(key);
                        });
                    }),
                );
            }
        },
        $next(key, subscription) {
            $observer.next(key, subscription);
        },
        $unsubscribe() {
            this.SUBSCRIPTIONS.unsubscribe();
        },
    },
});
export default $observer;