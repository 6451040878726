const optionsMap = {
    sexOptions: [
        { label: '男', value: '1' },
        { label: '女', value: '2' },
        { label: '未知', value: '3' },
        { label: '其他', value: '9' },
    ],
    nationOptions: [
        { label: '汉族', value: '汉族' },
        { label: '蒙古族', value: '蒙古族' },
        { label: '回族', value: '回族' },
        { label: '藏族', value: '藏族' },
        { label: '维吾尔族', value: '维吾尔族' },
        { label: '苗族', value: '苗族' },
        { label: '彝族', value: '彝族' },
        { label: '壮族', value: '壮族' },
        { label: '布依族', value: '布依族' },
        { label: '朝鲜族', value: '朝鲜族' },
        { label: '满 族', value: '满 族' },
        { label: '侗族', value: '侗族' },
        { label: '瑶族', value: '瑶族' },
        { label: '白族', value: '白族' },
        { label: '土家族', value: '土家族' },
        { label: '哈尼族', value: '哈尼族' },
        { label: '哈萨克族', value: '哈萨克族' },
        { label: '傣族', value: '傣族' },
        { label: '黎族', value: '黎族' },
        { label: '傈僳族', value: '傈僳族' },
        { label: '佤族 ', value: '佤族 ' },
        { label: '畲族', value: '畲族' },
        { label: '高山族', value: '高山族' },
        { label: '拉祜族', value: '拉祜族' },
        { label: '水族', value: '水族' },
        { label: '东乡族', value: '东乡族' },
        { label: '纳西族', value: '纳西族' },
        { label: '景颇族', value: '景颇族' },
        { label: '柯尔克孜族', value: '柯尔克孜族' },
        { label: '土族', value: '土族' },
        { label: '达斡尔族', value: '达斡尔族' },
        { label: '仫佬族', value: '仫佬族' },
        { label: '羌族', value: '羌族' },
        { label: '布朗族', value: '布朗族' },
        { label: '撒拉族', value: '撒拉族' },
        { label: '毛南族', value: '毛南族' },
        { label: '仡佬族', value: '仡佬族' },
        { label: '锡伯族', value: '锡伯族' },
        { label: '阿昌族', value: '阿昌族' },
        { label: '普米族', value: '普米族' },
        { label: '塔吉克 族', value: '塔吉克 族' },
        { label: '怒族', value: '怒族' },
        { label: '乌孜别克族', value: '乌孜别克族' },
        { label: '俄罗斯族', value: '俄罗斯族' },
        { label: '鄂温克族', value: '鄂温克族' },
        { label: '德昂族', value: '德昂族' },
        { label: '保安族', value: '保安族' },
        { label: '裕固族', value: '裕固族' },
        { label: '京族', value: '京族' },
        { label: '塔塔尔族', value: '塔塔尔族' },
        { label: '独龙族', value: '独龙族' },
        { label: '鄂伦春族', value: '鄂伦春族' },
        { label: '赫哲族', value: '赫哲族' },
        { label: '门巴族', value: '门巴族' },
        { label: '珞巴族', value: '珞巴族' },
        { label: '基诺族', value: '基诺族' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    ybOptions: [
        { label: '无', value: '无', code: 997 },
        { label: '城镇居民基本医疗保险', value: '城镇居民基本医疗保险', code: 1 },
        { label: '公费医疗', value: '公费医疗', code: 2 },
        { label: '社会基本医疗保险', value: '社会基本医疗保险', code: 3 },
        { label: '新型农村合作医疗', value: '新型农村合作医疗', code: 4 },
        { label: '商业医疗保险', value: '商业医疗保险', code: 5 },
        { label: '大病统筹', value: '大病统筹', code: 6 },
        { label: '未知', value: '未知', code: 998 },
        { label: '其他', value: '其他', code: 999 },
    ],
    whcdOptions: [
        { label: '文盲', value: '文盲' },
        { label: '小学', value: '小学' },
        { label: '初中', value: '初中' },
        { label: '高中/中专/技工学校', value: '高中/中专/技工学校' },
        { label: '大专', value: '大专' },
        { label: '本科', value: '本科' },
        { label: '硕士', value: '硕士' },
        { label: '博士及博士以上', value: '博士及博士以上' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    zyOptions: [
        { label: '工人', value: '工人' },
        { label: '职员', value: '职员' },
        { label: '农民', value: '农民' },
        { label: '军人', value: '军人' },
        { label: '教师', value: '教师' },
        { label: '科技人员', value: '科技人员' },
        { label: '行政人员', value: '行政人员' },
        { label: '医务人员', value: '医务人员' },
        {
            label: '个体、商、企、政服务人员',
            value: '个体、商、企、政服务人员',
        },
        { label: '家庭妇女', value: '家庭妇女' },
        { label: '待业', value: '待业' },
        { label: '学生 ', value: '学生 ' },
        { label: '退休/离休', value: '退休/离休' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    sfOptions: [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
    ],
    mywqOptions: [
        { label: '没有', value: '没有' },
        { label: '有', value: '有' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    ymwqnOptions: [
        { label: '有', value: '有' },
        { label: '无', value: '无' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    ywOptions: [
        { label: '有', value: '有' },
        { label: '无', value: '无' },
    ],
    lczzOptions: [
        { label: '胃部不适', value: '胃部不适' },
        { label: '体重下降', value: '体重下降' },
        { label: '腰痛', value: '腰痛' },
        { label: '腹胀', value: '腹胀' },
        { label: '恶心', value: '恶心' },
        { label: '呕吐', value: '呕吐' },
        { label: '便秘', value: '便秘' },
        { label: '腹泻', value: '腹泻' },
        { label: '便血', value: '便血' },
        { label: '黑便', value: '黑便' },
        { label: '腹水', value: '腹水' },
        { label: '反酸', value: '反酸' },
        { label: '嗳气', value: '嗳气' },
        { label: '烧心感', value: '烧心感' },
        { label: '呕血', value: '呕血' },
        { label: '纳差', value: '纳差' },
        { label: '乏力', value: '乏力' },
        { label: '食欲减退', value: '食欲减退' },
        { label: '吞咽困难', value: '吞咽困难' },
        { label: '消化不良', value: '消化不良' },
        { label: '体重减轻', value: '体重减轻' },
        { label: '发热', value: '发热' },
        { label: '下腹不适', value: '下腹不适' },
        { label: '咳嗽', value: '咳嗽' },
        { label: '咳痰', value: '咳痰' },
        { label: '喘憋相', value: '喘憋相' },
        { label: '痰血或咯血', value: '痰血或咯血' },
        { label: '气短或喘鸣', value: '气短或喘鸣' },
        { label: '胸痛', value: '胸痛' },
        { label: '声音嘶哑', value: '声音嘶哑' },
        { label: '饮水返呛', value: '饮水返呛' },
        { label: '胸水', value: '胸水' },
        { label: '上腔静脉堵塞综合征', value: '上腔静脉堵塞综合征' },
        { label: 'HORNER综合征', value: 'HORNER综合征' },
        { label: '神经症状', value: '神经症状' },
        { label: '骨痛', value: '骨痛' },
        { label: '淋巴结肿大', value: '淋巴结肿大' },
        { label: '深静脉血栓DVT', value: '深静脉血栓DVT' },
        { label: '癌痛综合征', value: '癌痛综合征' },
        { label: '胸闷', value: '胸闷' },
        { label: '憋气', value: '憋气' },
        { label: '多尿', value: '多尿' },
        { label: '尿痛', value: '尿痛' },
        { label: '眼花', value: '眼花' },
        { label: '耳鸣', value: '耳鸣' },
        { label: '视力模糊', value: '视力模糊' },
        { label: '手脚麻木', value: '手脚麻木' },
        { label: '乳房胀痛', value: '乳房胀痛' },
        { label: '多饮', value: '多饮' },
        { label: '无', value: '无' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    lczzbhOptions: [
        { label: '好转', value: '好转' },
        { label: '恶化', value: '恶化' },
        { label: '稳定', value: '稳定' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    cjqkOptions: [
        { label: '无残疾', value: '无残疾' },
        { label: '视力残疾', value: '视力残疾' },
        { label: '听力残疾', value: '听力残疾' },
        { label: '言语残疾', value: '言语残疾' },
        { label: '肢体残疾', value: '肢体残疾' },
        { label: '智力残疾', value: '智力残疾' },
        { label: '精神残疾', value: '精神残疾' },
        { label: '其他', value: '其他' },
    ],
    xyzkOptions: [
        { label: '已戒烟', value: '已戒烟' },
        { label: '现在吸烟', value: '现在吸烟' },
        { label: '从未吸烟', value: '从未吸烟' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    wxysjcwOptions: [
        { label: '药物', value: '药物' },
        { label: '化学物质', value: '化学物质' },
        { label: '病毒', value: '病毒' },
        { label: '寄生虫', value: '寄生虫' },
        { label: '职业暴露', value: '职业暴露' },
        { label: '香烟或酒精', value: '香烟或酒精' },
        { label: '污染', value: '污染' },
        { label: '物理（射线等）', value: '物理（射线等）' },
        { label: '食物', value: '食物' },
        { label: '无', value: '无' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    fhcsOptions: [
        { label: '有', value: '有' },
        { label: '无', value: '无' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    yjzkOptions: [
        { label: '从不饮酒', value: '从不饮酒' },
        { label: '偶尔饮酒', value: '偶尔饮酒' },
        { label: '经常饮酒', value: '经常饮酒' },
        { label: '每天饮酒', value: '每天饮酒' },
        { label: '已戒酒', value: '已戒酒' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    yjzlOptions: [
        { label: '白酒', value: '白酒' },
        { label: '啤酒', value: '啤酒' },
        { label: '红酒', value: '红酒' },
        { label: '黄酒', value: '黄酒' },
        { label: '米酒', value: '米酒' },
        { label: '混合类型', value: '混合类型' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    dlplOptions: [
        { label: '每天', value: '每天' },
        { label: '每周3次或以上', value: '每周3次或以上' },
        { label: '每周3次以下', value: '每周3次以下' },
        { label: '不锻炼', value: '不锻炼' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    dlfsOptions: [
        { label: '步行', value: '步行' },
        { label: '慢跑', value: '慢跑' },
        { label: '游泳', value: '游泳' },
        { label: '骑自行车', value: '骑自行车' },
        { label: '舞蹈', value: '舞蹈' },
        { label: '跳绳', value: '跳绳' },
        { label: '瑜伽', value: '瑜伽' },
        { label: '快走', value: '快走' },
        { label: '爬山', value: '爬山' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    dlqdOptions: [
        {
            label: '极大运动强度: 非常吃力，不能坚持到运动结束',
            value: '极大运动强度: 非常吃力，不能坚持到运动结束',
        },
        {
            label: '大运动强度: 相当吃力，但是能坚持到运动结束',
            value: '大运动强度: 相当吃力，但是能坚持到运动结束',
        },
        {
            label: '中等强度: 有适度出汗，肌肉有略微酸胀的感觉',
            value: '中等强度: 有适度出汗，肌肉有略微酸胀的感觉',
        },
        {
            label: '低强度运动: 运动后无汗，脉搏无明显变化，人有较轻松的感觉',
            value: '低强度运动: 运动后无汗，脉搏无明显变化，人有较轻松的感觉',
        },
    ],
    smzkOptions: [
        { label: '入睡困难', value: '入睡困难' },
        { label: '早醒', value: '早醒' },
        { label: '多梦', value: '多梦' },
        { label: '失眠', value: '失眠' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    ysxgOptions: [
        { label: '荤素均衡', value: '荤素均衡' },
        { label: '荤食为主', value: '荤食为主' },
        { label: '素食为主', value: '素食为主' },
        { label: '嗜盐', value: '嗜盐' },
        { label: '嗜油', value: '嗜油' },
        { label: '嗜糖', value: '嗜糖' },
        { label: '外卖为主', value: '外卖为主' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    hyzkOptions: [
        { label: '未婚', value: '未婚' },
        { label: '已婚', value: '已婚' },
        { label: '丧偶', value: '丧偶' },
        { label: '离异', value: '离异' },
        { label: '再婚', value: '再婚' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    yjqkOptions: [
        { label: '无月经', value: '无月经' },
        { label: '未绝经', value: '未绝经' },
        { label: '已绝经', value: '已绝经' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    ybrgxOptions: [
        { label: '配偶', value: '配偶' },
        { label: '父亲', value: '父亲' },
        { label: '母亲', value: '母亲' },
        { label: '兄弟', value: '兄弟' },
        { label: '姐妹', value: '姐妹' },
        { label: '儿子', value: '儿子' },
        { label: '女儿', value: '女儿' },
        { label: '伯/叔', value: '伯/叔' },
        { label: '姑', value: '姑' },
        { label: '侄子', value: '侄子' },
        { label: '侄女', value: '侄女' },
        { label: '外甥', value: '外甥' },
        { label: '外甥女', value: '外甥女' },
        { label: '祖父', value: '祖父' },
        { label: '祖母', value: '祖母' },
        { label: '舅', value: '舅' },
        { label: '姨', value: '姨' },
        { label: '外祖父', value: '外祖父' },
        { label: '外祖母', value: '外祖母' },
        { label: '堂兄弟', value: '堂兄弟' },
        { label: '堂姐妹', value: '堂姐妹' },
        { label: '姑表兄弟', value: '姑表兄弟' },
        { label: '姑表姐妹', value: '姑表姐妹' },
        { label: '舅表兄弟', value: '舅表兄弟' },
        { label: '舅表姐妹', value: '舅表姐妹' },
        { label: '姨表兄弟', value: '姨表兄弟' },
        { label: '姨表姐妹', value: '姨表姐妹' },
        { label: '同父异母兄弟', value: '同父异母兄弟' },
        { label: '同父异母姐妹', value: '同父异母姐妹' },
        { label: '同母异父兄弟', value: '同母异父兄弟' },
        { label: '同母异父姐妹', value: '同母异父姐妹' },
        { label: '其他', value: '其他' },
    ],
    ymmcOptions: [
        { label: '流感', value: '流感' },
        { label: 'hpv二价', value: 'hpv二价' },
        { label: 'hpv四价', value: 'hpv四价' },
        { label: 'hpv九价', value: 'hpv九价' },
        { label: '乙肝', value: '乙肝' },
        { label: '狂犬病', value: '狂犬病' },
        { label: '水痘疫苗', value: '水痘疫苗' },
        { label: '其他', value: '其他' },
    ],
    xxOptions: [
        { label: 'A 型', value: 'A 型' },
        { label: 'B 型', value: 'B 型' },
        { label: 'O 型', value: 'O 型' },
        { label: 'AB 型', value: 'AB 型' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    rhOptions: [
        { label: 'Rh阴性', value: 'Rh阴性' },
        { label: 'Rh阳性', value: 'Rh阳性' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    jldwOptions: [
        { label: 'mg', value: 'mg' },
        { label: 'μg', value: 'μg' },
        { label: 'g', value: 'g' },
        { label: 'IU', value: 'IU' },
        { label: 'ml', value: 'ml' },
        { label: 'U', value: 'U' },
        { label: '片', value: '片' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    yyplOptions: [
        { label: '持续', value: '持续' },
        { label: '按需', value: '按需' },
        { label: '每日一次(QD)', value: '每日一次(QD)' },
        { label: '每日两次(BID)', value: '每日两次(BID)' },
        { label: '每日三次(TID)', value: '每日三次(TID)' },
        { label: '每日四次', value: '每日四次' },
        { label: '隔日一次', value: '隔日一次' },
        { label: '每周一次', value: '每周一次' },
        { label: '每周两次', value: '每周两次' },
        { label: '每周三次', value: '每周三次' },
        { label: '每周四次', value: '每周四次' },
        { label: '每两周', value: '每两周' },
        { label: '每月', value: '每月' },
        { label: '每月两次', value: '每月两次' },
        { label: '每月三次', value: '每月三次' },
        { label: '每两个月', value: '每两个月' },
        { label: '每三个月', value: '每三个月' },
        { label: '每四个月', value: '每四个月' },
        { label: '间歇的', value: '间歇的' },
        { label: 'QN', value: 'QN' },
        { label: 'Q4h', value: 'Q4h' },
        { label: 'Q6h', value: 'Q6h' },
        { label: 'Q8h', value: 'Q8h' },
        { label: 'Q12h', value: 'Q12h' },
        { label: '每三周', value: '每三周' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    yyycxOptions: [
        { label: '按时服用', value: '按时服用' },
        { label: '漏服', value: '漏服' },
        { label: '自行停药', value: '自行停药' },
        { label: '不服药', value: '不服药' },
        { label: '未知', value: '未知' },
        { label: '其他', value: '其他' },
    ],
    jkpjOptions: [
        { label: '体检无异常', value: '体检无异常' },
        { label: '有异常', value: '有异常' },
    ],
    shxgzdOptions: [
        { label: '戒烟', value: '戒烟' },
        { label: '健康饮酒', value: '健康饮酒' },
        { label: '饮食', value: '饮食' },
        { label: '锻炼', value: '锻炼' },
        { label: '减体重', value: '减体重' },
        { label: '建议接种疫苗', value: '建议接种疫苗' },
        { label: '其他', value: '其他' },
    ],
    jdlxOptions: [
        { label: '建档', value: '5' },
        { label: '体检', value: '7' },
        { label: '全科诊疗', value: '8' },
        // { label: '电话问诊', value: '9' },
    ],
    jdlxOptionsOrg: [
        { label: '建档', value: '5' },
        { label: '体检', value: '7' },
        { label: '全科诊疗', value: '8' },
        { label: '咨询单', value: '1', disabled: true },
        { label: '图文问诊', value: '2', disabled: true }, // 问诊单
        { label: '处方单', value: '3', disabled: true },
        { label: '预约单', value: '4', disabled: true },
        { label: '上传资料', value: '10' },
        { label: '焦虑筛查量表', value: '20' },
        { label: '抑郁筛查量表', value: '21' },
        { label: '预约-电话回访', value: '9' },
        { label: '健康保问诊', value: '12' },
    ],
    txlxOptions: [
        { label: '常规健康提醒', value: '1' },
        { label: '建档预登记表', value: '30' },
        { label: '上传资料', value: '10' },
        { label: '焦虑筛查量表', value: '20' },
        { label: '抑郁筛查量表', value: '21' },
    ],
    fwfsOptions: [
        { label: '现场', value: '1' },
        { label: '电话', value: '2' },
    ],
    fwfsOptionsOrg: [
        { label: '现场', value: '1' },
        { label: '电话', value: '2' },
        { label: '线上', value: '3' },
    ],
    jzlxOptions: [
        { label: '门诊', value: '门诊' },
        { label: '住院', value: '住院' },
        { label: '线上诊疗', value: '线上诊疗' },
        { label: '其他', value: '其他' },
    ],
};
const valueName = {
    recordDate: '记录日期',
    name: '姓名',
    gender: '性别',
    idNo: '身份证号',
    age: '年龄',
    nation: '民族',
    // birthDate: '出生日期',
    // phone: '本人手机号',
    insurance: '医保情况',
    residence: '户籍地址',
    // address: '详细地址',
    street: '详细地址(备注)',
    email: '邮箱地址',
    contacter: '联系人姓名',
    contactPhone: '联系人电话',
    educational: '学历',
    job: '职业',
    workYear: '工作年限',
    medicalNo: '病历号',
    visitDate: '就诊日期',
    hospitalDate: '入院日期',
    dischargeDate: '出院日期',
    visitType: '就诊类型',
    hospitalName: '就诊医院',
    admissionDate: '入科日期',
    admissionDays: '入科天数',
    departmentName: '就诊科室',
    doctor: '主治医生',
    description: '主诉',
    sym: '症状',
    symDate: '症状发生时间',
    symDays: '症状持续时间',
    symNumbers: '症状发生次数',
    symChange: '临床症状变化',
    present: '病情描述',
    pahExamine: '病理检查',
    high: '身高',
    weigh: '体重',
    bmi: '体质指数BMI',
    waist: '腰围',
    hips: '臀围',
    whr: '腰臀比',
    disable: '残疾情况',
    tem: '体温',
    pulse: '脉搏',
    breathRate: '呼吸频率',
    leftDiastolic: '左侧舒张压',
    leftSystolic: '左侧收缩压',
    rightDiastolic: '右侧舒张压',
    rightSystolic: '右侧收缩压',
    dayFunction: '日间功能障碍',
    nightFunction: '夜间功能障碍',
    sleepTime: '睡眠时长',
    result: '睡眠量表检测结果',
    recordDate11: '记录日期',
    smoke: '吸烟状况',
    smokeNumber: '日吸烟量（每日平均支数)',
    startSmokeAge: '开始吸烟年龄',
    endSmokeAge: '戒烟年龄',
    dangerous: '危险因素接触物',
    dangerousRate: '每月频次',
    dangerousPro: '防护措施',
    drinkStatus: '饮酒状况',
    drinkStrenth: '次饮酒量',
    startDrinkAge: '开始饮酒年龄',
    drinkRate: '饮酒频次',
    endDrinkAge: '戒酒年龄',
    drunkTimes: '近一年内醉酒次数',
    drinkType: '饮酒种类',
    exerciseWays: '锻炼方式',
    exerciseStrenth: '锻炼强度',
    lifestyleExerciseFrequency: '锻炼频率',
    exerciseDays: '坚持锻炼时间',
    exerciseTime: '每次锻炼时间',
    sleepTime11: '睡眠时长',
    lifestyleSleepPatterns: '睡眠状况',
    lifestyleEatingHabits: '饮食习惯',
    marriage: '婚姻状况',
    birth: '生育情况',
    menopause: '绝经年龄',
    minMenstrualCycle: '最短月经周期',
    maxMenstrualCycle: '最长月经周期',
    minDuration: '最短持续时间',
    maxDuration: '最长持续时间',
    birthDate: '分娩时间',
    pregnantNumber: '孕次数',
    birthNumber: '生产次数',
    birthWay: '分娩方式',
    abnormal: '妊娠期间异常情况',
    pregnancy: '妊娠期',
    dueDate: '预产期',
    menstruation: '月经情况',
    menstruationAge: '初潮年龄',
    menstruationDate: '月经周期',
    menstruationDays: '持续时间',

    menstruationEndDate: '末次月经时间',
    menstruationStatus: '周期是否规则',
    menstruationAche: '有无痛经',
    menstruationVolume: '月经量',
    volumeMark: '备注',
    familyRela: '与本人关系',
    familyHis: '家族疾病史',
    allergyStatus: '有无过敏史',
    allergyName: '药品通用名',
    allergyNamess: '药物商品名',
    allergyOther: '其他物质',
    allergyMark: '备注',
    inoculationName: '非免疫规划预防接种疫苗名称',
    confirmedDate: '确诊日期',
    inoculationDate: '接种日期',
    examineDate: '检查日期',
    reportDate: '报告日期',
    badStartDate: '不良事件开始日期',
    examineProjects: '检查项目',
    examineResult: '检查结果',
    blood: '周边血流情况',
    bloodGlucose: '空腹血糖',
    eatGlucose: '餐后2小时血糖',
    hba1c: '糖化血红蛋白(Hba1C)',
    creat: '肌酐(Creat)',
    bun: '尿素氮（BUN）',
    urate: '尿酸(Urate)',
    hgb: '血红蛋白(HGB)',
    urine: '尿蛋白',
    pylori: '幽门螺旋杆菌',
    ast: '天冬氨酸转氨酶(AST)',
    alt: '丙氨酸转氨酶(ALT)',
    rggt: 'γ-谷氨酰胺转移酶(r-GGT)',
    ft3: '游离3-碘甲腺原氨酸（FT3）',
    ft4: '游离甲状腺素（FT4）',
    tsh: '促甲状腺激素（TSH）',
    tpo: '甲状腺过氧化物酶抗体（TPO-Ab）',
    tgab: '甲状腺球蛋白抗体（Tg-Ab）',
    trig: '甘油三酯(Trig)',
    chol: '总胆固醇(Chol)',
    cdl: '低密度脂蛋白胆固醇(Ldl)',
    hdl: '高密度脂蛋白胆固醇(Hdl)',
    rh: 'RH型',
    examinePart: '检查部位',
    imageFind: '影像学发现',
    tissuePart: '组织部位',
    numbers: '数目',
    long: '纵径',
    across: '横径',
    horizontalRatio: '纵横比',
    breast: '乳腺组织构成',
    breastNature: '组织性质',
    breastForm: '组织形态',
    breastDirect: '组织方向',
    innerEcho: '内部回声',
    outerEcho: '外部回声',
    edge: '边缘情况',
    cao: '钙化情况',
    tirads: 'TI-RADS分级',
    lymph: '淋巴结整体情况',
    birads: 'BI-RADS分级',
    uterusSize: '子宫大小',
    gene: '基因检查',
    geneName: '基因检测名称',
    geneResult: '基因检测结果',
    medicineCheck: '病理检查',
    immuneName: '免疫表型名称',
    immuneResult: '免疫表型结果',
    partType: '组织学类型',
    partPice: '能否提供组织病理切片',
    diagDate: '确诊日期',
    firstDate: '首次确诊日期',
    endDate: '末次确诊日期',
    diag: '临床诊断',
    diagMark: '临床诊断（备注）',
    clinicalDiagnosis: '临床诊断',
    diseaseTime: '患病时长',
    diseaseTimes: '发病次数',
    tumorPart: '肿瘤部位',
    dangerPart: '危险分层',
    molecular: '分子分型',
    clinicalStage: '临床分期类别',
    tnm: 'TNM分期',
    lugano: 'Lugano分期',
    ann: 'ANN分期',
    riss: 'R-ISS分期',
    ds: 'DS分期',
    iss: 'ISS分期',
    rai: 'Rai分期',
    binet: 'Binet分期',
    carcinoma: '小细胞肺癌分期',
    nephropathy: '肾病分期',
    retinopathy: '视网膜病变分期',
    fatty: '脂肪肝程度',
    fateRadioCheck: '脂肪肝B超诊断分度',
    allergic: '过敏性鼻炎分度',
    diabetes: '糖尿病病足wagner分级',
    diabetesType: '糖尿病病足类型',
    isOpertation: '有无手术',
    operDate: '手术日期',
    operName: '手术名称',
    bracketNum: '支架个数',
    mark: '备注',
    symPart: '部位',
    symStartTime: '症状发生时间',
    medicalStage: '治疗阶段',
    medicalType: '治疗类别',
    customerName: '药品厂家',
    drugUnit: '药品规格',
    drugNum: '药品数量',
    commonName: '药物通用名',
    drugName: '药物商品名',
    medStartDate: '用药开始日期',
    medEndDate: '用药结束日期',
    drugDose: '每次给药剂量',
    medicalWay: '用药方式',
    preDose: '每次给药剂量',
    dose: '剂量单位',
    medicalRate: '用药频率',
    isDose: '有无剂量调整',
    changeReason: '调整原因',
    doseChange: '剂量变化',
    compliance: '用药依从性',
    stopReaseon: '终止原因',
    medicalChange: '是否换药',
    changeDrugName: '更换用药的名称',
    medicalGuide: '用药指导',
    isBad: '有无不良事件',
    badEvent: '不良事件名称',
    badDegree: '不良事件严重程度',
    badSolution: '不良事件处理方法',
    badResult: '不良事件结局',
    efficacyDate: '疗效评估日期',
    efficacyInfo: '疗效评估',
    tumorProgression: '肿瘤进展类型',
    progressionDate: '复发或进展日期',
    progressionPart: '进展部位',
    effect: '治疗效果',
    com: '耗材厂家',
    price: '耗材单价',
    name11: '耗材名称',
    number: '耗材数量',
    equipName: '使用器材名称',
    isBlood: '有无输血',
    bloodTime: '输血时间',
    bloodStrengh: '输血剂量',
    bloodType: '输血类别',
    bloodMark: '备注',
    isMig: '有无移植',
    harveDate: '采干日期（自体）',
    migType: '移植类型',
    migDate: '移植日期（异体）',
    isRadio: '有无放疗',
    radioTimes: '放疗次数',
    radioNum: '放疗总剂量',
    treatInfo: '治疗意见',
    treatMark: '治疗信息备注',
    unormalInfo: '费用发生日期',
    costType: '费用类别',
    socialCost: '社保内自付金额',
    insuranceCost: '医保报销比例',
    selfCost: '自费金额',
    sum: '总费用',
    lifeWay: '生活方式干预',
    hospitalization: '住院情况',
    dangerMark: '备注',
    // 内容归类
    // symptom: '症状',
    symp: '临床症状',
    sign: '体征',
    sleep: '失眠',
    smoking: '吸烟史',
    danger: '危险因素接触史',
    drink: '饮酒史',
    life: '生活方式',
    marry: '婚育月经史',
    family: '家族史',
    allergy: '过敏史',
    inoculation: '预防接种史',
    genCheck: '基因检测',
    immune: '免疫表型',
    bad: '不良事件',
    // 二级菜单
    labExamine: '实验室检查',
    image: '影像学检查',
    endoscopy: '内镜检查',
    supply: '辅助检查',
    other: '其他检查',
    gen: '基因检查',
    pathology: '病理检查',
    operation: '手术信息',
    medication: '用药信息',
    bads: '不良事件信息',
    efficacy: '疗效信息',
    supplies: '耗材信息',
    equipment: '器材信息',
    bloodInfo: '输血信息',
    migration: '移植信息',
    radio: '放疗信息',
    // 一级菜单
    baseInfos: '基本信息',
    medicalInformation: '住院史', // 就诊信息
    symptomsAndSigns: '一般状况', // 症状和体征
    pastHistoryInformation: '既往史信息',
    examine: '个人疾病史', // 检查信息
    diagnosticInformation: '诊断信息',
    treatInfos: '治疗信息',
    costInfos: '费用信息',
    followInfos: '随访信息',
    healthGuide: '健康指导',
    // 疾病模块
    others: '基本信息',
    medInfo: '用药信息',
    Dyslipidemia: '实验室检查',
    imagess: '影像学检查',
    bloods: '输血信息',
    isFollow: '是否定期复诊',
    followPeriod: '复诊间隔 ',
    followDate: '随访日期 ',

    allergyHistory: '过敏史',
    signss: '体征',
    noSleep: '睡眠情况',
    remarks: '备注信息',
    remarkss: '备注',
};
const company = {
    high: 'cm',
    weigh: 'kg',
    bmi: 'kg/m²',
    waist: 'cm',
    hips: 'cm',
    tem: '℃',
    pulse: '次/分钟',
    breathRate: '次/分钟',
    leftDiastolic: 'mmHg',
    leftSystolic: 'mmHg',
    rightDiastolic: 'mmHg',
    rightSystolic: 'mmHg',
    menstruationAge: '岁',
    menstruationDate: '天',
    menstruationDays: '天',
    menopause: '岁',
    minMenstrualCycle: '天',
    maxMenstrualCycle: '天',
    minDuration: '天',
    maxDuration: '天',
    exerciseTime: '分钟',
    exerciseDays: '年',
    sleepTime11: '小时',
    drinkStrenth: 'ml',
    endDrinkAge: '岁',
    startDrinkAge: '岁',
    drunkTimes: '次',
    trig: 'mmol/L',
    chol: 'mmol/L',
    cdl: 'mmol/L',
    hdl: 'mmol/L',
    hgb: 'g/L',
    ast: 'U/L',
    alt: 'U/L',
    rggt: 'U/L',
    long: 'cm',
    across: 'cm',
    bun: 'mmol/L',
    urate: 'μmol/L',
    ft3: 'nmol/l',
    ft4: 'pmol/l',
    tsh: 'mIU/L ',
    tpo: 'IU/ml',
    tgab: 'IU/ml',
    bloodGlucose: 'mmol/L',
    eatGlucose: 'mmol/L',
    hba1c: '%',
    creat: 'μmol/L',
    symDays: '分钟',
    sleepTime: '小时/天',
    endSmokeAge: '岁',
    startSmokeAge: '岁',
    birthNumber: '次',
    pregnantNumber: '次',
    smokeNumber: '支',
    dangerousRate: '次',
    followPeriod: '月',
};
const InputMessage = {
    medicalInformation: {
        // 就诊信息
        clinicDate: '', // 就诊日期
        admissionDate: '', // 入院日期
        dischargeDate: '', // 出院日期
        clinicType: '', // 就诊类型
        clinicHospital: '', // 就诊医院
        clinicDepartment: '', // 就诊科室
        doctor: '', // 主治医生
    },
    symptomsAndSigns: {
        // 症状和体征
        recordDate: '', // 记录日期
        description: '', // 主诉
        symptomsClinicalSymptoms: '', // 临床症状（组合无限加）
        symptomsClinicalChange: '', // 临床症状变化（组合无限加）
        symptomsLastDate: '', // 症状持续日期（组合无限加）
        hpi: '', // 现病史
        height: '', // 身高
        weight: '', // 体重
        bmi: '', // BMI
        waist: '', // 腰围
        disability: '', // 残疾情况
        signsTemperature: '', // 体温
        signsPulse: '', // 脉搏
        signsBreathingRate: '', // 呼吸频率
        signsLeftDiastolicBloodPressure: '', // 左侧舒张压
        signsLeftSystolicBloodPressure: '', // 左侧收缩压
        signsRightDiastolicBloodpressure: '', // 右侧舒张压
        signsOfRightSystolicBloodPressure: '', // 右侧收缩压
        symptomsArr: '', // 症状数组
    },
    pastHistoryInformation: {
        // 既往史信息
        recordDate: '', // 记录日期
        smokinghistorySmokingStatus: '', // 吸烟状况
        smokinghistoryAverageCount: '', // 日吸烟量（每日平均支数）
        smokinghistoryBeginSmokingAge: '', // 开始吸烟年龄
        smokinghistoryStopSmokingAge: '', // 戒烟年龄
        riskFactorsContactRiskFactors: '', // 危险因素接触物（组合无限加）
        riskFactorsContactProtectiveMeasures: '', // 防护措施（组合无限加）
        historyOfDrinkingDrinking: '', // 饮酒状况
        historyOfDrinkingDayDrink: '', // 日饮酒量
        historyOfDrinkingStopDrinkingAge: '', // 戒酒年龄
        historyOfDrinkingEverDrunkNearlyYear: '', // 近一年内是否曾醉酒
        historyOfDrinkingDrinkingSpecies: '', // 饮酒种类
        lifestyleExerciseFrequency: '', // 锻炼频率
        lifestyleExerciseWay: '', // 锻炼方式
        lifestyleExerciseIntensity: '', // 锻炼强度
        lifestyleExerciseTime: '', // 每次锻炼时间
        lifestyleExerciseLastTime: '', // 坚持锻炼时间
        lifestyleSleepTime: '', // 睡眠时长
        lifestyleSleepPatterns: '', // 睡眠状况
        lifestyleEatingHabits: '', // 饮食习惯
        babyMenstrualHistoryMaritalStatus: '', // 婚姻状况
        babyMenstrualHistoryChildStatus: '', // 生育情况
        babyMenstrualHistoryMenstruation: '', // 月经情况
        familyHistoryOfFamilyHistoryDisease: '', // 家族疾病史（组合无限加）
        familyHistoryOfRelationship: '', // 与本人关系（组合无限加）
        allergyAllergies: '', // 有无过敏史
        historyVaccinationVaccinationVaccineName: '', // 非免疫规划预防接种疫苗名称（组合无限加）
        historyOfVaccinationVaccinationDate: '', // 接种日期（组合无限加）
        riskFactorsArr: '', // 危险因素接触史数组
        familyHistoryArr: '', // 家族病例史数组
        historyVaccinationArr: '', // 非免疫规划预防接种疫苗名称数组
    },
    laboratoryeExamination: {
        // 实验室检查
        checkDate: '', // 检查日期
        bloodType: '', // 血型
        rhType: '', // rh 型
    },
    diagnosticInformation: {
        // 诊断信息
        confirmedDate: '', // 确诊日期
        firstConfirmedDate: '', // 首次确诊时间
        clinicalDiagnosis: '', // 临床诊断
    },
    surgeryInformation: {
        // 手术信息
        surgeryStatus: '', // 有无手术
        surgeryTime: '', // 手术日期
        surgeryName: '', // 手术名称
    },
    medicationInformation: {
        // 用药信息
        drugFactoryName: '', // 药品厂家
        drugsCommonName: '', // 药物通用名
        drugName: '', // 药物商品名
        medicineStartDate: '', // 用药开始日期
        drugEndDate: '', // 用药结束日期
        amountOfDrug: '', // 每次给药剂量
        drugUnit: '', // 剂量单位
        drugFrequency: '', // 用药频率
        medicationAdherence: '', // 用药依从性
        guidelines: '', // 用药指导
    },
    bloodTransfusionInformation: {
        // 输血信息
        havaBloodTransfusion: '', // 有无输血
        bloodTransfusionTime: '', // 输血时间
    },
    treatmentInformationSecond: {
        // 治疗信息
        doctorAdvice: '', // 医嘱
    },
    healthGuide: {
        // 健康指导
        guideDate: '', // 指导日期
        healthAssessment: '', // 健康评价
        abnormalProject: '', // 异常项目
        healthAssessmentDetails: '', // 健康评价详情
        lifestyleGuidance: '', // 生活习惯指导
        healthArr: '', // 健康无限组合数组
        healthPlan: '', // 健康规划
        plan: '', // 规划详情
    },
};
export default {
    optionsMap,
    valueName,
    InputMessage,
    company,
};
