import { mapActions } from 'vuex';
import { safeParse } from '@/utils';

export default {

  data() {
    return {
      supervisionSwitchLoading: true,
    }
  },

  methods: {
    ...mapActions({
      getSupervisionSwitch: 'supervision/getSupervisionSwitch',
    }),

    initSupervisionSwitch() {
      const userInfo = safeParse(localStorage.getItem('userInfo'), {});
      // 获取监管开关配置
      this.getSupervisionSwitch({doctorId: userInfo.accountId}).finally(() => {
        this.supervisionSwitchLoading = false;
      })
    }
  },

  mounted() {
    this.initSupervisionSwitch();
  },

}

