<!--
 * @Author: xuchuanwei
 * @Date: 2022-03-17 17:26:17
 * @LastEditors: xuchuanwei
 * @Description: 意愿认证-验证码
-->

<template> 
  <el-dialog title="签名" :visible="visible" width="550px" @close="cancel">
    <div class="dialog-content">
      <el-form 
        :model="formdata"
        ref="verifyForm" 
        label-width="100px">
        <el-form-item>
          验证码已发送到手机 {{secretPhone(userInfo.phone)}}  
          <span class="seconds" v-if="seconds > 0">{{seconds}}s</span>
          <el-button :loading="loading" type="text" v-else @click="sendMessage">重新发送</el-button>
        </el-form-item>
    
        <el-form-item
          label="验证码"
          prop="code"
          :rules="[
            { required: true, message: '请输入验证码' }, 
            { min: 4, max: 4, message: '请输入4位验证码', trigger: 'blur' }
          ]"
        >
          <el-input v-model="formdata.code" maxlength="4" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item class="dialog-footer"> 
          <el-button @click="cancel">取 消</el-button>
          <el-button :loading="verifying" type="primary" @click="confirm('verifyForm')">确 定</el-button>
        </el-form-item>
      </el-form>
    </div> 
  </el-dialog> 
</template>
<script>
import { getMessageCode, verifyMessageCode } from '@/api/serviceExtend';
import { safeParse } from '@/utils';

export default {
  name: 'MessageCodeVerify',
  props: {  
    visible: {
      type: Boolean,
      default: false,
    },
    
    title: String,

    // PRESCRIPTION_AUDIT("1", "电子处方审核短信发送"), 
    // PRESCRIPTION_SIGNATURE("2", "电子处方医生签名短信发送"),
    // ASPIRATION_AUTHENTICATION("3", "意愿认证短信发送"), 电子病历
    messageTypeEnum: {
      type: String,
      required: true,
    }, 
  },
  data() {
    const userInfo = safeParse(localStorage.getItem('userInfo'), {}); 
    return {
      hadSendMsg: false, // 是否发送过msg
      formdata: {
        code: '',
      },
      userInfo,
      seconds: 0,
      timer: null, 
      loading: false,
      verifying: false,
    }
  },

  methods: {
    secretPhone(phone) {  
      return phone.replace(/(\w{3})\w*(\w{4})/, '$1****$2')
    },

    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;  
        let params = {
          messageTypeEnum: this.messageTypeEnum,
          phone: this.userInfo.phone,
          verificationCode: this.formdata.code,
        }
        this.verifying = true;
        verifyMessageCode(params).then(res => {
          this.$emit('confirm', () => {
            this.verifying = false;
          });
        }).catch(err => {
          this.verifying = false;
        })
      }); 
    },

    cancel() {
      this.$emit('update:visible', false);
    },

    sendMessage() { 
      let params = {
        phone: this.userInfo.phone,
        messageTypeEnum: this.messageTypeEnum,
      }
      this.loading = true;
      this.hadSendMsg = true;
      getMessageCode(params).then(res => {
        this.countDown();
      }).finally(() => {
        this.loading = false;
      })
    },

    countDown() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.seconds = 60;
      this.timer = setInterval(() => { 
        if (this.seconds > 0) {
          this.seconds--; 
        } else {
          this.seconds = 0;
          clearTimeout(this.timer);
        }
      }, 1000)
    }
  },

  watch: {
    visible: function(newV) {
      if (newV && !this.hadSendMsg) {
        this.sendMessage();
      } 
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
};
</script>

<style lang="scss" scoped>
  .dialog-content {
    padding: 20px;

    .seconds {
      color: #1989fa;
      padding: 0 10px;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      margin-bottom: 0;
    }
  }
</style>