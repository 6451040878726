/*
 * @Author: huihua.he
 * @Email: huihua.he@medbanks.cn
 * @Date: 2021-04-06 15:39:01
 * @Last Modified by: leslieHoward
 * @Last Modified time: 2021-07-09 17:15:45
 * @Description: Description
 * @Route: Route
 */

<template>
    <el-dialog
        :visible.sync="visible"
        :close-on-click-modal="false"
        custom-class="logsDialog"
        @closed="onDialogClosed">
        <el-form
            ref="form"
            :model="params"
            inline="false">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item class="form-item">
                        <span class="form-label">操作人CN号:</span>
                        <el-input
                            v-model="params.optUserNumber"
                            class="form-item-content"
                            placeholder="请输入操作人CN号" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="form-item">
                        <span class="form-label">操作人姓名:</span>
                        <el-input
                            v-model="params.optUserName"
                            class="form-item-content"
                            placeholder="请输入操作人姓名" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="form-item">
                        <span class="form-label">操作内容:</span>
                        <el-input
                            v-model="params.content"
                            class="form-item-content"
                            placeholder="请输入操作内容" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="dialog-footer">
            <el-button @click="onReset('form')">
                重置
            </el-button>
            <el-button
                type="primary"
                :loading="isSearching"
                @click="onSearch">
                查询
            </el-button>
        </div>

        <el-table :data="table.data"
                  max-height="400">
            <el-table-column
                prop="optUserName"
                label="操作人">
                <template slot-scope="scope">
                    <span>
                        {{
                            `${scope.row.ext1}${scope.row.optUserName}`
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                property="optTime"
                label="操作时间"
                width="200" />
            <el-table-column
                prop="content"
                label="操作内容">
                <template slot-scope="scope">
                    <span v-html="scope.row.content" />
                </template>
            </el-table-column>
        </el-table>
        <div
            class="pagination"
            style="margin: 20px 0">
            <el-pagination
                :current-page="params.pageNumber"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="20"
                :total="Number(table.total) "
                background
                pager-count="5"
                @current-change="onPageChange"
                @size-change="onPageSizeChange" />
        </div>
    </el-dialog>
</template>

<script>
import _ from 'lodash';
import { safeGet } from '@/utils';
import { opreaionLogs } from '@/api';

export default {
    name: 'OperationDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        optFunc: {
            type: String,
            default: 'service_manage',
        },
    },
    data() {
        return {
            isSearching: false,
            params: {
                optFunc: this.optFunc,
                optUserNumber: '',
                optUserName: '',
                content: '',
                pageNumber: 1,
                pageSize: 20,
                bizCode: 'QB',
            },
            table: {
                data: [],
                total: 0,
            },
        };
    },
    watch: {
        visible(value) {
            if (value) {
                this.init();
            }
        },
    },
    methods: {
        async init() {
            const response = await this.getSysCommonLogs();
            this.table.data = response.data;
            this.table.total = response.total;
        },

        // 请求日志列表接口
        async getSysCommonLogs() {
            this.isSearching = true;
            const response = await opreaionLogs(this.params);
            this.isSearching = false;

            return {
                data: safeGet(response, ['data', 'list'], []),
                total: safeGet(response, ['data', 'totalRecord'], 0),
            };
        },

        onPageChange(pageNumber) {
            this.params.pageNumber = pageNumber;
            this.init();
        },

        onPageSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.init();
        },

        // 查询
        onSearch() {
            this.params.pageNumber = 1;
            this.init();
        },

        // 重置
        onReset(formName) {
            this.$refs[formName].resetFields();
            this.params = {
                ...this.params,
                optUserNumber: '',
                optUserName: '',
                content: '',
                pageSize: 20,
                pageNumber: 1,
            };
            this.$nextTick(() => {
                this.init();
            });
        },

        // 取消
        onCancel() {
            this.$emit('update:visible', false);
        },

        onDialogClosed() {
            this.onCancel();
        },
    },
};
</script>

<style lang="scss" scoped>
.form-item {
    width: 100%;
    max-width: 450px;
    /deep/ .el-form-item__content {
        display: flex;
        width: 100%;
        height: 100%;
    }
    .form-label {
        display: flex;
        padding: 0 12px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        line-height: 18px;
        width: 128px;
    }
    .form-item-content {
        display: flex;
        width: 80%;
        height: 36px;
        line-height: 36px;
    }
}
.pagination {
    margin-top: 10px;
}
</style>
