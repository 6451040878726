// E签保的签名认证二维码
<template>
  <UserSignatureCom
    :visible="visible"
    :title="title"
    :getSignatureFn="getSignatureUrl"
    @update:visible="cancel"
    @signature-success="signatureSuccess"
  />
</template>

<script>
import UserSignatureCom from './UserSignatureCom.vue';
import { getFaceAuthUrl, getFaceAuthUrlOfDoctor } from '@/api/serviceExtend';
import config from '@/config';
export default {
  name: 'UserSignature',
  components: {
    UserSignatureCom,
  },
  props: {
    title: {
      type: String,
      default: '人脸识别',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number, // 处方|电子病历自增长id
      default: '',
    },
    // 1电子病历，0电子处方
    bizType: {
      type: Number,
      required: true,
    },
    // E签宝弹窗类型(prescription:开处方 | doctorAudit:医生去审核)
    type: {
      type: String,
      default: 'prescription',
    },
    // 服务单ID
    serviceId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    };
  },

  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    // 获取E签宝医生手写签名地址
    getSignatureUrl() {
      this.timer && clearTimeout(this.timer);
      this.loading = true;
      this.isValid = true;
      let apiPromise;
      if (this.type === 'doctorAudit') {
        const params = {
          auditType: '0',
          serviceId: this.serviceId,
          doctorId: this.userInfo.accountId,
          callbackUrl: config.signatureResultPage,
        };
        apiPromise = getFaceAuthUrlOfDoctor(params);
      } else {
        const params = {
          id: this.id,
          doctorId: this.userInfo.accountId,
          bizType: this.bizType,
          callbackUrl: config.signatureResultPage,
        };
        apiPromise = getFaceAuthUrl(params);
      }

      return apiPromise;
    },

    signatureSuccess(flowId) {
      this.$emit('signature-success', flowId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
