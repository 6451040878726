import _ from 'lodash';
import Mchat from '@med/mchat';
import config from '@/config';
import { isNilString } from './index';

export const msgType = {
  100: '文本',
  101: '图片',
  102: '处方',
  103: '健康档案',
  104: '订单状态',
  105: '签章消息',
  106: '驳回用药建议',
  108: '主诉',
  200: '连接',
  201: '断开',
  202: '出错',
  300: '设置消息状态',
  400: '开始问诊',
  401: '结束问诊',
  402: '问诊到期',
  403: '拒绝',
  404: '驳回',
};

export default function init(options = {}) {
  const token = localStorage.getItem(config.TOKEN_KEY);

  if (!isNilString(token)) {
    return new Mchat({
      url: `${config.wsUrl}/d/${token}`,
      // debug: !config.isProd,
      debug: false,
      intervalTime: 5000,
      ...options,
    });
  }

  return _.noop();
}
