/* * @Author: zhangzc * @Email: zhicheng.zhang@medbanks.cn * @Date: 2019-10-28 13:39:14 * @Last Modified by: zhifen.zhu
* @Last Modified time: 2022-02-16 17:51:06 * @Description: 统一公共头部 * @Route: Route */

<template>
  <div class="hx-header">
    <div class="logo-wrapper head-left">
      <i v-if="expandMuneBoolen" class="el-icon-s-fold menu_icon" @click="getExpandValue" />
      <i v-else class="el-icon-s-unfold menu_icon" @click="getExpandValue" />
    </div>
    <div class="head-right clearfix">
      <div v-if="showAccountInfo" class="user-right-box">
        <span class="user-name-wrapper">
          <span class="tip-txt">您好，</span>
          <span class="user-name">
            {{ computedUsername }}
          </span>
        </span>
        <span class="vertical-line">|</span>
        <span class="user-exit" @click="handleLogout"> 退出 </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import config from '@/config';
import { safeGet } from '@/utils';
import { renewalToken } from '@/api/index';
import { loginPM } from '@/utils/loginAuth';

export default {
  props: {
    showAccountInfo: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    expandMuneBoolen() {
      return this.$store.state.expandMuneBoolen;
    },
    computedUsername() {
      return safeGet(this.userInfo, ['accountInfo', 'realName'], '--');
    },
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.refreshToken();
    }, 30 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    /**
     * 侧边栏目展开收齐
     */
    getExpandValue() {
      const vuale = !this.$store.state.expandMuneBoolen;
      this.$store.commit('expandMuneOperation', vuale);
    },

    /**
     * 退出登陆
     */
    handleLogout() {
      localStorage.removeItem(config.TOKEN_KEY);
      loginPM();
    },

    async refreshToken() {
      const res = await renewalToken();
      console.log('refreshtoken==');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.hx-header {
  position: fixed;
  top: 0;
  right: 0;
  left: $--left-block-width;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: $--color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: $--color-text-regular;
  font-size: 14px;
  z-index: 99;
  .menu_icon {
    font-size: 24px;
    cursor: pointer;
  }
  .head-left {
    flex-shrink: 0;
    padding: 0 15px;
    color: $--color-black;
    font-size: 18px;
    text-align: center;
    img {
      width: 37px;
      height: 32px;
    }
  }
  .head-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  .user-right-box {
    font-size: 0;
    .user-name {
      color: $--color-primary;
      font-size: 14px;
      text-decoration-line: none;
    }
    .tip-txt {
      color: $--color-text-regular;
      font-weight: 400;
      font-size: 14px;

      &::before {
        content: '';
        display: inline-block;
        margin: -3px 10px 0 0;
        width: 20px;
        height: 20px;
        background: url('~@/assets/images/avatar.png') no-repeat 100%;
        vertical-align: middle;
      }
    }
    .vertical-line {
      margin: 0 13px 0 17px;
      color: $--border-color-light;
      font-size: 14px;
    }
    .user-exit {
      margin: 0 16px 0 0;
      cursor: pointer;
      font-size: 14px;

      &::before {
        content: '';
        display: inline-block;
        margin: -2px 6px 0 0;
        width: 20px;
        height: 20px;
        background: url('~@/assets/images/logout.png') no-repeat 100%;
        vertical-align: middle;
      }
    }
  }
}
</style>
