/*
 身份证规则：
 15
 0-6：地址
 7-12: +19 出生日期

 18
 1-6：地址 412726
 7-14: 出生日期 19900101
 15-17: 851
 18:校验位

 校验位置：
 由前17位决定

 powers
 18

 */

const idCardNoUtil = {
    /* 省,直辖市代码表 */
    provinceAndCitys: {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门',
        91: '国外',
    },

    /* 每位加权因子 */
    powers: ['7', '9', '10', '5', '8', '4', '2', '1', '6', '3', '7', '9', '10', '5', '8', '4', '2'],

    /* 第18位校检码 */
    parityBit: ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'],

    /* 性别 */
    genders: { male: '男', female: '女' },

    /* 校验地址码 */
    checkAddressCode(addressCode) {
        const check = /^[1-9]\d{5}$/.test(addressCode);
        if (!check) return false;
        if (idCardNoUtil.provinceAndCitys[parseInt(addressCode.substring(0, 2), 10)]) {
            return true;
        }
        return false;
    },

    /* 校验日期码 */
    checkBirthDayCode(birDayCode) {
        const check = /^[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))$/.test(birDayCode);
        if (!check) return false;
        const yyyy = parseInt(birDayCode.substring(0, 4), 10);
        const mm = parseInt(birDayCode.substring(4, 6), 10);
        const dd = parseInt(birDayCode.substring(6), 10);
        const xdata = new Date(yyyy, mm - 1, dd);
        const now = new Date();

        if (xdata.getTime() > now.getTime() && (xdata.getFullYear() - now.getFullYear()) > 105) {
            return false;// 生日不能大于当前日期
        }
        if ((xdata.getFullYear() === yyyy) && (xdata.getMonth() === mm - 1)
        && (xdata.getDate() === dd)) {
            return true;
        }

        return false;
    },

    /* 计算校检码 */
    getParityBit(idCardNo) {
        const id17 = idCardNo.substring(0, 17);
        /* 加权 */
        let power = 0;
        for (let i = 0; i < 17; i += 1) {
            power += parseInt(id17.charAt(i), 10) * parseInt(idCardNoUtil.powers[i], 10);
        }
        /* 取模 */
        const mod = power % 11;
        return idCardNoUtil.parityBit[mod];
    },

    /* 验证校检码 */
    checkParityBit(idCardNo) {
        const parityBit = idCardNo.charAt(17).toUpperCase();
        if (idCardNoUtil.getParityBit(idCardNo) === parityBit) {
            return true;
        }
        return false;
    },

    /* 校验15位或18位的身份证号码 */
    checkIdCardNo(idCardNo) {
        let check = '';
        if (idCardNo.length === 10) {
            check = /^[a-zA-Z]{1}\d{6}\([A0-9]\)$/.test(idCardNo) === true ? true : /^[a-zA-Z]{1}\d{6}（[A0-9]）$/.test(idCardNo);
        } else {
            check = /^\d{15}|(\d{17}(\d|x|X))$/.test(idCardNo);
        }

        if (!check) { return false; }
        // 15位和18位身份证号码的基本校验

        // 判断长度为15位或18位
        if (idCardNo.length === 15) {
            return idCardNoUtil.check15IdCardNo(idCardNo);
        } if (idCardNo.length === 18) {
            return idCardNoUtil.check18IdCardNo(idCardNo);
        }
        // if (idCardNo.length === 8 || idCardNo.length === 10) {
        //     // 检查香港身份证号码
        //     return idCardNoUtil.check10IdCardNo(idCardNo);
        // }
        return false;
    },

    // 校验15位的身份证号码
    check15IdCardNo(idCardNo) {
        // 15位身份证号码的基本校验
        let check = /^[1-9]\d{7}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}$/.test(idCardNo);
        if (!check) return false;
        // 校验地址码
        const addressCode = idCardNo.substring(0, 6);
        check = idCardNoUtil.checkAddressCode(addressCode);
        if (!check) return false;
        const birDayCode = `19${idCardNo.substring(6, 12)}`;
        // 校验日期码
        return idCardNoUtil.checkBirthDayCode(birDayCode);
    },

    // 校验18位的身份证号码
    check18IdCardNo(idCardNo) {
        // 18位身份证号码的基本格式校验
        let check = /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/.test(idCardNo);
        if (!check) return false;
        // 校验地址码
        const addressCode = idCardNo.substring(0, 6);
        check = idCardNoUtil.checkAddressCode(addressCode);
        if (!check) return false;
        // 校验日期码
        const birDayCode = idCardNo.substring(6, 14);
        check = idCardNoUtil.checkBirthDayCode(birDayCode);
        if (!check) return false;
        // 验证校检码
        return idCardNoUtil.checkParityBit(idCardNo);
    },

    // 检查10位数的香港身份证号码
    check10IdCardNo(idCardNo) {
        let idNo = '';
        const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (idCardNo.length < 8) {
            return false;
        }
        if (idCardNo.charAt(idCardNo.length - 3) === '(' && idCardNo.charAt(idCardNo.length - 1) === ')') {
            idNo = idCardNo.substring(0, idCardNo.length - 3)
            + idCardNo.charAt(idCardNo.length - 2);
        }

        if (idCardNo.charAt(idCardNo.length - 3) === '（' && idCardNo.charAt(idCardNo.length - 1) === '）') {
            idNo = idCardNo.substring(0, idCardNo.length - 3)
            + idCardNo.charAt(idCardNo.length - 2);
        }
        idNo = idCardNo.toUpperCase();

        const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
        const matchArray = idNo.match(hkidPat);

        if (matchArray == null) {
            return false;
        }

        const charPart = matchArray[1];
        const numPart = matchArray[2];
        const checkDigit = matchArray[3];

        let checkSum = 0;
        if (charPart.length === 2) {
            checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
            checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
        } else {
            checkSum += 9 * 36;
            checkSum += 8 * (10 + strValidChars.indexOf(charPart));
        }

        for (let i = 0, j = 7; i < numPart.length; i += 1, j -= 1) {
            checkSum += j * numPart.charAt(i);
        }

        const remaining = checkSum % 11;
        const verify = remaining === 0 ? 0 : 11 - remaining;
        return verify === checkDigit || (verify === 10 && checkDigit === 'A');
    },


    formateDateCN(day) {
        const yyyy = day.substring(0, 4);
        const mm = day.substring(4, 6);
        const dd = day.substring(6);
        return `${yyyy}-${mm}-${dd}`;
    },

    // 获取信息
    getIdCardInfo(idCardNo) {
        const idCardInfo = {
            gender: '', // 性别
            birthday: '', // 出生日期(yyyy-mm-dd)
        };
        if (idCardNo.length === 15) {
            const aday = `19${idCardNo.substring(6, 12)}`;
            idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
            if (parseInt(idCardNo.charAt(14), 10) % 2 === 0) {
                idCardInfo.gender = idCardNoUtil.genders.female;
            } else {
                idCardInfo.gender = idCardNoUtil.genders.male;
            }
        } else if (idCardNo.length === 18) {
            const aday = idCardNo.substring(6, 14);
            idCardInfo.birthday = idCardNoUtil.formateDateCN(aday);
            if (parseInt(idCardNo.charAt(16), 10) % 2 === 0) {
                idCardInfo.gender = idCardNoUtil.genders.female;
            } else {
                idCardInfo.gender = idCardNoUtil.genders.male;
            }
        }
        return idCardInfo;
    },

    /* 18位转15位 */
    getId15(idCardNo) {
        if (idCardNo.length === 15) {
            return idCardNo;
        } if (idCardNo.length === 18) {
            return idCardNo.substring(0, 6) + idCardNo.substring(8, 17);
        }
        return null;
    },

    /* 15位转18位 */
    getId18(idCardNo) {
        if (idCardNo.length === 15) {
            const id17 = `${idCardNo.substring(0, 6)}19${idCardNo.substring(6)}`;
            const parityBit = idCardNoUtil.getParityBit(id17);
            return id17 + parityBit;
        } if (idCardNo.length === 18) {
            return idCardNo;
        }
        return null;
    },
};

export default idCardNoUtil;
