<template>
    <span class="number_check_button">
        <el-popover placement="top" trigger="click" popper-class="check_popover">
            <!-- <div class="total_number_title">{{ title }}</div> -->
            <div class="total_number">{{ totalNumber }}</div>
            <span slot="reference" @click="showTotalMessage" class="check">查看</span>
        </el-popover>
    </span>
</template>

<script>
import { getSecretData } from '@/api';
export default {
    props: {
        params: {
            type: Object,
            default: () => {},
        },
        title: {
            type: String,
            default: '完整手机号',
        },
    },
    data() {
        return {
            totalNumber: '',
        };
    },
    methods: {
        // 获取非脱敏手机号码
        showTotalMessage() {
            getSecretData({ ...this.params }).then((res) => {
                console.log('this.totalNumber_res: ', res);
                if (+res?.code === 0) {
                    this.totalNumber = res.data;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.check_popover {
    // height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
    background: rgba(0, 0, 0, 0.75);
    padding: 5px 0!important;
    min-width: 128px!important;
    .popper__arrow::after {
        border-top-color: rgba(0, 0, 0, 0.75)!important;
    }
}
.number_check_button {
    margin-left: 5px;
    .check {
        cursor: pointer;
        display: inline-block;
        // height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #005fff;
        line-height: 22px;
    }
}
.total_number {
    height: 22px ;
    font-weight: 600;
    padding: 0 12px 0 12px;
    text-align: center;
}
.total_number_title {
    // height: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    color: #303133;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 12px;
    padding: 0 12px 12px;
}
.el-popover {
    padding: 12px 0;
}
</style>
