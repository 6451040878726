import { generateQueryMap } from '@/utils';

export const identity = {
  0: '内部员工',
  1: '医生',
  2: '药企',
  3: '保险',
  4: '微信',
  5: '合作门店',
  56: '护士',
  57: '思派全科医生',
  58: '咨询医生',
  59: '问诊医生',
  60: '药师',
};

// 用户端：0；医生端：根据登录账号角色决定(1专科医生,2全科医生,3护士,4药师)
export const identityToRoleId = generateQueryMap({
  '1|58|59': 1,
  56: 3,
  57: 2,
  60: 4,
});

// 角色归类
export const identityToCategory = generateQueryMap({
  '1|57|58|59': { label: '医生', value: 1 },
  56: { label: '医助', value: 2 },
  60: { label: '药师', value: 3 },
  others: { label: '运营', value: 4 },
});

// 翻译角色ID
export function translateRoleId(value) {
  return identityToRoleId[value] || '';
}

export function translateRoleCategory(value) {
  return identityToCategory[value] || identityToCategory.others;
}

// 患者类型
export const diagnosisStatusEnum = {
  0: '初诊',
  1: '复诊',
};

// 问诊范畴
export const consultScopeEnum = {
  0: '在线咨询',
  1: '在线诊疗',
};

// 随访方式
export const followTypeEnum = {
  1: '容联电话',
  2: '容联短信',
  3: '派诊所健康建议',
  4: '企业微信（仅限派E院等项目）',
  5: '诊所电话（不提倡）',
  6: '线下面对面随访',
};
