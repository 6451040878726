import { Message, Loading } from 'element-ui';
import _ from 'lodash';
import Axios from 'axios';
import Configs from '@/config';
import { safeGet, safeMomentFormat } from './index';
import { loginPM } from './loginAuth';

Axios.defaults.withCredentials = false;

const request = (config) => {
  const { method, params, independent, url, isLoading = false, timeout, headers: headerInfo, ...other } = config;
  const token = localStorage.getItem(Configs.TOKEN_KEY);
  const alterHeader = url.indexOf('/server/') >= 0 ? {} : { token: token || '', platformId: 3 };

  if (params && !('bizCode' in params)) {
    params.bizCode = Configs.bizCode;
  }

  // 创建axios实例
  const axiosIns = Axios.create();
  const options = {
    headers: {
      ...alterHeader,
      ...headerInfo,
    },
    baseURL: Configs.baseUrl,
    url,
    method,
    timeout: timeout || 1 * 60 * 1000,
    [/^(?:post|delete)$/i.test(method) ? 'data' : 'params']: params,
    ...other,
  };
  let loading = null;
  if (isLoading) {
    loading = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
    });
  }
  return axiosIns(options)
    .catch((error) => {
      loading && loading.close();
      const safeErrorData = _.defaultTo(error, {});
      const status = safeGet(safeErrorData, 'response.status');
      const statusMap = {
        401: () => {
          Message.error('登录已失效，请重新登录员工平台账号');
          setTimeout(() => {
            loginPM();
          }, 800);
        },
        403: () => {
          Message.error('登录已失效，请重新登录员工平台账号');
          setTimeout(() => {
            loginPM();
          }, 800);
        },
        404: () => {
          Message.error('请求的资源不存在');
        },
        500: () => {
          Message.error('服务异常，请稍后重试');
        },
        503: () => {
          Message.error('服务器正在维护，请稍后重试');
        },
        10021: () => {
          Message.error('登录已失效，请重新登录员工平台账号');
        },
      };

      if (/timeout\sof\s\d+ms\sexceeded/.test(safeErrorData.message)) {
        Message.error('网络不稳定，请稍后重试');
      }

      if (statusMap[status]) {
        statusMap[status]();
      }
      return Promise.reject();
    })
    .then((res) => {
      loading && loading.close();
      // independent 存在做单独异常处理，其余走统一业务处理
      if (independent) return res;

      const data = safeGet(res, 'data');
      const code = safeGet(res, 'data.code');
      const tip = safeGet(res, 'data.message', '');

      // code不为0视为错误
      if (!/^0$/.test(code)) {
        if (code === '10021') {
          Message.error('登录已失效，请重新登录员工平台账号');
          loginPM();
        } else {
          Message.error(tip);
        }

        return Promise.reject(res);
      }

      return data;
    });
};

/**
 * 下载文件，基于xhr请求，保持登录状态，最长下载时间60分钟，超出则断开
 */
export const downloadFile = (options) => {
  const { url, params, method, fileName, fileSuffix } = options;
  return request({
    url,
    params,
    method,
    responseType: 'blob',
    independent: true,
    timeout: 60 * 60 * 1000,
  }).then((res) => {
    const date = safeMomentFormat(new Date(), 'YYYYMMDDhhmmss');
    const name = `${fileName}-${date}${fileSuffix}`;
    const { data } = res;
    // 针对IE浏览器
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(data, name);
    } else {
      const a = document.createElement('a');
      const fileUrl = URL.createObjectURL(data);
      a.href = fileUrl;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  });
};

export default request;
