export const credentialsType = {
  1: '身份证',
  2: '护照',
  3: '军官证',
    // { name: '港澳台居民通行证', code: 6 },
  4: '居民户口簿',
  7: '港澳台居民居住证',
  10: '出生证',
  11: '外国人永久居留证',
  12: '港澳居民来往内地通行证',
  13: '台湾居民来往大陆通行证',
  9: '其他',
}