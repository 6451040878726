import Vue from 'vue';
import _ from 'lodash';

Vue.directive('fragment', {
    inserted: (el) => {
        const parent = el.parentElement;
        const childrens = Array.from(el.children);
        _.forEach(childrens, (element) => {
            parent.appendChild(element);
        });
        parent.removeChild(el);
    },
});
