import { mapGetters } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const pagePermission = {
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapGetters({
            permissionCode: 'permissionCode',
        }),
    },
    watch: {
        permissionCode() {
            this.initPage();
        },
    },
    mounted() {
        this.initPage();
    },
    methods: {
        /**
         * 初始化页面，根据依赖的权限码进行页面初始化
         */
        initPage() {
            if (this.permissionCode.length > 0) {
                this.loading = false;
            }
        },
    },
};
