/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2020-09-16 16:46:37
 * @Last Modified by: zhifen.zhu
 * @Last Modified time: 2021-01-04 18:15:39
 * @Description: 表单字段顺序（临时方案，不应该前后台各自维护一套字段顺序，应该统一维护）
 * @Route: Route
 */

// 一般状态-基本信息
export const userBaseInfo = {
    recordDate: '记录日期',
    high: '身高',
    weigh: '体重',
    bmi: '体质指数',
    waist: '腰围',
    hips: '臀围',
    whr: '腰臀比',
};
// 一般状态-体征
export const userSign = {
    leftDiastolic: '左侧舒张压(mmHg)',
    leftSystolic: '左侧收缩压(mmHg)',
    rightDiastolic: '右侧舒张压(mmHg)',
    rightSystolic: '右侧收缩压(mmHg)',
    tem: '体温(℃)',
    pulse: '脉搏(次/分钟)',
    breathRate: '呼吸频率(次/分钟)',

};

// 个人一般史-婚育月经史
export const marryHistory = {
    menstruation: '月经情况',
    menstruationAge: '初潮年龄（岁）',
    menstruationDate: '月经周期（天）',
    menstruationDays: '持续时间（天）',
    menstruationEndDate: '末次月经时间',
    menopause: '绝经年龄',
    minMenstrualCycle: '最短月经周期',
    maxMenstrualCycle: '最长月经周期',
    minDuration: '最短持续时间',
    maxDuration: '最长持续时间',
    menstruationStatus: '周期是否规则',
    menstruationAche: '有无痛经',
    menstruationVolume: '月经量',
    pregnancy: '妊娠期',
    dueDate: '预产期',
    birth: '生育情况',
    pregnantNumber: '怀孕次数（次）',
    marryInfo: '生育信息',
    birthNumber: '生产次数（次）',
    birthWay: '分娩方式',
    volumeMark: '备注',
};
// 婚育月经史-生产信息

export const marryInfos = {
    birthNumber: '生产次数（次）',
    birthDate: '分娩时间',
    birthWay: '分娩方式',
};
// 个人一般史-过敏史
export const allergys = {
    allergyStatus: '有无过敏史',
    allergyName: '药品通用名',
    allergyOther: '其他物质',
    allergyMark: '备注',
};

// 个人一般史-危险因素接触史
export const dangerous = {
    dangerous: '危险因素接触物',
    dangerousRate: '每月频次',
    dangerousPro: '防护措施',
    dangerMark: '备注',

};

// 个人一般史-生活方式
export const lifeWay = {
    exerciseWays: '锻炼方式',
    exerciseStrenth: '锻炼强度',
    lifestyleExerciseFrequency: '锻炼频率',
    exerciseDays: '坚持锻炼时间',
    exerciseTime: '每次锻炼时间',
    sleepTime: '睡眠时长',
    lifestyleSleepPatterns: '睡眠状况',
    lifestyleEatingHabits: '饮食习惯',
};
// 个人一般史-吸烟史
export const smoking = {
    smoke: '吸烟状况',
    smokeNumber: '日吸烟量',
    startSmokeAge: '开始吸烟年龄',
    endSmokeAge: '戒烟年龄',
};
// 个人一般史-饮酒史
export const drinks = {
    drinkStatus: '饮酒状况',
    drinkInfo: '饮酒信息',
    drinkStrenth: '次饮酒量',
    startDrinkAge: '开始饮酒年龄',
    endDrinkAge: '戒酒年龄',
    drunkTimes: '近一年内醉酒',
    drinkType: '饮酒种类',
    drinkRate: '饮酒频次',
};
export const drinksInfo = {
    drinkType: '饮酒种类',
    drinkStrenth: '次饮酒量',
    drinkRate: '饮酒频次',
};

// 个人疾病史-手术信息

export const operations = {
    operDate: '手术日期',
    hospitalName: '就诊医院',
    operName: '手术名称',
    effect: '治疗效果',
    pahExamine: '病理检查',
    examineDate: '检查日期',
    partType: '组织学类型',
    bloodMark: '备注',
    isBad: '有无不良事件',
    badStartDate: '不良事件开始日期',
    badEvent: '不良事件名称',
};

// 个人疾病史-用药信息
export const medical = {
    medStartDate: '用药开始日期',
    medEndDate: '用药结束日期',
    commonName: '药物通用名',
    allergyNamess: '药物商品名',
    medicalWay: '用药方式',
    medicalRate: '用药频率',
    preDose: '每次给药剂量',
    dose: '剂量单位',
    compliance: '用药依从性',
};

// 个人疾病史-输血信息
export const blood = {
    bloodTime: '输血时间',
    bloodMark: '备注',
};
// 个人疾病史-影像学检查
export const image = {
    examineDate: '检查日期',
    examineProjects: '检查项目',
    examineResult: '检查结果',
    imageFind: '影像学发现',
    tissuePart: '组织部位',
    uterusSize: '子宫大小',
    numbers: '数目',
    long: '纵径',
    across: '横径',
    horizontalRatio: '纵横比',
    innerEcho: '内部回声',
    edge: '边缘情况',
    breastNature: '组织性质',
    cao: '钙化情况',
    blood: '周边血流情况',
    tirads: 'TI-RADS分级',
    breast: '乳腺组织构成',
    breastForm: '组织形态',
    outerEcho: '外部回声',
    lymph: 'TI-淋巴结整体情况',
    birads: 'BI-RADS分级',
    mark: '备注',
    allergyMark: '备注',

};

// 个人疾病史-实验室检查
export const Dyslipidemia = {
    examineDate: '检查日期',
    examineProjects: '检查项目',
    examineResult: '检查结果',
    bloodGlucose: '空腹血糖',
    eatGlucose: '餐后2小时血糖',
    hba1c: '糖化血红蛋白',
    hgb: '血红蛋白',
    pylori: '幽门螺旋杆菌',
    trig: '甘油三酯',
    chol: '总胆固醇',
    cdl: '低密度脂蛋白胆固醇',
    hdl: '高密度脂蛋白胆固醇',
    creat: '肌酐',
    urate: '尿酸',
    bun: '尿素氮',
    urine: '尿蛋白',
    ast: '天冬氨酸转氨酶',
    alt: '丙氨酸转氨酶',
    rggt: '谷氨酰胺转移酶',
    ft3: '游离3-碘甲腺原氨酸',
    ft4: '游离甲状腺素',
    tsh: '促甲状腺激素',
    tpo: '甲状腺过氧化物酶抗体',
    tgab: '甲状腺球蛋白抗体',
};

// 个人疾病史-症状
export const sym = {
    symp: '临床症状',
    symDate: '症状发生时间',
    symStartTime: '症状发生时间',
    symPart: '部位',
    symNumbers: '症状发生次数',
    symDays: '症状持续时间',
};
//  家族史
export const family = {
    familyRela: '与本人关系',
    familyHis: '家族疾病史',
};

// 住院史
export const hospital = {
    hospitalDate: '入院日期',
    dischargeDate: '出院日期',
    present: '现病史',
    diag: '临床诊断',
    hospitalization: '住院情况',
};

export default {
    userBaseInfo,
    userSign,
    marryHistory,
    dangerous,
    allergys,
    lifeWay,
    smoking,
    drinks,
    drinksInfo,
    operations,
    medical,
    blood,
    image,
    Dyslipidemia,
    sym,
    family,
    hospital,
    marryInfos,
};
