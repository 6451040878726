
<style src="./annotationLayer.css"></style>
<script>
/* eslint-disable */
import componentFactory from './componentFactory';

// eslint-disable-next-line import/no-mutable-exports
let component;
if (process.env.VUE_ENV !== 'server') {
    // eslint-disable-next-line global-require
    const pdfjsWrapper = require('./pdfjsWrapper').default;
    // eslint-disable-next-line global-require
    const PDFJS = require('pdfjs-dist/build/pdf.js');

    if (
        typeof window !== 'undefined'
        && 'Worker' in window
        && navigator.appVersion.indexOf('MSIE 10') === -1
    ) {
        const PdfjsWorker = require('worker-loader!./pdf.worker.js');
        PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();
    }
    console.log(PDFJS);
    component = componentFactory(pdfjsWrapper(PDFJS));
} else {
    component = componentFactory({});
}

export default component;
</script>
