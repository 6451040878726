// E签保的签名认证二维码
<template>
  <el-dialog :title="title" :visible="visible" width="550px" @close="cancel">
    <div class="dialog-content" v-loading="loading">
      <vue-qr v-if="signatureData.shortLink" :text="signatureData.shortLink" :size="300" />
      <div class="useless" v-if="!isValid || verifyFailed" @click="getSignatureUrl">
        <i class="el-icon-refresh-left"></i>
        <div class="verify-status" v-if="verifyFailed">识别失败，请刷新重试</div>
        <template v-else>二维码已失效，请刷新重试</template>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="cancel">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VueQr from 'vue-qr';
import { getFaceAuthResult } from '@/api/serviceExtend';
export default {
  name: 'UserSignatureCom',
  components: {
    VueQr,
  },
  props: {
    title: {
      type: String,
      default: '人脸识别',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    // 获取人脸识别url
    getSignatureFn: {
      type: Function,
      default: async () => Promise.resolve({}),
    },

    // 查询人脸识别结果接口
    getFaceAuthResultApi: {
      type: Function,
      default: getFaceAuthResult,
    },
  },

  data() {
    return {
      timer: null,
      loading: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      signatureData: {},
      validStartDate: null,
      isValid: true,
      verifyFailed: false,
    };
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    // 获取E签宝医生手写签名地址
    getSignatureUrl() {
      this.timer && clearTimeout(this.timer);
      this.getSignatureFn()
        .then((res) => {
          this.signatureData = res.data;
          this.validStartDate = new Date();
          this.verifyFailed = false;
          this.isValid = true;
          setTimeout(() => {
            this.queryAuthResult();
          }, 1000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询签名结果
    async queryAuthResult() {
      if (!this.visible || this.verifyFailed) return;
      const { flowId, requestId } = this.signatureData;
      if (!flowId && !requestId) return;

      const res = await this.getFaceAuthResultApi({ flowId, requestId });
      const result = res?.data;
      // 识别结果
      this.verifyFailed = result?.status == 0;

      // 0识别失败，1认证成功， 2认证中
      if (result && result.status == 1) {
        this.$emit('signature-success', this.signatureData.flowId);
        this.loading = true;
        return;
      }
      // 二维码过期
      if (this.checkIsInvalid()) {
        this.timer && clearTimeout(this.timer);
        this.isValid = false;
        return;
      }
      // 继续轮询
      this.timer = setTimeout(() => {
        this.queryAuthResult();
      }, 1000);
    },
    // 二维码是否过期
    checkIsInvalid() {
      if (!this.validStartDate) return false;
      return +new Date() - this.validStartDate.getTime() > 2 * 60 * 1000; // 二维码有效期两分钟
    },
    // 重置页面数据
    resetData() {
      this.loading = false;
      this.timer && clearTimeout(this.timer);
      this.signatureData = {};
      this.validStartDate = null;
      this.isValid = true;
      this.verifyFailed = false;
    },
  },
  watch: {
    // 轮询查询处方签名状态
    visible(newVal, oldVal) {
      this.resetData();
      if (newVal) {
        this.getSignatureUrl();
      }
    },
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  position: relative;
  text-align: center;
  height: 340px;
}
.useless {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1989fa;
  cursor: pointer;
  i {
    font-size: 40px;
    margin-bottom: 10px;
  }
}
.verify-status {
  text-align: center;
  margin-top: 10px;
  color: red;
  font-size: 18px;
}
</style>
