
// 1-面诊 2-电话问诊 3-图文问诊 4-健康保用药指导 5-快速咨询图文问诊,6-视频问诊,7-快速视频问诊,8-快速电话问诊
export const SERVICE_METHOD = {
  FACE: 1,
  TEL: 2,
  TEXT: 3,
  HEALTH: 4,
  QUICK_TEXT: 5,
  VIDEO: 6, 
  QUICK_VIDEO: 7,
  QUICK_TEL: 8,

  // 是否是视频问诊
  isVideo: (serviceMethod) => {
    return [
      SERVICE_METHOD.VIDEO, 
      SERVICE_METHOD.QUICK_VIDEO
    ].includes(+serviceMethod);
  },

  // 是否是快速咨询
  isQuick: (serviceMethod) => {
    return [
      SERVICE_METHOD.QUICK_TEXT, 
      SERVICE_METHOD.QUICK_VIDEO, 
      SERVICE_METHOD.QUICK_TEL
    ].includes(+serviceMethod)
  },

  // 视频问诊与电话问诊逻辑一致
  isTelOrVideo: (serviceMethod) => {
    return [
      SERVICE_METHOD.TEL, 
      SERVICE_METHOD.VIDEO, 
      SERVICE_METHOD.QUICK_VIDEO
    ].includes(+serviceMethod)
  }

}